import type { AlgoliaFacets } from '@jane/search/types';
import type { Store } from '@jane/shared/models';
import type { MenuCategoryPath } from '@jane/shared/types';

export const getMenuTabs = ({
  facets,
  menuTabs,
  specialsPage,
}: {
  facets?: AlgoliaFacets;
  menuTabs: Store['menu_tabs'];
  specialsPage?: boolean;
}): MenuCategoryPath[] =>
  menuTabs.reduce<MenuCategoryPath[]>((categories, tab) => {
    if (!facets || typeof specialsPage === 'undefined') {
      return [];
    }

    if (
      (tab === 'specials' && specialsPage) ||
      tab === 'featured' ||
      (facets?.root_types || {})[tab]
    ) {
      return [...categories, tab] as MenuCategoryPath[];
    }

    if (tab === 'all-products') {
      return [...categories, 'all'] as MenuCategoryPath[];
    }

    return categories as MenuCategoryPath[];
  }, []);
