import startCase from 'lodash/startCase';
import ResizeObserver from 'resize-observer-polyfill';

import { config } from '@jane/shared/config';
import {
  generateFallbackJdid,
  getCookie,
  getFingerprintId,
  getLocalStorageIgnoreTTL,
  getLocalStorageWithTTL,
} from '@jane/shared/data-access';
import { Storage, TESTS } from '@jane/shared/util';

import { request } from '../lib/request';

const fpKey = 'j_eyeD';

export default async function bootstrap({
  isMarketplace = false,
  jdidOverride = null,
}: { isMarketplace?: boolean; jdidOverride?: string } = {}) {
  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
  }

  if (Storage.get('user_session')) {
    // clear out old auth storage
    Storage.remove('user_session');
  }

  if (Storage.get('store-snapshot')) {
    // don't use legacy local cart persistence
    Storage.remove('store-snapshot');
  }

  // Fall back to a 5 second ready window in case a page doesn't mark as ready
  setTimeout(function () {
    window.prerenderReady = true;
  }, 5000);

  window.BUILD_VERSION = config.buildVersion?.substring(0, 7) || '';

  const jdidKey = 'jdid';
  Storage.remove('fpId');

  // We are not rolling out FP to marketplace at the moment.
  if (isMarketplace) {
    if (!Storage.get(jdidKey)) {
      const janeUrl = `${config.apiPath}/bootstrap`;
      await request(janeUrl, { method: 'GET' });
      const cookieJdid = getCookie(jdidKey);
      if (cookieJdid) {
        Storage.set(jdidKey, cookieJdid);
      } else {
        Storage.set(jdidKey, generateFallbackJdid());
      }
    }
  } else if (jdidOverride) {
    Storage.set(jdidKey, jdidOverride);
  } else {
    const availableJdid = Storage.get(jdidKey);
    const cachedFingerprintId = getLocalStorageWithTTL(fpKey);
    const staleFingerprintId = getLocalStorageIgnoreTTL(fpKey);
    if (
      availableJdid &&
      availableJdid !== 'undefined' &&
      availableJdid !== 'null'
    ) {
      Storage.set(jdidKey, availableJdid); // In case we need to set JDID to iframe JDID
      console.debug('JDID was available in cache');
      if (!cachedFingerprintId && staleFingerprintId) {
        // If there's not an available FPID but there's a stale one, refresh the cache
        // Local storage is handled in getFingerprintId
        const newJdid = await getFingerprintId();
        if (newJdid) {
          console.debug('Refreshed cache for FPID');
        } else {
          console.debug('Failed to get FPID to fresh cache');
        }
      }
    } else {
      if (cachedFingerprintId) {
        console.debug('Set JDID to cached FPID');
        Storage.set(jdidKey, cachedFingerprintId);
      } else {
        const newJdid = await getFingerprintId();
        if (newJdid) {
          console.debug('Set JDID to new FPID');
          Storage.set(jdidKey, newJdid);
        } else {
          console.debug('Failed to generate FP ID');
          if (staleFingerprintId) {
            console.debug('Set JDID to stale FPID');
            Storage.set(jdidKey, staleFingerprintId);
          } else {
            console.debug('Set JDID to fallback');
            Storage.set(jdidKey, generateFallbackJdid());
          }
        }
      }
    }
  }

  // If JDID is still not set, set it to a fallback value.
  if (!Storage.get(jdidKey)) {
    Storage.set(jdidKey, generateFallbackJdid());
  }

  // Remove inactive AB tests from localStorage and Mixpanel super properties.
  try {
    // Remove historical AB tests that are no longer in local storage but in Cookies.
    const historicalTests = [
      'Test: Relevancy Score Algorithm 1',
      'Test: Relevancy Score Algorithm 2',
      'Test: Relevancy Score Algorithm 3',
      'Test: Relevancy Score Algorithm 4',
    ];
    historicalTests.forEach((t) => janeMixpanel.unregister(t));

    Object.entries(localStorage)
      ?.filter((entry) => entry[0].includes('test:'))
      ?.forEach((entry) => {
        const storageKey = entry[0];
        const testName = storageKey.split(':')[1];

        if (!Object.keys(TESTS).includes(testName)) {
          localStorage.removeItem(storageKey);
          janeMixpanel.unregister(`Test: ${startCase(testName)}`);
        }
      });
  } catch (e) {
    // localStorage not available
  }
}
