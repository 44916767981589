import type { Card } from '@square/web-payments-sdk-types';
import { useState } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Button } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

import { PaymentMethods } from '../../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

export const SquareButton = () => {
  const [processing, setProcessing] = useState(false);
  const { dispatches } = useCheckout();
  const { squareCard, verified, selected, updatePrepayments } =
    useCheckoutPayments();

  const handleClick = async () => {
    setProcessing(true);
    try {
      const tokenResult = await (squareCard.current as Card).tokenize();

      if (tokenResult.status === 'OK') {
        updatePrepayments({
          squareToken: tokenResult.token,
          squareCardDetails: tokenResult?.details?.card,
        });
        (squareCard.current as Card).detach();
      }
    } catch (e) {
      dispatches.checkoutError(
        'Something went wrong with your payment, please try again. If the problem persists, please contact us at support@iheartjane.com'
      );
      trackError('Unable to tokenize Square card', { squareError: e });
    }

    setProcessing(false);
  };

  if (verified && selected === PaymentMethods.square) return null;

  return (
    <Button
      mb={24}
      full
      label="Continue"
      onClick={handleClick}
      loading={processing}
    />
  );
};
