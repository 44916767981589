import { createContext, useCallback, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Form, useForm } from '@jane/shared/reefer';

import { useBuildDefaultFormValues } from '../util/hooks/useBuildDefaultFormValues';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';

interface CheckoutFormProviderValues {
  autoSelectSlot: () => void;
}

const CheckoutFormContext = createContext<CheckoutFormProviderValues>(
  {} as CheckoutFormProviderValues
);

interface Props {
  children: ReactNode;
}

export const CheckoutFormProvider = ({ children }: Props) => {
  const {
    cart: { reservation_mode },
  } = useCheckout();
  const { deliverySlots, curbsideSlots, pickupSlots } =
    useFulfillmentSchedule();
  const defaultFormValues = useBuildDefaultFormValues();

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  });

  const handleAutoSelectSlot = useCallback(() => {
    const pickupMode = formMethods.getValues('pickupOptions.pickupMode');

    const isDelivery = reservation_mode === 'delivery';
    const fulfillmentSlots = isDelivery
      ? deliverySlots
      : pickupMode === 'pickup'
      ? pickupSlots
      : curbsideSlots;

    const firstSlot = fulfillmentSlots.length
      ? `${fulfillmentSlots[0].from}>${fulfillmentSlots[0].to}`
      : '';

    const formKey = isDelivery
      ? 'deliveryOptions.fulfillmentTime'
      : 'pickupOptions.fulfillmentTime';

    // resetField both sets the value and re-runs validation.
    formMethods.resetField(formKey, { defaultValue: firstSlot });
  }, [curbsideSlots, deliverySlots, pickupSlots, reservation_mode]);

  const values = useMemo(
    () => ({
      autoSelectSlot: handleAutoSelectSlot,
    }),
    [handleAutoSelectSlot]
  );

  return (
    <CheckoutFormContext.Provider value={values}>
      <Form.BaseForm
        name="jane-checkout"
        // We submit checkout from various components with differing values,
        // so we aren't using the form submit handler.
        onSubmit={() => null}
        formMethods={formMethods}
      >
        {children}
      </Form.BaseForm>
    </CheckoutFormContext.Provider>
  );
};

export const useCheckoutForm = () => {
  const context = useContext(CheckoutFormContext);

  if (context === undefined) {
    throw new Error(
      'useCheckoutForm must be used within the CheckoutFormContext'
    );
  }

  return context;
};
