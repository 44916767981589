import type { ReservationSlotsProps } from './reservationSlots.queries';

export const reservationSlotsUrls = {
  slots: ({
    reservationMode,
    storeId,
    lat,
    lng,
    zipcode,
  }: ReservationSlotsProps) => {
    const queryString =
      reservationMode === 'delivery'
        ? `?user_latitude=${lat}&user_longitude=${lng}&user_zipcode=${zipcode}`
        : '';
    return `/stores/${storeId}/reservation_slots/${reservationMode}${queryString}`;
  },
};

const BASE_KEY = 'reservationSlots';

export const reservationSlotsKeys = {
  slots: ({
    reservationMode,
    storeId,
    lat,
    lng,
    zipcode,
  }: ReservationSlotsProps) => [
    BASE_KEY,
    storeId,
    reservationMode,
    lat,
    lng,
    zipcode,
  ],
};
