import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import type { UserConsentItem } from '@jane/shared/models';

import { toPayloadValues } from '../parseConsentItems';

interface Agreements {
  external_link: string | null;
  id: number;
  label: string;
  name: string;
  required: boolean;
}

interface UseUserConsents {
  checkoutConsents: UserConsentItem[];
  consentItems: Agreements[];
  isValid: boolean;
}

export const useUserConsents = (): UseUserConsents => {
  const {
    cart: { reservation_mode },
    checkoutRequirements: { data: checkoutRequirementsData },
  } = useCheckout();

  const { control } = useFormContext();

  const [deliveryConsents, pickupConsents] = useWatch({
    control,
    name: ['deliveryOptions.consentItems', 'pickupOptions.consentItems'],
  });

  const consentItems = (
    checkoutRequirementsData?.checkout_agreement_versions || []
  )
    ?.filter(
      (agreement) =>
        agreement.reservation_mode === reservation_mode ||
        !agreement.reservation_mode
    )
    .map((agreement) => ({
      external_link: agreement.external_link,
      id: agreement.id,
      label: agreement.label,
      name: `consentItems.${agreement.id}`,
      required: agreement.consent_requirement.includes('required'),
    }));

  const formConsentItems =
    reservation_mode === 'delivery' ? deliveryConsents : pickupConsents;

  const checkoutConsents = toPayloadValues(formConsentItems);

  const requiredConsents = consentItems.filter((item) => item.required);

  const isValid = requiredConsents.every(
    (item) => item.id in formConsentItems && formConsentItems[item.id]
  );

  return { consentItems, checkoutConsents, isValid };
};
