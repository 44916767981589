import { useMenu } from '@jane/shared-ecomm/providers';
import { Box, Link, useMobileMediaQuery } from '@jane/shared/reefer';

export const MenuResetLocationLink = () => {
  const {
    appInfo: { appMode },
    onOperatorEmbedResetLocationLink: onTextClick,
  } = useMenu();
  const isMobile = useMobileMediaQuery({});
  const isOperatorEmbed = appMode === 'operatorEmbed';

  if (!isOperatorEmbed) {
    return null;
  }

  return (
    <Box pb={isMobile ? 16 : 24} px={isMobile ? 24 : 64}>
      <Link color="text-main" onClick={onTextClick} typography="body">
        Enter new address
      </Link>
    </Box>
  );
};
