import { useCheckout } from '@jane/shared-ecomm/providers';
import { Link, Typography } from '@jane/shared/reefer';
import { monolithPaths } from '@jane/shared/util';

export const AeropayError = ({ code = '' }) => {
  const {
    appInfo: { appMode },
  } = useCheckout();

  if (code === 'AP300') {
    return (
      <Typography as="div" color="error-dark">
        We are having issues connecting to your bank. Please select another bank
        account{' '}
        <Link
          color="error-dark"
          {...(appMode === 'default'
            ? { to: monolithPaths.profileEdit() }
            : { href: 'https://www.iheartjane.com/profile/edit' })}
        >
          here
        </Link>{' '}
        and try again or reach out to support@iheartjane.com to resolve the
        issue.
      </Typography>
    );
  }

  if (code === 'AP301') {
    return (
      <Typography as="div" color="error-dark">
        Your account has been blocked, please fill out{' '}
        <Link
          color="error-dark"
          href="https://customer-support.iheartjane.com/s/jane-pay-aeropay-customer-support"
        >
          this form
        </Link>{' '}
        and someone will reach out to resolve the issue.
      </Typography>
    );
  }

  if (['AP302', 'AP306', 'AP307', 'AP315'].includes(code || '')) {
    return (
      <Typography as="div" color="error-dark">
        We could not approve your payment, your available balance might be
        insufficient for this transaction. Please select another bank account{' '}
        <Link
          color="error-dark"
          {...(appMode === 'default'
            ? { to: monolithPaths.profileEdit() }
            : { href: 'https://www.iheartjane.com/profile/edit' })}
        >
          here
        </Link>{' '}
        and try again or reach out to support@iheartjane.com to resolve the
        issue.
      </Typography>
    );
  }

  if (code === 'AP304') {
    return (
      <Typography color="error-dark">
        Your transaction amount exceeds the daily limit on your account. Please
        try a smaller purchase or reach out to support@iheartjane.com to resolve
        the issue.
      </Typography>
    );
  }

  if (code === 'AP305') {
    return (
      <Typography as="div" color="error-dark">
        We've detected delinquent activity on your account, please fill out{' '}
        <Link
          color="error-dark"
          href="https://customer-support.iheartjane.com/s/jane-pay-aeropay-customer-support"
        >
          this form
        </Link>{' '}
        and someone will reach out to resolve the issue.
      </Typography>
    );
  }

  if (code === 'AP310') {
    return (
      <Typography color="error-dark">
        We're sorry, your transaction has been declined, please try again or
        reach out to support@iheartjane.com to resolve the issue.
      </Typography>
    );
  }

  return (
    <Typography color="error-dark">
      Something went wrong, please try again. If the problem persists, please
      contact us at support@iheartjane.com.
    </Typography>
  );
};
