import { useParams } from 'react-router-dom';

import {
  CATEGORY_DESCRIPTIONS,
  formatFeaturedCategory,
} from '@jane/search/util';
import { MenuBreadcrumbs } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { useJaneUserId } from '@jane/shared/data-access';
import {
  Button,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';
import { insertIfArr, sentenceCase } from '@jane/shared/util';

import { MenuFiltersAndProductsGrid } from '../../MenuComponents/MenuFiltersAndProductsGrid';
import { MENU_CATEGORY_TABS } from '../../MenuComponents/MenuTabs';
import { MenuTitle } from '../../MenuComponents/MenuTitle';
import { useBuyAgain } from '../../hooks/useBuyAgain';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';

export const MenuProducts = ({
  initialCategory,
}: {
  initialCategory?: MenuCategoryPath;
}) => {
  const {
    category: categoryParam = 'all',
    featured_category: featuredCategory,
  } = useParams<{
    category: MenuCategoryPath;
    featured_category: string;
  }>();
  const isMobile = useMobileMediaQuery({});
  const {
    appInfo: { appMode },
    paths,
    store,
  } = useMenu();
  const userId = useJaneUserId();

  const category = initialCategory ?? categoryParam;

  const { featuredTitle, featuredFilter, isJaneDefined } =
    formatFeaturedCategory(store, featuredCategory);

  const filterByCategory =
    !['all', 'featured'].includes(category) && MENU_CATEGORY_TABS[category];
  const filterByFeaturedCategory =
    featuredCategory &&
    !['buy-again'].includes(featuredCategory) &&
    featuredFilter.length;
  const filterByBuyAgain = featuredCategory && featuredCategory === 'buy-again';

  const { purchasedProductFilter } = useBuyAgain({
    userId: filterByBuyAgain ? userId : undefined,
  });

  const staticFilters = useComposeMenuFilters([
    filterByCategory ? `kind:"${category}" OR root_types:"${category}"` : '',
    filterByFeaturedCategory ? featuredFilter : '',
    filterByBuyAgain ? purchasedProductFilter : '',
  ]);

  const customLabels = store.custom_product_type_labels;
  const categoryTitle =
    (customLabels && customLabels[category]) ||
    sentenceCase(category === 'all' ? 'all products' : category);

  const pageTitle =
    featuredCategory && featuredTitle.length ? featuredTitle : categoryTitle;

  const disableAds = Boolean(featuredCategory) && !isJaneDefined;

  const categoryDescription =
    (appMode === 'default' && CATEGORY_DESCRIPTIONS[category]) || '';

  const breadcrumbs = [
    { title: store.name, url: paths.menu() },
    ...(featuredCategory
      ? [{ title: categoryTitle, url: paths.menuCategory(category) }]
      : []),
  ];

  const nextBreadcrumbs = [
    ...insertIfArr(!!category, {
      title: category === 'all' ? 'All products' : sentenceCase(category || ''),
      url: paths.menuCategory(category),
    }),
    ...insertIfArr(!!featuredCategory, {
      title: featuredTitle,
      url: paths.menuFeaturedCategory(featuredCategory || ''),
    }),
  ];

  if (
    (featuredCategory && !featuredTitle.length) ||
    (category && !MENU_CATEGORY_TABS[category])
  ) {
    return (
      <Flex flexDirection="column" alignItems="center" p={isMobile ? 24 : 64}>
        <Typography variant="header-bold" mb={24} textAlign="center">
          Oops, we can't seem to find this page.
        </Typography>
        <Button to={paths.menu()} label={`Return to ${store.name}`} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" gap={isMobile ? 24 : 48}>
      {featuredCategory && (
        <MenuBreadcrumbs
          breadcrumbs={breadcrumbs}
          title={featuredTitle}
          px={64}
        />
      )}
      <MenuTitle
        title={pageTitle}
        description={categoryDescription}
        px={isMobile ? 24 : 64}
      />
      <MenuFiltersAndProductsGrid
        breadcrumbs={nextBreadcrumbs}
        disableAds={disableAds}
        hasPurchasedProducts={purchasedProductFilter.length > 0}
        menuCategory={category}
        pageTitle={pageTitle}
        staticFilters={staticFilters}
      />
    </Flex>
  );
};
