import { useEffect } from 'react';

import { Portal } from '@jane/shared/components';
import { postMessageToIframeParent } from '@jane/shared/util';

import { MONERIS_WIDGET_DIV } from './useMoneris';

export const MonerisPortal = ({ onBackPress }: { onBackPress: () => void }) => {
  useEffect(() => {
    postMessageToIframeParent({ messageType: 'scrollToTop' });
    const shadowRoot = document.getElementById('shadow-host');
    if (shadowRoot) {
      shadowRoot.scrollIntoView();
    }

    // We have to push a fake history state in order to intercept the back button
    window.history.pushState({}, '');
    const handleBackButton = () => {
      onBackPress();
    };
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <Portal>
      <div style={{ zIndex: 'var(--layers-modal)' }} id={MONERIS_WIDGET_DIV} />
    </Portal>
  );
};
