import { Box, useWindowListener } from '@jane/shared/reefer';

export const PaytenderModal = ({
  src,
  handleMessage,
}: {
  handleMessage: (event: MessageEvent) => void;
  src: string;
}) => {
  useWindowListener('message', handleMessage);

  return (
    <Box height="100%" width="100%">
      <iframe title="Paytender Payment" height="100%" width="100%" src={src} />
    </Box>
  );
};
