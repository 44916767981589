import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';

import type {
  PaytenderCheckoutRequest,
  PaytenderOrderReference,
  PaytenderResponse,
  PaytenderStatusResponse,
} from './paytender.types';
import { queryKeys, urls } from './paytender.util';

const postPaytenderAuthorize = async (payload: PaytenderCheckoutRequest) => {
  const data = {
    ...payload,
    aeropay_preauthorization_id: null,
    current_url: window.location.href,
    payfirma_card_number: null,
    payfirma_card_expiry_year: null,
    payfirma_card_expiry_month: null,
    payfirma_card_cvv2: null,
    payfirma_card_postal_code: null,
  };
  return await janeApi.post<PaytenderResponse>(urls.authorize, data, getJwt());
};

const enableAuthorizeQuery = (payload: PaytenderCheckoutRequest) => {
  const {
    cart_uuid,
    reservation_mode,
    delivery_finished_at,
    delivery_started_at,
  } = payload;

  return (
    !!cart_uuid &&
    !!reservation_mode &&
    !!delivery_finished_at &&
    !!delivery_started_at
  );
};

export const usePaytenderAuthorize = createUseQueryHook<
  PaytenderCheckoutRequest,
  PaytenderResponse
>(postPaytenderAuthorize, queryKeys.authorize, enableAuthorizeQuery);

const getPaytenderStatus = async ({ orderReference = '' }) =>
  await janeApi.get<PaytenderStatusResponse>(
    urls.status(orderReference),
    getJwt()
  );

const enableStatusQuery = ({ orderReference }: PaytenderOrderReference) =>
  !!orderReference;

export const usePaytenderStatus = createUseQueryHook<
  PaytenderOrderReference,
  PaytenderStatusResponse
>(getPaytenderStatus, queryKeys.status, enableStatusQuery);

const getPaytenderReservationStatus = async (orderReference: string) =>
  await janeApi.get<{ status: string; total_amount: number }>(
    urls.reservationStatus(orderReference),
    getJwt()
  );

// The paytender widget can close before returning validation status;
// this query has a refetch interval that will attempt to retrieve this status
// every second for twenty seconds (it should only take one or two refetches).
export const usePaytenderReservationStatus = ({
  enabled,
  onError,
  orderReference,
}: {
  enabled: boolean;
  onError?: (err: unknown) => void;
  orderReference?: string;
}) => {
  const refetchCounter = useRef(0);
  const completed = useRef(false);
  return useQuery({
    enabled: enabled && !!orderReference,
    queryFn: async () => {
      refetchCounter.current += 1;

      if (refetchCounter.current === 20) {
        return Promise.reject(
          new Error('Cart status has not updated for completed paytender order')
        );
      }

      const reservationStatus = await getPaytenderReservationStatus(
        orderReference as string
      );

      if (reservationStatus.status === 'verification') {
        completed.current = true;
      }

      return reservationStatus;
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.reservationStatus({ orderReference }),
    onError: onError,
    refetchInterval: () =>
      refetchCounter.current < 20 && !completed.current ? 1000 : false,
  });
};
