import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCheckout } from '@jane/shared-ecomm/providers';
import type { ReservationSlot } from '@jane/shared/models';
import { formatTime } from '@jane/shared/util';

import type {
  CheckoutFormValues,
  PickupOptions,
} from '../../CheckoutFormProvider/checkoutForm.types';
import { useFulfillmentSchedule } from './useFulfillmentSchedule';

export const useBuildDefaultFormValues = (): CheckoutFormValues => {
  const { state } = useLocation();
  const preAuthOptions = state?.preAuthOptions;
  const preAuthCustomer = state?.preAuthCustomer;

  const {
    deliveryMessage: preAuthDeliveryMessage,
    consents: preAuthConsents,
    fulfillmentTime: preAuthFulfillmentTime,
    pickupMessage: preAuthPickupMessage,
    pickupMode: preAuthPickupMode,
  } = preAuthOptions || {};

  const {
    customer: { birthDate, email, lastName, firstName, phone },
    identification: {
      document: {
        mmj_card,
        mmj_card_back,
        mmj_card_id_number,
        government_photo,
      },
    },
    checkoutRequirements: { data: checkoutRequirementsData },
    store: { curbside_pickup_setting },
  } = useCheckout();

  const { curbsideSlots, pickupSlots, deliverySlots, isAcceptingPickup } =
    useFulfillmentSchedule();

  const checkoutAgreements =
    checkoutRequirementsData?.checkout_agreement_versions || [];

  const deliveryConsents = checkoutAgreements
    ?.filter(
      (agreement) =>
        agreement.reservation_mode === 'delivery' ||
        agreement.reservation_mode === null
    )
    .reduce(
      (prev, curr) => ({
        ...prev,
        [`${curr.id}`]:
          preAuthConsents?.includes(curr.id) || !!curr.consent_default,
      }),
      {}
    );

  const pickupConsents = checkoutAgreements
    ?.filter(
      (agreement) =>
        agreement.reservation_mode === 'pickup' ||
        agreement.reservation_mode === null
    )
    .reduce(
      (prev, curr) => ({
        ...prev,
        [`${curr.id}`]:
          preAuthConsents?.includes(curr.id) || !!curr.consent_default,
      }),
      {}
    );

  // The form expects dates as MMDDYYY but we store them as YYYY-MM-DD.
  // The form's date will be formatted back to YYYY-MM-DD when checking out.
  const birthDateToParse = birthDate ? birthDate : preAuthCustomer?.birthDate;
  const parsedBirthDate = birthDateToParse
    ? formatTime(birthDateToParse, 'MMDDYYYY', 'YYYY-MM-DD')
    : '';

  const getDefaultPickupMode = () => {
    if (curbside_pickup_setting === 'not_allowed') return 'pickup';
    if (preAuthPickupMode) return preAuthPickupMode;
    if (isAcceptingPickup) return 'pickup';
    return 'curbside';
  };

  const getDefaultTime = (slots: ReservationSlot[]) => {
    if (!slots.length) return '';

    const firstSlot = `${slots[0].from}>${slots[0].to}`;

    if (preAuthFulfillmentTime) {
      const preSelectedSlot = slots.find(
        (slot) => slot.from === parseInt(preAuthFulfillmentTime.split('>')[0])
      );

      return preSelectedSlot
        ? `${preSelectedSlot.from}>${preSelectedSlot.to}`
        : firstSlot;
    }

    return firstSlot;
  };

  const getFulfillmentOptions = () => {
    const defaultPickupMode: PickupOptions['pickupMode'] =
      getDefaultPickupMode();
    const pickupFulfillmentSlots =
      defaultPickupMode === 'pickup' ? pickupSlots : curbsideSlots;
    const defaultPickupTime = getDefaultTime(pickupFulfillmentSlots);
    const defaultDeliveryTime = getDefaultTime(deliverySlots);

    return {
      deliveryOptions: {
        fulfillmentTime: defaultDeliveryTime,
        message: preAuthDeliveryMessage || '',
        consentItems: deliveryConsents,
        promotionsOptIn: true,
      },
      pickupOptions: {
        fulfillmentTime: defaultPickupTime,
        pickupMode: defaultPickupMode,
        message: preAuthPickupMessage || '',
        consentItems: pickupConsents,
        promotionsOptIn: true,
      },
    };
  };

  const defaultValues = useMemo(
    () => ({
      customerInformation: {
        birthDate: parsedBirthDate,
        email: email || '',
        firstName: firstName || preAuthCustomer?.firstName || '',
        governmentIdentificationUrl:
          government_photo || preAuthCustomer?.government_photo || '',
        lastName: lastName || preAuthCustomer?.lastName || '',
        mmjIdBackUrl: mmj_card_back || preAuthCustomer?.mmj_card_back || '',
        mmjIdFrontUrl: mmj_card || preAuthCustomer?.mmj_card || '',
        mmjIdNumber:
          mmj_card_id_number || preAuthCustomer?.mmj_card_id_number || '',
        phone: phone || '',
      },
      paymentOptions: {
        payfirma: {
          cardCvv2: '',
          cardExpiry: '',
          cardNumber: '',
          cardPostalCode: '',
        },
        canPayToken: '',
      },
      ...getFulfillmentOptions(),
    }),
    []
  );

  return defaultValues;
};
