import { useSearchContext } from '@jane/search/providers';
import {
  DESKTOP_PRODUCTS_PER_PAGE_GRID,
  MOBILE_PRODUCTS_PER_PAGE_GRID,
  buildFeaturedCategoryCarousels,
  buildFilter,
} from '@jane/search/util';
import { useMyHigh } from '@jane/shared-ecomm/hooks';
import { useMenu } from '@jane/shared-ecomm/providers';
import { useJaneUserId } from '@jane/shared/data-access';
import { Box, Flex, useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuFilters } from '../../MenuComponents/MenuFilters';
import { MenuNavButtons } from '../../MenuComponents/MenuNavButtons';
import { MenuProductsGrid } from '../../MenuComponents/MenuProductsGrid';
import { MenuSponsoredCarousel } from '../../MenuComponents/MenuSponsoredCarousel';
import { MenuTitle } from '../../MenuComponents/MenuTitle';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import { MenuBuyAgainCarousel } from './MenuBuyAgainCarousel';
import { MenuFeaturedCategoryCarousel } from './MenuFeaturedCategoryCarousel';
import { MenuMagicRowCarousel } from './MenuMagicRowCarousel';
import { MenuSpecialsCarousel } from './MenuSpecialsCarousel';

export const MenuFeatured = () => {
  const isMobile = useMobileMediaQuery({});
  const { searchState } = useSearchContext();
  const { paths, store } = useMenu();
  const userId = useJaneUserId();
  const staticFilters = useComposeMenuFilters([]);
  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;

  const {
    products,
    indexName,
    isLoading,
    hasNextPage,
    numColumns,
    numHits,
    fetchNextPage,
    searchResultFacets,
  } = useMyHigh({
    maxProducts: isFilteringProducts ? undefined : 0,
    productsPerPage: isMobile
      ? MOBILE_PRODUCTS_PER_PAGE_GRID
      : DESKTOP_PRODUCTS_PER_PAGE_GRID,
    staticFilters,
  });

  const featuredCategoryCarousels = buildFeaturedCategoryCarousels(
    searchResultFacets,
    store
  );

  const showSpecialsRow = store.specials_row_configs?.enabled;

  const renderedFeaturedCarousels = !isFilteringProducts
    ? featuredCategoryCarousels?.map((featuredCategory, index) => {
        const { count, isJaneDefined, rootType, title } = featuredCategory;

        if (!isJaneDefined && count === 0) return null;

        if (rootType === 'buy_again_row' && userId) {
          return (
            <MenuBuyAgainCarousel
              key="Buy again"
              placementIndex={index}
              userId={userId}
            />
          );
        }

        if (rootType === 'magic_row') {
          return <MenuMagicRowCarousel key={title} placementIndex={index} />;
        }

        if (rootType === 'specials' && showSpecialsRow) {
          return <MenuSpecialsCarousel key={title} />;
        }

        const featuredCategoryFilter = buildFilter('root_types', [rootType]);

        return (
          <MenuFeaturedCategoryCarousel
            key={title}
            disableAds={!isJaneDefined}
            featuredCategoryFilter={featuredCategoryFilter}
            placementIndex={index}
            title={title}
          />
        );
      })
    : null;

  const nextBreadcrumbs = [
    {
      title: 'Featured',
      url: paths.menuCategory('featured'),
    },
  ];

  return (
    <Box pt={isMobile ? 0 : 24}>
      <MenuTitle px={isMobile ? 24 : 64} />
      <MenuFilters
        isLoadingProducts={isLoading}
        menuCategory="featured"
        searchResultFacets={searchResultFacets}
        totalResults={numHits}
        staticFilters={staticFilters}
      />
      <MenuSponsoredCarousel menuCategory="featured" />
      {!isFilteringProducts ? (
        <Flex
          flexDirection="column"
          gap={isMobile ? 24 : 48}
          px={isMobile ? 24 : 64}
          pt={isFilteringProducts || isMobile ? 0 : 24}
        >
          {renderedFeaturedCarousels}
          <MenuNavButtons isMobile={isMobile} />
        </Flex>
      ) : (
        <MenuProductsGrid
          breadcrumbs={nextBreadcrumbs}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          indexName={indexName}
          isLoading={isLoading}
          numColumns={numColumns}
          numHits={numHits}
          pageTitle="featured"
          products={products}
        />
      )}
    </Box>
  );
};
