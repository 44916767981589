import type { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { trackError as trackErrorUtil } from '@jane/shared/util';

import type { ErrorPageProps } from './errorPage';
import { ErrorPage } from './errorPage';

interface ErrorBoundaryProps
  extends Omit<ErrorPageProps, 'error'>,
    PropsWithChildren {
  trackError?: (error: Error | string) => void;
  withFallbackRender?: boolean;
}

export function ErrorBoundary({
  button,
  children,
  trackError = trackErrorUtil,
  whiteLabel,
  withFallbackRender = true,
  withReeferThemeProvider = false,
}: ErrorBoundaryProps) {
  return (
    <ReactErrorBoundary
      fallbackRender={({ error }) => {
        if (!withFallbackRender) return null;
        return (
          <ErrorPage
            button={button}
            error={error}
            whiteLabel={whiteLabel}
            withReeferThemeProvider={withReeferThemeProvider}
          />
        );
      }}
      onError={trackError}
    >
      {children}
    </ReactErrorBoundary>
  );
}
