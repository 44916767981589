import { useCallback, useEffect } from 'react';

import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import { PrepaymentMethods, useCheckout } from '@jane/shared-ecomm/providers';

import type { CheckoutPaymentsContextValue } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { PaymentMethods, usePaymentOptions } from './usePaymentOptions';

export type UsePaymentIsValid = Omit<
  CheckoutPaymentsContextValue,
  | 'updatePrepayments'
  | 'squareCard'
  | 'squareCardDetails'
  | 'selectPaymentOption'
>;

export const usePaymentIsValid = (prepayments: UsePaymentIsValid) => {
  const { paymentOptions, prepaymentOptions } = usePaymentOptions();

  const {
    selected,
    aeropayBankId,
    squareToken,
    strongholdSourceId,
    setVerified,
  } = prepayments;

  const {
    cart: { reservation_mode },
    store: { canpay_v2remotepay_enabled },
  } = useCheckout();

  const allOptions = [...paymentOptions, ...prepaymentOptions];
  const handleVerify = useCallback(() => {
    if (selected && !allOptions.includes(selected)) {
      setVerified(false);
      return;
    }

    // cash, credit, debit, or a custom payment method
    if (
      selected &&
      allOptions.includes(selected) &&
      !Object.values(PrepaymentMethods).includes(
        selected as PrepaymentProviders
      )
    ) {
      setVerified(true);
      return;
    }

    switch (selected) {
      // These prepayments have their own validation or are validated via react-hook-form
      case PaymentMethods.aeropay:
      case PaymentMethods.moneris:
      case PaymentMethods.paytender:
      case PaymentMethods.payfirma:
      case PaymentMethods.canpay:
        setVerified(true);
        return;
      case PaymentMethods.janePay:
        setVerified(!!aeropayBankId);
        return;
      case PaymentMethods.square:
        setVerified(!!squareToken);
        return;
      case PaymentMethods.stronghold:
        setVerified(!!strongholdSourceId);
        return;
      default:
        setVerified(false);
        return;
    }
  }, [
    aeropayBankId,
    canpay_v2remotepay_enabled,
    selected,
    squareToken,
    strongholdSourceId,
    reservation_mode,
    allOptions,
  ]);

  useEffect(() => {
    handleVerify();
  }, [handleVerify]);
};
