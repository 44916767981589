import { useState } from 'react';

import { SpecialCard } from '@jane/shared-ecomm/components';
import {
  SPECIALS_PAGE_LIMIT,
  useMenu,
  useSpecials,
} from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import {
  Button,
  Flex,
  Skeleton,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { StyledSpecialsGrid } from './MenuSpecials.styles';

export const MenuSpecialsGrid = () => {
  const [showFullPage, setShowFullPage] = useState(false);
  const isMobile = useMobileMediaQuery({});
  const { store, paths } = useMenu();
  const {
    fetchNextSpecialsPage,
    hasNextSpecialsPage,
    loadingSpecialsCards,
    refetchSpecialsPage,
    specialsCards = [],
  } = useSpecials();

  const handleScroll = () => {
    const element = document.getElementById('specials-grid');
    element?.scrollIntoView({ behavior: 'smooth' });
  };

  const onViewLess = () => {
    setShowFullPage(false);
    refetchSpecialsPage();
    handleScroll();
  };

  const onViewMore = () => {
    if (!showFullPage) {
      setShowFullPage(true);
    } else {
      fetchNextSpecialsPage();
    }
  };

  const nextBreadcrumbs = [
    {
      title: store.specials_row_configs?.custom_display_name || 'Specials',
      url: paths.menuCategory('specials'),
    },
  ];

  const showViewMore =
    specialsCards &&
    ((specialsCards.length > 6 && !showFullPage) || hasNextSpecialsPage);

  const showViewLess =
    !showViewMore && specialsCards.length > SPECIALS_PAGE_LIMIT;

  const specialCardsToRender = !showFullPage
    ? specialsCards.slice(0, 6)
    : specialsCards;

  return (
    <Flex px={isMobile ? 24 : 64} flexDirection="column" alignItems="center">
      <StyledSpecialsGrid id="specials-grid">
        {loadingSpecialsCards && !specialsCards?.length
          ? Array.from({ length: 6 }, (_, i) => (
              <Skeleton.Bone height={240} width="100%" key={i} />
            ))
          : specialCardsToRender.map((special, index) => (
              <SpecialCard
                key={special.id}
                breadcrumbs={nextBreadcrumbs}
                special={special}
                store={store}
                to={paths.menuSpecial({
                  id: special.id,
                  title: special.title,
                })}
                onClick={() =>
                  track({
                    event: EventNames.StoreSpecialTap,
                    source: 'Specials Row',
                    specialRankOnList: index + 1,
                    storeName: store.name,
                    storeId: store.id,
                    specialId: special.id,
                    specialType: special.special_type,
                  })
                }
              />
            ))}
      </StyledSpecialsGrid>
      {!loadingSpecialsCards && (showViewLess || showViewMore) && (
        <Button
          label={showViewMore ? 'View more' : 'View less'}
          onClick={showViewMore ? onViewMore : onViewLess}
          full={isMobile}
          my={isMobile ? 24 : 48}
        />
      )}
    </Flex>
  );
};
