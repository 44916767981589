import isUndefined from 'lodash/isUndefined';
import { createContext, useContext, useMemo } from 'react';

import { useFacets } from '@jane/search/data-access';
import { AlgoliaFacetsSchema } from '@jane/search/types';
import { buildBrandFilterClause, composeFilters } from '@jane/search/util';

import type { MenuContextProps } from './menuProvider.types';

export const MenuContext = createContext<MenuContextProps>(
  {} as MenuContextProps
);

interface MenuProviderProps {
  children: React.ReactNode;
  value: MenuContextProps;
}

export const MenuProvider = ({ children, value }: MenuProviderProps) => {
  const {
    appInfo: { brandPartner },
    store,
  } = value;

  const staticFilters = composeFilters(
    `store_id = ${store?.id}`,
    brandPartner?.force_store_visibility ? 'at_visible_store:true' : '',
    brandPartner && buildBrandFilterClause(brandPartner)
  );

  const { data: facets, isLoading } = useFacets({
    enabled: !isUndefined(store?.id),
    schemaObject: AlgoliaFacetsSchema,
    staticFilters,
  });

  const contextValue = useMemo(
    () => ({
      ...value,
      loading: isLoading || value.loading,
      facets,
      staticFilters,
    }),
    [facets, isLoading, staticFilters, value]
  );

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return context;
};
