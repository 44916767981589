export const ZoneCart = 'cart';
export const ZoneStoreMenu = 'storeMenu';
export const ZoneStoreMenuInline = 'storeMenuInline';
export const ZoneStorePdp = 'storePDP';
export const ZoneMagicRow = 'magicRow';
export type Zone =
  | typeof ZoneCart
  | typeof ZoneStoreMenu
  | typeof ZoneStoreMenuInline
  | typeof ZoneStorePdp
  | typeof ZoneMagicRow;

export const SurfaceEcomm = 'ecomm';
export const SurfaceKiosk = 'kiosk';
export const SurfaceMarketplace = 'marketplace';

export type Surface =
  | typeof SurfaceEcomm
  | typeof SurfaceKiosk
  | typeof SurfaceMarketplace;
