import { useEffect, useState } from 'react';

import { useCheckout, useGoogleRecaptcha } from '@jane/shared-ecomm/providers';
import { Box, Loading, Modal, Typography } from '@jane/shared/reefer';

import { SharedButton } from '../../CheckoutButton/SharedButton';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { MonerisPortal } from './MonerisPortal';
import { useMoneris } from './useMoneris';

export const MonerisButton = () => {
  const { getValue, execute } = useGoogleRecaptcha();
  const {
    appInfo: { isCheckingOut },
  } = useCheckout();
  const [modalOpen, setModalOpen] = useState(false);
  const { updatePrepayments } = useCheckoutPayments();

  const handleSuccess = (ticket: string) => {
    setModalOpen(false);
    updatePrepayments({ monerisTicket: ticket });
  };

  const { openWidget, closeWidget, widgetLoaded } = useMoneris({
    onCancel: () => setModalOpen(false),
    onSuccess: handleSuccess,
    onError: () => setModalOpen(false),
  });

  useEffect(() => {
    if (modalOpen && widgetLoaded) {
      openWidget();
    }
  }, [modalOpen, widgetLoaded, openWidget]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
      if (widgetLoaded) {
        closeWidget();
      }
    };
  }, []);

  const handleCloseWidget = () => {
    setModalOpen(false);
    closeWidget();
  };

  const handleClick = () => {
    const token = getValue();

    if (token) {
      setModalOpen(true);
    } else {
      execute({
        onSuccess: () => setModalOpen(true),
      });
    }
  };

  return (
    <>
      <SharedButton
        label="Pay now with card"
        onClick={handleClick}
        loading={!widgetLoaded || modalOpen}
      />

      {modalOpen && <MonerisPortal onBackPress={handleCloseWidget} />}

      {isCheckingOut && (
        <Modal variant="dialogue" open={true} onRequestClose={() => null}>
          <Modal.Content>
            <Box mb={16} position="relative">
              <Loading size="lg" />
            </Box>
            <Typography textAlign="center">Processing payment...</Typography>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
