import { useCheckout } from '@jane/shared-ecomm/providers';
import { useShouldShowGold } from '@jane/shared/hooks';
import { Typography } from '@jane/shared/reefer';

import { CheckoutButton } from '../CheckoutButton/CheckoutButton';
import { CheckoutJaneGoldCallout } from '../CheckoutJaneGoldCallout/CheckoutJaneGoldCallout';
import { CheckoutStoreCard } from '../CheckoutStoreCard/CheckoutStoreCard';
import { CheckoutStep } from '../checkout.styles';
import { useAutoSubmitCheckout } from '../util/hooks/useAutoSubmitCheckout';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useSelectedFulfillmentTime } from '../util/hooks/useSelectedFulfillmentTime';
import { CheckoutTotal } from './CheckoutTotal';
import { StoreHours } from './StoreHours';

export const CheckoutSummary = () => {
  // This only exists here because it's guaranteed to exist once within all the providers that it pulls data from.
  useAutoSubmitCheckout();

  const {
    store: {
      id,
      state,
      customer_paperwork,
      show_paperwork,
      jane_pay_integration,
    },
  } = useCheckout();
  const showGold = useShouldShowGold({ store: { id, state } });
  const hideJanePayBranding = jane_pay_integration
    ? Boolean(jane_pay_integration?.white_label) ||
      Boolean(!jane_pay_integration?.enabled)
    : false;

  const { reservationModeLabel } = useReservationModeLabels();

  const { displayName } = useSelectedFulfillmentTime();

  return (
    <CheckoutStep>
      <Typography variant="header-bold" branded mb={24}>
        {reservationModeLabel} reservation
      </Typography>

      {displayName && (
        <>
          <Typography color="text-light">Scheduled time</Typography>
          <Typography variant="body-bold" mb={24}>
            {displayName}
          </Typography>
        </>
      )}

      <Typography color="text-light" mb={16}>
        Store
      </Typography>
      <CheckoutStoreCard />
      <StoreHours />

      {show_paperwork && !!customer_paperwork && (
        <>
          <Typography color="text-light" mt={24}>
            Reminder
          </Typography>
          <Typography>{customer_paperwork}</Typography>
        </>
      )}

      <hr />

      <CheckoutTotal />

      <CheckoutButton />

      {showGold && (
        <CheckoutJaneGoldCallout
          isSummary
          hideJanePayBranding={hideJanePayBranding}
        />
      )}
    </CheckoutStep>
  );
};
