import dayjs from 'dayjs';
import { Suspense, lazy } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { LoadingWrapper } from '@jane/shared/components';
import { useMfa } from '@jane/shared/providers';
import {
  Box,
  Button,
  Flex,
  Form,
  Grid,
  Typography,
  emailRegex,
  telRegex,
} from '@jane/shared/reefer';
import {
  formatTime,
  postMessageToIframeParent,
  timeIsSameOrBefore,
} from '@jane/shared/util';

import { CheckoutAccordionItem } from '../CheckoutAccordion/CheckoutAccordionItem';
import { StyledGridContainer } from '../checkout.styles';
import { formatPhone } from '../util/formatPhone';
import { useCheckoutDocs } from '../util/hooks/useCheckoutDocs';
import { useCheckoutInfo } from '../util/hooks/useCheckoutInfo';
import { useCheckoutInfoIsValid } from '../util/hooks/useCheckoutInfoIsValid';

/** Code splitting to load convertIfPDF/pdfjs only when called */
const FormImageUpload = lazy(() =>
  import('./FormImageUpload').then(({ FormImageUpload }) => ({
    default: FormImageUpload,
  }))
);

export const CheckoutInformation = () => {
  const { launch } = useMfa();

  const {
    customer: { authenticated, phone: originalPhone },
    store: { birth_date_required, checkout_age_minimum },
  } = useCheckout();

  const { docsValid, formValid } = useCheckoutInfoIsValid();

  const { control } = useFormContext();

  const {
    requiredDocs: { governmentId, medicalPhoto, medicalNumber },
    medicalCardUploadText,
    medicalIdLanguage,
  } = useCheckoutDocs();

  const { handleContinue } = useCheckoutInfo();

  const { firstName, lastName, phone } = useWatch({
    control,
    name: 'customerInformation',
  });

  const fullName = `${firstName} ${lastName}`.trim();
  const showSummary = !!firstName && !!lastName && !!phone;

  const handleSubmitOr2fa = () => {
    if (!authenticated || originalPhone === phone) {
      handleContinue();
      return;
    }
    launch({
      onSuccess: handleContinue,
      newPhone: phone,
    });
  };

  const validateAge = (birthDate: string): string | true => {
    if (birthDate?.length < 8) return 'Please fill out this field';

    const minimumAge = checkout_age_minimum || 18;
    const minAgeDate = dayjs().subtract(minimumAge, 'year');
    const dob = formatTime(birthDate, 'YYYY-MM-DD', 'MMDDYYYY');
    const dobAsDayjs = dayjs(dob);

    const isMinimumAge = timeIsSameOrBefore(dobAsDayjs, minAgeDate, 'day');

    return isMinimumAge
      ? true
      : `You must be older than ${minimumAge} to continue`;
  };

  const {
    appInfo: { bloomUserExperience },
  } = useCheckout();

  return (
    <CheckoutAccordionItem
      id="info"
      header="Enter your information"
      summary={
        showSummary && (
          <>
            {!!fullName && <Typography>{fullName}</Typography>}
            {!!phone && <Typography>{formatPhone(phone)}</Typography>}
          </>
        )
      }
    >
      <Suspense fallback={<LoadingWrapper isLoading />}>
        <Box>
          {bloomUserExperience && !authenticated && (
            <Button
              label="Sign in for faster checkout"
              type="button"
              variant="primary"
              onClick={() =>
                postMessageToIframeParent({
                  messageType: 'bloomEvent',
                  payload: {
                    name: 'initiateExternalAuth',
                  },
                })
              }
              full
              mb={24}
            />
          )}
          {governmentId && (
            <Box mb={24}>
              <Typography variant="body-bold">Government ID</Typography>
              <Typography mb={8}>
                This will help stores validate your identity and will not be
                made visible to anyone else.
              </Typography>
              <FormImageUpload
                name="customerInformation.governmentIdentificationUrl"
                fileName="government_photo"
                label="Upload government ID"
              />
            </Box>
          )}

          {medicalPhoto && (
            <Box mb={24}>
              <Typography variant="body-bold">Medical Marijuana</Typography>
              <Typography mb={8}>{medicalCardUploadText}</Typography>
              <Flex gap={16}>
                <FormImageUpload
                  name="customerInformation.mmjIdFrontUrl"
                  fileName="mmj_card"
                  label="Upload front of card"
                />
                <FormImageUpload
                  name="customerInformation.mmjIdBackUrl"
                  fileName="mmj_card_back"
                  label="Upload back of card"
                />
              </Flex>
            </Box>
          )}

          <StyledGridContainer spacing={24}>
            {medicalNumber ? (
              <Grid.Item xs={12} lg={6}>
                <Form.TextField
                  borderRadius="xs"
                  disableMobileInputStyling
                  helperText="Include all dashes and special characters"
                  label={`ID number on your ${medicalIdLanguage} card`}
                  maxLength={100}
                  name="customerInformation.mmjIdNumber"
                  required
                />
              </Grid.Item>
            ) : (
              <></>
            )}

            <Grid.Item xs={12} lg={6}>
              <Form.NumberField
                allowLeadingZeros
                borderRadius="xs"
                disableMobileInputStyling
                helperText="Used only for order updates via SMS"
                label="Mobile phone number"
                name="customerInformation.phone"
                maskFormat="(###) ###-####"
                validate={(value: string) =>
                  !!value.match(telRegex) || 'Please enter valid phone number'
                }
              />
            </Grid.Item>

            <Grid.Item xs={12} lg={6}>
              <Form.TextField
                borderRadius="xs"
                disableMobileInputStyling
                label="First name on your photo ID"
                maxLength={100}
                name="customerInformation.firstName"
                required
              />
            </Grid.Item>

            <Grid.Item xs={12} lg={6}>
              <Form.TextField
                borderRadius="xs"
                disableMobileInputStyling
                label="Last name on your photo ID"
                maxLength={100}
                name="customerInformation.lastName"
                required
              />
            </Grid.Item>

            {birth_date_required ? (
              <Grid.Item xs={12} lg={6}>
                <Form.NumberField
                  allowLeadingZeros
                  borderRadius="xs"
                  disableMobileInputStyling
                  name="customerInformation.birthDate"
                  label="Birth date"
                  placeholder="MM/DD/YYYY"
                  maskFormat="##/##/####"
                  validate={validateAge}
                />
              </Grid.Item>
            ) : (
              <></>
            )}

            <Grid.Item xs={12} lg={6}>
              <Form.TextField
                borderRadius="xs"
                disableMobileInputStyling
                label="Email"
                maxLength={100}
                name="customerInformation.email"
                type="email"
                validate={(value: string | undefined) =>
                  !!value?.match(emailRegex) || 'Please enter a valid email'
                }
              />
            </Grid.Item>
          </StyledGridContainer>

          <Button
            onClick={() => handleSubmitOr2fa()}
            disabled={!docsValid || !formValid}
            full
            label="Continue"
            mt={24}
          />
        </Box>
      </Suspense>
    </CheckoutAccordionItem>
  );
};
