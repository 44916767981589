import { useLocation } from 'react-router-dom';

import { useSearchContext } from '@jane/search/providers';
import { buildFilter } from '@jane/search/util';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { EventNames, Track } from '@jane/shared-ecomm/tracking';
import { Flex, useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuSpecialsBanner } from '../../MenuComponents/MenuBanners/MenuSpecialsBanner';
import { MenuFiltersAndProductsGrid } from '../../MenuComponents/MenuFiltersAndProductsGrid';
import { MenuTitle } from '../../MenuComponents/MenuTitle';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import { MenuSpecialsGrid } from './MenuSpecialsGrid';

export const MenuSpecials = () => {
  const location = useLocation();
  const isMobile = useMobileMediaQuery({});
  const { store } = useMenu();
  const { searchState } = useSearchContext();
  const { specialIds } = useSpecials();

  const pageTitle =
    store.specials_row_configs?.custom_display_name || 'Specials';

  const specialsFilter = buildFilter('applicable_special_ids', specialIds);
  const staticFilters = useComposeMenuFilters([specialsFilter]);
  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;

  const nextBreadcrumbs = [
    {
      title: pageTitle,
      url: location.pathname,
    },
  ];

  return (
    <Flex flexDirection="column" gap={isMobile ? 24 : 48} height="100%">
      <Track event={{ event: EventNames.ViewedSpecial, isGold: false }} />
      <MenuTitle title={pageTitle} px={isMobile ? 24 : 64} />
      <MenuSpecialsBanner />

      {!isFilteringProducts && <MenuSpecialsGrid />}
      <MenuFiltersAndProductsGrid
        breadcrumbs={nextBreadcrumbs}
        menuCategory="specials"
        pageTitle="Specials"
        staticFilters={staticFilters}
      />
    </Flex>
  );
};
