import { Flex, TagIcon, useMobileMediaQuery } from '@jane/shared/reefer';

export const BundleTagCard = ({ isActive }: { isActive: boolean }) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex
      width={isMobile ? 98 : 120}
      height={isMobile ? 98 : 120}
      alignItems="center"
      justifyContent="center"
      borderRadius="sm"
      position="relative"
      border={isActive ? 'sunset' : 'grays-light'}
      data-testid="add-discounted-product"
    >
      <TagIcon color={isActive ? 'sunset' : 'grays-mid'} />
    </Flex>
  );
};
