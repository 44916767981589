import type { UserConsentItem } from '@jane/shared/models';

import type { ConsentItems } from '../CheckoutFormProvider/checkoutForm.types';

export const toPayloadValues = (
  consentItems: ConsentItems
): UserConsentItem[] => {
  if (!consentItems) return [];
  const userConsentKeys = Object.keys(consentItems);

  const payloadConsents = userConsentKeys.map((key) => ({
    checkout_agreement_version_id: parseInt(key),
    consent: consentItems[`${parseInt(key)}`] || false,
  }));

  return payloadConsents;
};
