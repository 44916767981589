import { useMutation } from '@tanstack/react-query';

import { janeApi } from '@jane/shared/data-access';

import { urls } from './paytender.util';

const patchCancelPaytender = async (orderReference: string) =>
  await janeApi.patch(urls.cancel(orderReference), {});

export const usePaytenderCancel = () => {
  return useMutation({
    mutationFn: ({ orderReference }: { orderReference: string }) =>
      patchCancelPaytender(orderReference),
  });
};
