import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { config } from '@jane/shared/config';
import {
  Box,
  ConditionalWrapper,
  Flex,
  JaneLogo,
  ReeferThemeProvider,
  Typography,
} from '@jane/shared/reefer';
import { trackError as trackErrorUtil } from '@jane/shared/util';

export interface ErrorPageProps {
  button?: ReactNode;
  error?: Error | string;
  whiteLabel?: boolean;
  withReeferThemeProvider?: boolean;
}

export function ErrorPage({
  button,
  error,
  whiteLabel = true,
  withReeferThemeProvider = false,
}: ErrorPageProps) {
  const errorName = typeof error === 'string' ? error : error?.name;
  return (
    <ConditionalWrapper
      condition={withReeferThemeProvider}
      wrapper={(children) => (
        <ReeferThemeProvider>{children}</ReeferThemeProvider>
      )}
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        p={48}
      >
        {!whiteLabel && <JaneLogo size="lg" />}
        <Typography mb={24} variant="title">
          Oops!
        </Typography>
        <Typography mb={24}>
          Sorry, an unexpected error has occurred.
        </Typography>
        {button}
      </Flex>
      {config.dev && error && (
        <Box p={48}>
          <Typography my={24} variant="title">
            Error Details
          </Typography>
          <Box
            as="pre"
            background="grays-dark"
            p={24}
            style={{ color: 'white' }}
            width="100%"
          >
            {errorName && <code>Error Name: {errorName}</code>}
            <br />
            {typeof error !== 'string' && error?.message && (
              <code>Error Message: {error.message}</code>
            )}
            <br />
            <br />
            {typeof error !== 'string' && error?.stack && (
              <code>{error.stack}</code>
            )}
          </Box>
        </Box>
      )}
    </ConditionalWrapper>
  );
}

export interface RouterErrorPageProps
  extends Omit<ErrorPageProps, 'error'>,
    PropsWithChildren {
  trackError?: (error: Error | string) => void;
}

export function RouterErrorPage({
  button,
  trackError = trackErrorUtil,
  whiteLabel = true,
  withReeferThemeProvider = false,
}: RouterErrorPageProps) {
  const error = useRouteError();
  useEffect(() => {
    trackError(error as Error);
  }, [error, trackError]);

  return (
    <ErrorPage
      button={button}
      error={error as Error}
      whiteLabel={whiteLabel}
      withReeferThemeProvider={withReeferThemeProvider}
    />
  );
}
