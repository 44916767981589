import { Typography } from '@jane/shared/reefer';

interface Props {
  address: {
    city?: string | null;
    state_code?: string | null;
    street?: string | null;
    street2?: string | null;
    zipcode?: string | null;
  };
}

const FormattedAddress = ({ address }: Props) => {
  const line1 = [address.street, address.street2].filter(Boolean).join(', ');
  const line2 = [
    [address.city, address.state_code].filter(Boolean).join(', '),
    address.zipcode,
  ].join(' ');

  return (
    <Typography as="p" variant="body">
      {[line1, <br key="break" />, line2]}
    </Typography>
  );
};

export default FormattedAddress;
