import { useMenu } from '@jane/shared-ecomm/providers';
import { Box, Typography } from '@jane/shared/reefer';
import { formatTime, titleCase } from '@jane/shared/util';

import { StyledGrid } from './MenuHeader.styles';

export const MenuSchedule = () => {
  const {
    store: { working_hours },
  } = useMenu();

  const schedule = working_hours.map((schedule, index) => {
    const { day, period } = schedule;
    const startTime =
      period?.from && formatTime(period.from, 'h:mm a', 'hh:mm');
    const endTime = period?.to && formatTime(period.to, 'h:mm a', 'hh:mm');
    return (
      <StyledGrid key={day} pt={index !== 0 ? 4 : 0}>
        <Typography>{titleCase(day)}</Typography>
        {!!startTime && !!endTime ? (
          <Typography color="text-light" whiteSpace="nowrap">
            {startTime} - {endTime}
          </Typography>
        ) : (
          <Typography color="text-light">Closed</Typography>
        )}
      </StyledGrid>
    );
  });

  return <Box pt={8}>{schedule}</Box>;
};
