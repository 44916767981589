export const insertIfArr = <T>(condition: boolean, element: T): T[] => {
  return condition ? [element] : [];
};

export const insertIfObj = <T extends object>(
  condition: boolean,
  obj: T
): Partial<T> | Record<string, never> => {
  return condition ? obj : {};
};
