import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { JanePayLogoWhite } from '@jane/shared/reefer';
import type { IconComponentProps } from '@jane/shared/reefer';

import { useCheckoutPayments } from '../CheckoutPayments/CheckoutPaymentsProvider';
import { useCartIsValid } from '../util/hooks/useCartIsValid';
import { useCheckoutInfo } from '../util/hooks/useCheckoutInfo';
import { useCheckoutOptionsIsValid } from '../util/hooks/useCheckoutOptionsIsValid';
import { PaymentMethods } from '../util/hooks/usePaymentOptions';
import { ThemedButton } from './checkoutButton.styles';

export const SharedButton = ({
  icon,
  label,
  loading = false,
  onClick,
}: {
  icon?: React.FC;
  label: string;
  loading?: boolean;
  onClick(): void;
}) => {
  const {
    appInfo: { isCheckingOut },
    store: { jane_pay_integration },
  } = useCheckout();

  const Icon = icon as IconComponentProps;

  const { selected, verified } = useCheckoutPayments();

  const { cartIsValid } = useCartIsValid();

  const { control } = useFormContext();
  const { isValid } = useFormState({ control });

  const stepOneValid = useCheckoutOptionsIsValid();

  const { isValid: stepTwoValid } = useCheckoutInfo();

  const readyToCheckout =
    verified && cartIsValid && stepOneValid && stepTwoValid && isValid;

  const showJanePay = Boolean(
    !jane_pay_integration?.white_label && selected === PaymentMethods.janePay
  );

  const informativeLabel = !stepOneValid
    ? 'Confirm your reservation details'
    : !stepTwoValid
    ? 'Enter your information'
    : null;

  return (
    <ThemedButton
      mt={24}
      full
      disabled={!readyToCheckout}
      janePay={showJanePay}
      endIcon={
        !informativeLabel && icon ? (
          <Icon />
        ) : !informativeLabel && showJanePay ? (
          <JanePayLogoWhite height={18} />
        ) : null
      }
      loading={loading || isCheckingOut}
      label={informativeLabel || label}
      onClick={onClick}
    />
  );
};
