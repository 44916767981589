import { Helmet } from 'react-helmet-async';

import { LogInModal, PageLayout } from '@jane/shared-ecomm/components';
import {
  useAuthentication,
  useCheckout,
  useGoogleRecaptcha,
} from '@jane/shared-ecomm/providers';
import { Accordion, LoadingWrapper } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import { useShouldShowGold } from '@jane/shared/hooks';
import { Box, Flex, useDesktopMediaQuery } from '@jane/shared/reefer';

import { CheckoutAccordionProvider } from './CheckoutAccordion/CheckoutAccordionProvider';
import { CheckoutAlerts } from './CheckoutAlerts/CheckoutAlerts';
import { CheckoutFormProvider } from './CheckoutFormProvider/CheckoutFormProvider';
import { CheckoutHeader } from './CheckoutHeader/CheckoutHeader';
import { CheckoutInformation } from './CheckoutInformation/CheckoutInformation';
import { CheckoutJaneGoldCallout } from './CheckoutJaneGoldCallout/CheckoutJaneGoldCallout';
import { CheckoutOptions } from './CheckoutOptions/CheckoutOptions';
import { CheckoutPayments } from './CheckoutPayments/CheckoutPayments';
import { CheckoutPaymentsProvider } from './CheckoutPayments/CheckoutPaymentsProvider';
import { CheckoutReview } from './CheckoutReview/CheckoutReview';
import { CheckoutSummary } from './CheckoutSummary/CheckoutSummary';
import { CheckoutTerms } from './CheckoutTerms/CheckoutTerms';
import { CheckoutToggle } from './CheckoutToggle/CheckoutToggle';
import { ContentContainer, HeaderRow } from './checkout.styles';
import { useFulfillmentSchedule } from './util/hooks/useFulfillmentSchedule';
import { PaymentMethods } from './util/hooks/usePaymentOptions';

export const Checkout = () => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });

  const { ReCAPTCHA } = useGoogleRecaptcha();
  const { isLoadingPickup } = useFulfillmentSchedule();

  const {
    appInfo: {
      disablePrepayment,
      isEmbeddedMode,
      bloomUserExperience,
      headlessLoginUrl,
    },
    customer: { authenticated },
    prepaymentProviders,
    guestCheckout: { showLoginModal, setPreAuthSelection, setShowLoginModal },
    store,
  } = useCheckout();

  const showGold = useShouldShowGold(
    store.id ? { store: { id: store.id, state: store.state } } : {}
  );
  const hideJanePayBranding = store
    ? Boolean(store?.jane_pay_integration?.white_label) ||
      Boolean(!store?.jane_pay_integration?.enabled)
    : false;

  const { defaultLogin, register, resetError, resetPassword, ...authProps } =
    useAuthentication();

  const handleShowLogin = () => {
    setShowLoginModal(true);
  };

  const hasStrongHold =
    !disablePrepayment &&
    prepaymentProviders?.includes(PaymentMethods.stronghold);
  const hasCanpay =
    !disablePrepayment && prepaymentProviders?.includes(PaymentMethods.canpay);
  const hasSquare =
    !disablePrepayment && prepaymentProviders?.includes(PaymentMethods.square);
  const hasAeropay =
    !disablePrepayment && prepaymentProviders?.includes(PaymentMethods.aeropay);
  const hasMoneris =
    !disablePrepayment && prepaymentProviders?.includes(PaymentMethods.moneris);

  return (
    <>
      <Helmet>
        {hasStrongHold && (
          <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
        )}
        {hasStrongHold && (
          <script src="https://api.strongholdpay.com/v2/js"></script>
        )}
        {hasAeropay && (
          <script defer src="https://sdk.aeropay.com/ap/aeropay.js"></script>
        )}
        {hasCanpay && <script src={config.canPayV2RemotePaySDK}></script>}
        {hasSquare && <script src={config.squareWebPaymentsSdkUrl}></script>}
        {hasMoneris && (
          <script defer async src={config.monerisJsSdkUrl}></script>
        )}
      </Helmet>
      <HeaderRow>
        <CheckoutHeader handleShowLogin={handleShowLogin} />
      </HeaderRow>
      <LoadingWrapper variant="checkout" isLoading={isLoadingPickup}>
        <CheckoutFormProvider>
          <CheckoutPaymentsProvider>
            <CheckoutAccordionProvider>
              <CheckoutAlerts />
              <PageLayout
                offscreenTitle="checkout"
                px={isDesktop ? 64 : 0}
                py={isDesktop ? (bloomUserExperience ? 40 : 64) : 0}
              >
                <ContentContainer>
                  <Flex flexDirection="column">
                    {((bloomUserExperience && isDesktop) ||
                      !bloomUserExperience) && (
                      <CheckoutToggle handleShowLogin={handleShowLogin} />
                    )}

                    <Accordion
                      autoScrollMargin={isEmbeddedMode ? '48px' : '140px'}
                    >
                      {bloomUserExperience && <CheckoutInformation />}

                      <CheckoutOptions />

                      {!bloomUserExperience && <CheckoutInformation />}

                      <CheckoutPayments />
                    </Accordion>

                    {showGold && (
                      <CheckoutJaneGoldCallout
                        headlessLoginUrl={headlessLoginUrl}
                        hideJanePayBranding={hideJanePayBranding}
                      />
                    )}

                    <CheckoutReview />
                  </Flex>

                  <Box>
                    <CheckoutSummary />

                    <CheckoutTerms />
                  </Box>
                </ContentContainer>

                <ReCAPTCHA />
              </PageLayout>
            </CheckoutAccordionProvider>
          </CheckoutPaymentsProvider>
        </CheckoutFormProvider>
      </LoadingWrapper>

      {showLoginModal && !authenticated && (
        <LogInModal
          {...authProps}
          onSignInSubmit={defaultLogin}
          onCreateAccountSubmit={register}
          onForgotPasswordSubmit={resetPassword}
          location="checkout"
          open
          onRequestClose={() => {
            resetError();
            setPreAuthSelection(null);
            setShowLoginModal(false);
          }}
        />
      )}
    </>
  );
};
