import { useLocation, useParams } from 'react-router-dom';

type Page =
  | 'activity'
  | 'brands'
  | 'brandDetail'
  | 'buyAgain'
  | 'category'
  | 'featured'
  | 'featuredCategory'
  | 'janeGold'
  | 'productDetail'
  | 'specialDetail'
  | 'reviews'
  | 'specials'
  | 'menu';

export const useGetPage = (): Page => {
  const params = useParams();
  const location = useLocation();

  if (params['activity']) return 'activity';
  if (params['brand_id']) return 'brandDetail';
  if (params['featured_category']) return 'featuredCategory';
  if (params['category']) return 'category';
  if (params['product_id']) return 'productDetail';
  if (params['special_id']) return 'specialDetail';

  const pathParts = location.pathname.split('/');
  const lastPart = pathParts[pathParts.length - 1];

  if (lastPart === 'brands') return 'brands';
  if (lastPart === 'featured') return 'featured';
  if (lastPart === 'jane-gold') return 'janeGold';
  if (lastPart === 'reviews') return 'reviews';
  if (lastPart === 'specials') return 'specials';

  return 'menu';
};
