import { useMenu } from '@jane/shared-ecomm/providers';
import { trackStoreInfoClick } from '@jane/shared-ecomm/tracking';
import {
  Box,
  CheckIcon,
  Flex,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { titleCase } from '@jane/shared/util';

import { MenuHours } from './MenuHours';

const getResponseTime = (responseTime: number) => {
  const responseTimeMins = Math.round(responseTime / 60);
  if (responseTimeMins <= 10) {
    return '0-10';
  } else if (responseTimeMins <= 30) {
    return '10-30';
  } else {
    return '30+';
  }
};

export const MenuAbout = () => {
  const {
    store: { address, city, phone, avg_response_time, store_options },
  } = useMenu();

  const isMobile = useMobileMediaQuery({});

  if (isMobile) {
    return (
      <Box>
        <Typography>
          {address}, {city}
        </Typography>
        <MenuHours inModal={false} />
        <Link
          href={`tel:${phone}`}
          onClick={() => trackStoreInfoClick('phone')}
        >
          <Typography>{phone}</Typography>
        </Link>
      </Box>
    );
  }

  return (
    <Flex flexWrap="wrap">
      <Typography>
        {address}, {city}
      </Typography>
      <Typography as="span" mx={4}>
        &#8226;
      </Typography>
      <MenuHours inModal={false} />
      <Flex flexWrap="wrap" gap={8} pl={8}>
        {avg_response_time && (
          <Flex as="span" alignItems="center">
            <CheckIcon size="sm" mr={4} />
            <Typography>
              Responds {getResponseTime(avg_response_time)} mins
            </Typography>
          </Flex>
        )}
        {store_options &&
          store_options.map((option) => (
            <Flex as="span" alignItems="center" key={option}>
              <CheckIcon size="sm" mr={4} />
              <Typography>
                {option === 'accessible' ? 'Wheelchair' : titleCase(option)}
              </Typography>
            </Flex>
          ))}
      </Flex>
    </Flex>
  );
};
