import { useStrongholdCustomer } from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, Typography } from '@jane/shared/reefer';

import { PaymentMethods } from '../../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

export const StrongholdHeader = () => {
  const {
    customer: { firstName, lastName, phone },
    store,
  } = useCheckout();
  const { selected } = useCheckoutPayments();
  const { data: strongholdCustomerData } = useStrongholdCustomer(
    {
      firstName,
      lastName,
      phone,
      storeId: store.id,
    },
    { enabled: selected === PaymentMethods.stronghold }
  );

  const activeSource =
    strongholdCustomerData &&
    strongholdCustomerData?.customer.payment_sources.find(
      (source) => source.active
    );

  const defaultSource =
    activeSource || strongholdCustomerData?.customer.payment_sources[0];

  return (
    <Box>
      <Typography>Stronghold</Typography>

      {selected === PaymentMethods.stronghold && defaultSource && (
        <Typography mt={8} variant="body-bold">
          {defaultSource.label}
        </Typography>
      )}
    </Box>
  );
};
