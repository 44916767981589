import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutDocs } from './useCheckoutDocs';

export const useCheckoutInfoIsValid = () => {
  const {
    store: { birth_date_required },
  } = useCheckout();
  const { requiredDocs } = useCheckoutDocs();

  const { control } = useFormContext();
  const values = useWatch({ control, name: 'customerInformation' });
  const { errors } = useFormState({ control });

  const {
    birthDate,
    email,
    firstName,
    governmentIdentificationUrl,
    lastName,
    mmjIdBackUrl,
    mmjIdFrontUrl,
    mmjIdNumber,
    phone,
  } = values;

  const { governmentId, medicalPhoto, medicalNumber } = requiredDocs;

  const formValid =
    (!!birthDate || !birth_date_required) &&
    !!firstName &&
    !!lastName &&
    !!phone &&
    !!email &&
    !errors?.['customerInformation'];

  const docsValid =
    (!governmentId || !!governmentIdentificationUrl) &&
    (!medicalNumber || !!mmjIdNumber) &&
    (!medicalPhoto || (!!mmjIdBackUrl && !!mmjIdFrontUrl));

  return {
    docsValid,
    formValid,
  };
};
