import { useCheckout } from '@jane/shared-ecomm/providers';

import { useSelectedFulfillmentTime } from './useSelectedFulfillmentTime';
import { useUserConsents } from './useUserConsents';

export const useCheckoutOptionsIsValid = () => {
  const {
    cart: { reservation_mode },
    deliveryValidation,
  } = useCheckout();

  const { isValid: consentsValid } = useUserConsents();
  const { isValid: fulfillmentTimeValid } = useSelectedFulfillmentTime();

  const deliveryValid =
    reservation_mode !== 'delivery' || deliveryValidation?.data?.can_deliver;

  return consentsValid && deliveryValid && fulfillmentTimeValid;
};
