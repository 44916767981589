import { useCheckout } from '@jane/shared-ecomm/providers';

import { CheckoutDeliveryOptions } from './CheckoutDeliveryOptions';
import { CheckoutPickupOptions } from './CheckoutPickupOptions';

export const CheckoutOptions = () => {
  const {
    cart: { reservation_mode },
  } = useCheckout();

  if (reservation_mode === 'pickup') {
    return <CheckoutPickupOptions />;
  }

  return <CheckoutDeliveryOptions />;
};
