import { buildFilter } from '@jane/search/util';
import { usePurchasedProducts } from '@jane/shared-ecomm/data-access';
import { useMenu } from '@jane/shared-ecomm/providers';
import type { PurchasedProduct } from '@jane/shared/types';

export interface UseBuyAgainProps {
  userId?: number;
}

interface UseBuyAgainResponse {
  purchasedProductFilter: string;
  purchasedProducts: PurchasedProduct[];
}

export const useBuyAgain = ({
  userId,
}: UseBuyAgainProps): UseBuyAgainResponse => {
  const { store } = useMenu();

  const { data: purchasedProducts = [] } = usePurchasedProducts(
    {
      limit: 60,
      storeId: Number(store.id),
      userId,
    },
    { enabled: !!userId }
  );

  const purchasedProductIds = purchasedProducts?.map(
    ({ product_id }) => product_id
  );

  const purchasedProductFilter = buildFilter('product_id', purchasedProductIds);

  return {
    purchasedProductFilter,
    purchasedProducts,
  };
};
