import { useEffect } from 'react';

import { SpecialBundleEnticement } from '@jane/shared-ecomm/components';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { useToast } from '@jane/shared/reefer';

export const MenuSpecialBundleEnticement = () => {
  const toast = useToast();
  const {
    appInfo: { bundlePossibilitiesState },
    dispatches: { clearTouchedProduct, onSpecialEnticementClick },
    paths,
    store,
  } = useMenu();
  const { specials } = useSpecials();

  const { touchedProductId, bundlePossibilities: bundle } =
    bundlePossibilitiesState || {};

  useEffect(() => {
    if (bundle && touchedProductId && specials && specials.length > 0) {
      const bundleSpecial = specials.find((s) => s.id === bundle.special_id);

      if (bundleSpecial) {
        toast.add({
          label: (
            <SpecialBundleEnticement
              bundlePossibilities={bundle}
              special={bundleSpecial}
              storeId={Number(store.id)}
              onClick={onSpecialEnticementClick}
              to={paths.menuSpecial({
                id: bundleSpecial.id,
                title: bundleSpecial.title,
              })}
            />
          ),
          'data-testid': 'bundle-enticement-toast',
          time: 4000,
        });
      }
    }

    return () => {
      if (touchedProductId) {
        clearTouchedProduct();
      }
    };
  }, [touchedProductId, bundle, specials, store]);

  return null;
};
