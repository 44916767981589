import {
  ReadMore,
  Typography,
  useDesktopMediaQuery,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import type { MenuTitleProps } from './MenuTitle';

export const DefaultTitle = ({
  description,
  title,
}: Omit<MenuTitleProps, 'variant'>) => {
  const isMobile = useMobileMediaQuery({});
  const isDesktop = useDesktopMediaQuery({});

  return (
    <>
      {!!title && (
        <Typography branded variant="title-bold">
          {title}
        </Typography>
      )}

      {!!description && (
        <Typography as="div" pt={isDesktop ? 8 : 4}>
          <ReadMore
            characters={isMobile ? 100 : isDesktop ? 300 : 200}
            content={description}
            more="View more"
            less="View less"
          />
        </Typography>
      )}
    </>
  );
};
