import { useRoutes } from 'react-router-dom';

import {
  CustomerGlobal,
  MediaQueryProvider,
  Modals,
  SkipLinks,
  initAlgoliaInsights,
  setPartnerHostedConfig,
  useScrollToApp,
} from '@jane/monolith/boost';
import { config } from '@jane/shared/config';
import { maybeSetPublicPath } from '@jane/shared/public-path';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import {
  initCrmMixpanel,
  initMixpanel,
  initTrackJS,
  loadTimezoneData,
} from '@jane/shared/util';

import { buildBoostForAllRoutes } from './app/boost-for-all-routes';
import { buildRoutes } from './app/routes';
import ShadowRoot from './shadow-root';

maybeSetPublicPath('frameless_embeds/');
initMixpanel(config.mixpanelCustomerToken, {
  userSessionHost: config.mixpanelUserSessionApiHost,
  useLocalStorage: true,
});
initCrmMixpanel(config.mixpanelCrmToken);
initTrackJS();
loadTimezoneData();
initAlgoliaInsights();

const App = () => {
  useScrollToApp('shadow-host');

  const { isPartnerHosted, partnerHostedPath, isBoostForAll } =
    useRuntimeConfig();

  const routes = isBoostForAll
    ? buildBoostForAllRoutes()
    : buildRoutes(isPartnerHosted, partnerHostedPath);

  const routing = useRoutes(routes);

  setPartnerHostedConfig({ isBoostForAll, isPartnerHosted, partnerHostedPath });

  if (!isPartnerHosted) return <div>Partner Config Not Found!</div>;

  return (
    <ShadowRoot>
      <CustomerGlobal />
      <MediaQueryProvider>
        <SkipLinks />
        <Modals />
        <div style={{ minHeight: '100vh' }}>{routing}</div>
      </MediaQueryProvider>
    </ShadowRoot>
  );
};

export default App;
