export const formatPhone = (phone: string) => {
  const numbers = phone.replace(/\D+/g, '');
  if (numbers.length === 10)
    return numbers.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  if (numbers.length === 11)
    return numbers.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');

  return phone;
};
