import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useFulfillmentSchedule } from './useFulfillmentSchedule';

export const useSelectedFulfillmentTime = () => {
  const {
    cart: { reservation_mode },
  } = useCheckout();

  const { control } = useFormContext();
  const [deliveryOptions, pickupOptions] = useWatch({
    control,
    name: ['deliveryOptions', 'pickupOptions'],
  });

  const { deliverySlots, curbsideSlots, pickupSlots } =
    useFulfillmentSchedule();

  const fulfillmentTime =
    reservation_mode === 'delivery'
      ? deliveryOptions.fulfillmentTime
      : pickupOptions.fulfillmentTime;

  const fulfillmentSlots =
    reservation_mode === 'delivery'
      ? deliverySlots
      : pickupOptions.pickupMode === 'pickup'
      ? pickupSlots
      : curbsideSlots;

  const selectedSlot =
    fulfillmentTime &&
    fulfillmentSlots.find(
      (slot) =>
        slot.from === parseInt(fulfillmentTime.split('>')[0]) &&
        slot.to === parseInt(fulfillmentTime.split('>')[1])
    );

  const fulfillmentTimeName = selectedSlot ? selectedSlot?.display_name : '';

  return {
    isValid: !!selectedSlot,
    displayName: fulfillmentTimeName,
  };
};
