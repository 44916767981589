import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutInfo } from '../util/hooks/useCheckoutInfo';
import { useCheckoutOptionsIsValid } from '../util/hooks/useCheckoutOptionsIsValid';
import { getDefaultExpanded } from './getDefaultExpanded';

export type AccordionItems = 'options' | 'info' | 'payments';

interface CheckoutAccordionContextValue {
  expandableItems: AccordionItems[];
  expanded: AccordionItems | null;
  setExpanded: Dispatch<SetStateAction<AccordionItems | null>>;
}

const CheckoutAccordionContext = createContext<CheckoutAccordionContextValue>(
  {} as CheckoutAccordionContextValue
);

export const CheckoutAccordionProvider = ({ children }: PropsWithChildren) => {
  const {
    appInfo: { bloomUserExperience },
  } = useCheckout();
  const { state } = useLocation();

  // If a user signs in during checkout, we'll have stored some pre-sign in info in the location's state.
  const justSignedIn = Boolean(
    state && Object.keys(state).some((key) => key.includes('preAuth'))
  );

  const { isValid: infoValid } = useCheckoutInfo();

  const optionsValid = useCheckoutOptionsIsValid();

  const expandableItems: AccordionItems[] = useMemo(() => {
    if (bloomUserExperience) {
      return [
        'info',
        ...(infoValid ? ['options' as const] : []),
        ...(optionsValid && infoValid ? ['payments' as const] : []),
      ];
    }

    return [
      'options',
      ...(optionsValid ? ['info' as const] : []),
      ...(optionsValid && infoValid ? ['payments' as const] : []),
    ];
  }, [bloomUserExperience, optionsValid, infoValid]);

  const [expanded, setExpanded] = useState<AccordionItems | null>(
    justSignedIn
      ? getDefaultExpanded({
          bloomUserExperience,
          infoValid,
          optionsValid: !!optionsValid,
        })
      : bloomUserExperience
      ? 'info'
      : 'options'
  );

  const accordionContextValues = useMemo(
    () => ({
      expandableItems,
      expanded,
      setExpanded,
    }),
    [expandableItems, expanded]
  );

  return (
    <CheckoutAccordionContext.Provider value={accordionContextValues}>
      {children}
    </CheckoutAccordionContext.Provider>
  );
};

export const useCheckoutAccordion = () => {
  const context = useContext(CheckoutAccordionContext);
  if (context === undefined) {
    throw new Error(
      'useCheckoutAccordion must be used within the CheckoutAccordionContext'
    );
  }

  return context;
};
