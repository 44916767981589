import type { Card, CardDetails } from '@square/web-payments-sdk-types';
import type {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from 'react';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import { useCheckout } from '@jane/shared-ecomm/providers';

import { usePaymentIsValid } from '../util/hooks/usePaymentIsValid';
import type { PaymentOptions } from '../util/hooks/usePaymentOptions';
import {
  PaymentMethods,
  usePaymentOptions,
} from '../util/hooks/usePaymentOptions';

export interface CanPayIntent {
  intent_id: string;
  response: string;
  signature: string;
}

interface Prepayments {
  aeropayBankId: string;
  aeropayPreauthorizationId: string;
  canPayIntent?: CanPayIntent;
  monerisTicket: string;
  squareCardDetails?: CardDetails;
  squareToken: string;
  strongholdSourceId: string;
}

export interface CheckoutPaymentsContextValue {
  aeropayBankId: string;
  aeropayPreauthorizationId: string;
  canPayIntent?: CanPayIntent;
  monerisTicket: string;
  selectPaymentOption: (option: PaymentOptions | null) => void;
  selected: PaymentOptions | null;
  setVerified: Dispatch<SetStateAction<boolean>>;
  squareCard: MutableRefObject<Card | undefined>;
  squareCardDetails?: CardDetails;
  squareToken: string;
  strongholdSourceId: string;
  updatePrepayments: (value: Partial<Prepayments>) => void;
  verified: boolean;
}

const CheckoutPaymentsContext = createContext<CheckoutPaymentsContextValue>(
  {} as CheckoutPaymentsContextValue
);
/**
 * This provider tracks all the possible payment methods, which one is selected,
 * and any data returned to us from a payment provider's API/widget.
 * Any payment related info that is filled out using our inputs
 * is stored in the checkout form provider.
 */
export const CheckoutPaymentsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    customer: { id },
    dispatches: { updateTip },
    guestCheckout: { preAuthSelection },
  } = useCheckout();
  const { prepaymentOptions, paymentOptions } = usePaymentOptions();
  const { data: aeropayUser } = useAeropayUser(
    {
      janeUserId: id || null,
    },
    { enabled: prepaymentOptions.includes(PaymentMethods.janePay) }
  );

  const [prepayments, setPrepayments] = useState<Prepayments>({
    aeropayBankId: '',
    aeropayPreauthorizationId: '',
    canPayIntent: undefined,
    strongholdSourceId: '',
    monerisTicket: '',
    squareToken: '',
    squareCardDetails: undefined,
  });

  const allOptions = [...prepaymentOptions, ...paymentOptions];
  /**
   * If there's only one payment method, pre-select that one,
   * if the user was forced to login after selecting a method, pre-select that one,
   * if Jane Pay is an option, select Jane Pay,
   * otherwise, don't preselect any payment method.
   */
  const getInitialSelection = () => {
    if (allOptions.length === 1) return allOptions[0];

    if (prepaymentOptions.includes(preAuthSelection as PrepaymentProviders))
      return preAuthSelection as PrepaymentProviders;

    if (prepaymentOptions.includes(PaymentMethods.janePay))
      return PaymentMethods.janePay;

    return null;
  };
  const [selected, setSelected] = useState<PaymentOptions | null>(
    getInitialSelection()
  );
  const [verified, setVerified] = useState(false);

  const squareCard = useRef(undefined);

  const handleUpdatePrepayments = (value: Partial<Prepayments>) => {
    setPrepayments((prevValue) => ({ ...prevValue, ...value }));
  };

  const handleSelectPayment = (option: PaymentOptions | null) => {
    updateTip(0);
    setSelected(option);
  };

  usePaymentIsValid({
    ...prepayments,
    selected,
    verified,
    setVerified,
  });

  useEffect(() => {
    if (aeropayUser?.bank_accounts.length) {
      setPrepayments((prevValue) => ({
        ...prevValue,
        aeropayBankId: aeropayUser.bank_accounts[0].bank_account_id,
      }));
    }
  }, [aeropayUser]);

  const memoizedPaymentsContextValues = useMemo(
    () => ({
      ...prepayments,
      selected,
      selectPaymentOption: handleSelectPayment,
      setVerified,
      squareCard,
      updatePrepayments: handleUpdatePrepayments,
      verified,
    }),
    [prepayments, selected, verified]
  );

  return (
    <CheckoutPaymentsContext.Provider value={memoizedPaymentsContextValues}>
      {children}
    </CheckoutPaymentsContext.Provider>
  );
};

export const useCheckoutPayments = () => {
  const context = useContext(CheckoutPaymentsContext);
  if (context === undefined) {
    throw new Error(
      'useCheckoutPaymentsContext must be used within the CheckoutPaymentsContext'
    );
  }

  return context;
};
