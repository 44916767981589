import type { Id } from '@jane/shared/models';

import { EventNames } from '../eventNames';
import type {
  SearchedDispensaries,
  SearchedMenuProducts,
  SearchedProducts,
  SharedSearchProperties,
} from '../eventNames';

export const getMenuSearchEventDetails = ({
  store,
  view,
}: {
  store: { id: Id; name: string };
  view: 'grid' | 'list';
}): Omit<SearchedMenuProducts, keyof SharedSearchProperties> => ({
  event: EventNames.SearchedMenuProducts,
  storeId: store.id.toString(),
  storeName: store.name,
  view,
});

export const getProductSearchEventDetails = ({
  cityState,
}: {
  cityState?: string;
}): Omit<SearchedProducts, keyof SharedSearchProperties> => ({
  event: EventNames.SearchedProducts,
  cityState: cityState || undefined,
});

export const getDispensarySearchEventDetails = ({
  cityState,
  indexName,
}: {
  cityState?: string;
  indexName: string;
}): Omit<SearchedDispensaries, keyof SharedSearchProperties> & {
  indexName: string;
} => ({
  event: EventNames.SearchedDispensaries,
  cityState: cityState || undefined,
  indexName,
});
