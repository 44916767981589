import { useQueryClient } from '@tanstack/react-query';
import type { InfiniteData } from '@tanstack/react-query';
import { createContext, useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';

import {
  storeSpecialsQueryKeys,
  useGetStoreSpecials,
  useGetStoreSpecialsPage,
} from '@jane/shared-ecomm/data-access';
import type { StoreSpecial } from '@jane/shared/types';

import { useMenu } from '../menuProvider';

export interface SpecialsContextProps {
  fetchNextSpecialsPage: () => void;
  hasNextSpecialsPage: boolean;
  isLoadingStore?: boolean;
  loadingSpecials?: boolean;
  loadingSpecialsCards?: boolean;
  refetchSpecialsPage: () => void;
  specialIds?: StoreSpecial['id'][];
  specials?: StoreSpecial[];
  specialsCards?: StoreSpecial[];
}

export const SpecialsContext = createContext<SpecialsContextProps>(
  {} as SpecialsContextProps
);

type SpecialsProviderProps = PropsWithChildren<{ fetchSpecialsRow?: boolean }>;

export const SPECIALS_PAGE_LIMIT = 16;

export const SpecialsProvider = ({
  children,
  fetchSpecialsRow = true,
}: SpecialsProviderProps) => {
  const queryClient = useQueryClient();
  const { loading, store } = useMenu();
  const storeId = store?.id;

  const { data: allSpecials = [], isLoading: loadingSpecials } =
    useGetStoreSpecials(store?.id);

  const specialIds = useMemo(
    () => allSpecials.map((special) => special.id),
    [allSpecials]
  );

  const specialsRowEnabled =
    store?.specials_row_configs?.enabled && fetchSpecialsRow;

  // This doesn't call a "refetch" but it resets the results to the first page
  const handleRefetchSpecialsPage = () => {
    queryClient.setQueryData<InfiniteData<unknown>>(
      storeSpecialsQueryKeys.storeSpecialsPage(storeId, SPECIALS_PAGE_LIMIT),
      (data) => {
        if (!data) return data;
        return {
          pages: data.pages.slice(0, 1),
          pageParams: data.pageParams.slice(0, 1),
        };
      }
    );
  };

  const {
    data: specialsPage,
    isFetching: loadingSpecialsCards,
    fetchNextPage: fetchNextSpecialsPage,
    hasNextPage: hasNextSpecialsPage,
  } = useGetStoreSpecialsPage(
    storeId,
    {
      enabled: specialsRowEnabled,
    },
    SPECIALS_PAGE_LIMIT
  );

  const specialsCards = useMemo(
    () => specialsPage?.pages?.flatMap((page) => page.specials) || [],
    [specialsPage]
  );

  const contextValue = useMemo(
    () => ({
      fetchNextSpecialsPage,
      hasNextSpecialsPage: !!hasNextSpecialsPage,
      isLoadingStore: loading,
      loadingSpecials,
      loadingSpecialsCards,
      refetchSpecialsPage: handleRefetchSpecialsPage,
      specialIds,
      specials: allSpecials,
      specialsCards,
    }),
    [
      allSpecials,
      fetchNextSpecialsPage,
      hasNextSpecialsPage,
      loading,
      loadingSpecials,
      loadingSpecialsCards,
      specialIds,
      specialsCards,
    ]
  );

  return (
    <SpecialsContext.Provider value={contextValue}>
      {children}
    </SpecialsContext.Provider>
  );
};

export const useSpecials = () => {
  const context = useContext(SpecialsContext);

  if (context === undefined) {
    throw new Error('useSpecials must be used within a SpecialsProvider');
  }

  return context;
};
