import { useCheckout } from '@jane/shared-ecomm/providers';
import { useJaneUser } from '@jane/shared/data-access';
import {
  Box,
  Button,
  ButtonToggle,
  Flex,
  Typography,
  useDesktopMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';

import { useCheckoutAccordion } from '../CheckoutAccordion/CheckoutAccordionProvider';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';

type Modes = 'delivery' | 'pickup';

interface CheckoutHeaderProps {
  handleShowLogin: () => void;
}

export const CheckoutToggle = ({ handleShowLogin }: CheckoutHeaderProps) => {
  const { data: janeUserData } = useJaneUser();

  const isMobile = useTabletMediaQuery({ width: 'max' });
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });
  const {
    appInfo: { bloomUserExperience, disableAuthFeatures },
    cart,
    dispatches: { changeReservationMode },
    store: { delivery, pickup },
  } = useCheckout();
  const { setExpanded } = useCheckoutAccordion();
  const reservationMode = cart?.reservation_mode;

  const { deliveryLabel, curbsideLabel, pickupLabel } =
    useReservationModeLabels();

  const {
    deliverySlots,
    hasFetchedDelivery,
    isAcceptingCurbside,
    isAcceptingDelivery,
    isAcceptingPickup,
  } = useFulfillmentSchedule();

  const signedIn = Boolean(janeUserData?.user?.id);
  const showLogin = !signedIn && isMobile && !disableAuthFeatures;

  const availableReservationModes = bloomUserExperience
    ? [...(pickup ? ['pickup'] : []), ...(delivery ? ['delivery'] : [])]
    : [...(delivery ? ['delivery'] : []), ...(pickup ? ['pickup'] : [])];

  const getLabel = (mode: Modes) => {
    if (mode === 'delivery') return deliveryLabel;
    return isAcceptingPickup ? pickupLabel : curbsideLabel;
  };

  const handleToggle = (mode: Modes) => {
    changeReservationMode(mode);
    setExpanded('options');
  };

  return (
    <Box
      background="grays-white"
      mb={isDesktop ? 24 : 16}
      py={isDesktop ? 0 : 16}
      px={isDesktop ? 0 : 24}
      width="100%"
    >
      {showLogin && (
        <Button
          mb={16}
          full
          label="Sign in"
          variant="secondary"
          onClick={handleShowLogin}
        />
      )}
      {availableReservationModes.length > 1 && (
        <ButtonToggle
          variant="tertiary"
          value={reservationMode}
          onChange={(mode) => handleToggle(mode as Modes)}
        >
          {availableReservationModes.map((mode) => (
            <ButtonToggle.Button
              value={mode}
              key={mode}
              disabled={
                mode === 'pickup'
                  ? !isAcceptingPickup && !isAcceptingCurbside
                  : !isAcceptingDelivery ||
                    (!deliverySlots?.length && !!hasFetchedDelivery)
              }
            >
              <Typography as="span" variant="body-bold">
                {getLabel(mode as Modes)}
              </Typography>
            </ButtonToggle.Button>
          ))}
        </ButtonToggle>
      )}

      {availableReservationModes.length === 1 && (
        <Flex width="100%" justifyContent="center" mt={24}>
          <Typography variant="body-bold">
            {getLabel(availableReservationModes[0] as Modes)}
          </Typography>
        </Flex>
      )}
    </Box>
  );
};
