import { AddIcon, Flex, useMobileMediaQuery } from '@jane/shared/reefer';

export const BundleAddCard = ({ isActive }: { isActive: boolean }) => {
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex
      width={isMobile ? 98 : 120}
      height={isMobile ? 98 : 120}
      alignItems="center"
      justifyContent="center"
      borderRadius="sm"
      position="relative"
      border={isActive ? 'purple' : 'grays-light'}
      data-testid="add-qualified-product"
    >
      <AddIcon color={isActive ? 'purple' : 'grays-mid'} />
    </Flex>
  );
};
