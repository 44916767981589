import { useState } from 'react';

import { useMenu } from '@jane/shared-ecomm/providers';
import { useJaneUserId, useUnreviewedProducts } from '@jane/shared/data-access';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  Grid,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { UnreviewedProduct } from '@jane/shared/types';

import { MenuBanner } from '../MenuBanner';
import {
  EllipsisLink,
  SlidingText,
  TextWrapper,
} from './menuReviewBanner.styles';

export const MenuReviewBanner = () => {
  const [index, setIndex] = useState(0);
  const isMobile = useMobileMediaQuery({});
  const { paths, store } = useMenu();
  const userId = useJaneUserId();

  const { data: products = [], isFetching: isLoading } = useUnreviewedProducts({
    userId,
    storeId: Number(store.id),
  });

  const handlePreviousProduct = () => {
    setIndex(
      (prevValue) => (prevValue - 1 + products.length) % products.length
    );
  };

  const handleNextProduct = () => {
    setIndex((prevValue) => (prevValue + 1) % products.length);
  };

  if (!isLoading && !products?.length) return null;

  return (
    <MenuBanner isLoading={isLoading}>
      <Grid.Container spacing={isMobile ? 8 : 16}>
        <Grid.Item xs={12} md={6} lg={8}>
          <Flex alignItems="center">
            <Typography>
              You made a purchase at this store. Please help the community by
              reviewing it.
            </Typography>
          </Flex>
        </Grid.Item>

        <Grid.Item xs={12} md={6} lg={4}>
          <Flex alignItems="center" height="100%">
            <TextWrapper alignItems="center">
              {products.map((product: UnreviewedProduct, i: number) => (
                <SlidingText
                  key={`${product.id}` + i}
                  currentIndex={index}
                  minWidth="100%"
                  textAlign="left"
                >
                  <EllipsisLink
                    to={paths.newProductReview({
                      id: product.id,
                      name: product.name,
                    })}
                  >
                    {product.name}
                  </EllipsisLink>
                </SlidingText>
              ))}
            </TextWrapper>

            {products.length > 1 && (
              <Flex ml="auto">
                <ChevronLeftIcon
                  ml={16}
                  altText="previous-product"
                  onClick={handlePreviousProduct}
                  color="text-main"
                />

                <ChevronRightIcon
                  ml={16}
                  altText="next-product"
                  onClick={handleNextProduct}
                  color="text-main"
                />
              </Flex>
            )}
          </Flex>
        </Grid.Item>
      </Grid.Container>
    </MenuBanner>
  );
};
