import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Grid, Link, Typography } from '@jane/shared/reefer';

import { useCheckoutAccordion } from '../CheckoutAccordion/CheckoutAccordionProvider';
import { StyledGridContainer } from '../checkout.styles';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useSelectedFulfillmentTime } from '../util/hooks/useSelectedFulfillmentTime';
import FormattedAddress from './FormattedAddress';

export const DeliverySummary = () => {
  const { displayName } = useSelectedFulfillmentTime();
  const { deliveryLabel } = useReservationModeLabels();
  const { deliveryAddress } = useCheckout();

  const { control } = useFormContext();
  const { setExpanded } = useCheckoutAccordion();

  const message = useWatch({ control, name: 'deliveryOptions.message' });

  return (
    <>
      <StyledGridContainer>
        <Grid.Item xs={12} lg={6}>
          {!!deliveryAddress?.street && (
            <FormattedAddress address={deliveryAddress} />
          )}
          {!deliveryAddress?.street && (
            <Link onClick={() => setExpanded('options')}>
              Add delivery address
            </Link>
          )}
        </Grid.Item>
        <Grid.Item xs={12} lg={6}>
          <Typography>
            {displayName ? (
              displayName
            ) : (
              <Link onClick={() => setExpanded('options')}>
                Add delivery time
              </Link>
            )}
          </Typography>
        </Grid.Item>
      </StyledGridContainer>

      {!!message && (
        <Typography mt={24}>
          {deliveryLabel} instructions: {message}
        </Typography>
      )}

      {!message && (
        <Link mt={24} onClick={() => setExpanded('options')}>
          Add delivery instructions
        </Link>
      )}
    </>
  );
};
