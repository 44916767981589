import { getJwt } from '@jane/shared/auth';
import { STALE_TIMES } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import type { BannerMessageSetting, Store, StoreSeo } from '@jane/shared/types';

import { janeApi } from '../api';
import { createUseQueryHook } from '../utils';
import { queryKeys, urls } from './stores.util';

const enableWithStoreId = (id: Id) => !!id;

const fetchStore = async (id: Id) => {
  return await janeApi
    .get<{ store: Store }>(urls.store(id), getJwt())
    .then((result) => result.store);
};

export const useStore = createUseQueryHook<Id, Store>(
  fetchStore,
  queryKeys.store,
  enableWithStoreId
);

const fetchStoreSeo = async (id: Id) => {
  return await janeApi.get<StoreSeo>(urls.seo(id), getJwt());
};

export const useStoreSeo = createUseQueryHook<Id, StoreSeo>(
  fetchStoreSeo,
  queryKeys.seo,
  enableWithStoreId
);

const fetchStoreCommsBanner = async (id: Id) => {
  return await janeApi.get<BannerMessageSetting>(
    urls.commsBanner(id),
    getJwt()
  );
};

export const useStoreCommsBanner = createUseQueryHook<Id, BannerMessageSetting>(
  fetchStoreCommsBanner,
  queryKeys.commsBanner,
  enableWithStoreId,
  { staleTime: STALE_TIMES.short }
);
