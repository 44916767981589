import type { UseStrongholdCustomerProps } from './stronghold.queries';

export const urls = {
  strongholdCustomer: '/users/stronghold_customers',
};

const queryKeyBase = ['stronghold'];

export const queryKeys = {
  strongholdCustomer: ({
    storeId,
    firstName,
    lastName,
    phone,
  }: UseStrongholdCustomerProps) => [
    ...queryKeyBase,
    'customer',
    storeId,
    firstName,
    lastName,
    phone,
  ],
};
