import { Typography } from '@jane/shared/reefer';

export const AgreementLabel = ({ label = '', required = false }) => {
  return (
    <Typography>
      {required && (
        <Typography color="error" as="span">
          *
        </Typography>
      )}
      {label}
    </Typography>
  );
};
