import type {
  PaytenderCheckoutRequest,
  PaytenderOrderReference,
} from './paytender.types';

export const urls = {
  authorize: '/paytender/checkout/authorize',
  cancel: (orderReference: string) =>
    `/paytender/checkout/cancel/${orderReference}`,
  status: (orderReference: string) =>
    `/paytender/checkout/status/${orderReference}`,
  reservationStatus: (orderReference: string) =>
    ` /paytender/checkout/reservation_status/${orderReference}`,
};

const queryKeyBase = ['paytender'];

export const queryKeys = {
  authorize: (payload: PaytenderCheckoutRequest) => [
    ...queryKeyBase,
    'authorize',
    payload,
  ],
  status: ({ orderReference }: PaytenderOrderReference) => [
    ...queryKeyBase,
    'status',
    orderReference,
  ],
  reservationStatus: ({ orderReference }: PaytenderOrderReference) => [
    ...queryKeyBase,
    'reservationStatus',
    orderReference,
  ],
};
