import type { ReactElement } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Banner, Link } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { AlertsRow } from '../checkout.styles';
import { useCartIsValid } from '../util/hooks/useCartIsValid';
import { useCheckoutUnload } from '../util/hooks/useCheckoutUnload';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';
import { useHandleCheckoutErrors } from '../util/hooks/useHandleCheckoutErrors';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useSelectedFulfillmentTime } from '../util/hooks/useSelectedFulfillmentTime';
import { AeropayError } from './AeropayError';

export const CheckoutAlerts = () => {
  useCheckoutUnload();
  useHandleCheckoutErrors();

  const {
    appInfo: { checkoutError },
    cart: { reservation_mode },
    dispatches: { openCart, dismissCheckoutError },
    store: { name },
  } = useCheckout();

  const { cartLimitValid, minimumIsMet, requiredMinimum } = useCartIsValid();
  const {
    hasFetchedDelivery,
    curbsideSlots,
    deliverySlots,
    isAcceptingCurbside,
    isAcceptingDelivery,
    isAcceptingPickup,
    pickupSlots,
  } = useFulfillmentSchedule();
  const { reservationModeLabel: reservationModeLabelLowercase } =
    useReservationModeLabels(true);
  const { reservationModeLabel } = useReservationModeLabels();
  const { isValid: slotValid } = useSelectedFulfillmentTime();

  const noDelivery =
    (hasFetchedDelivery && !deliverySlots.length) || !isAcceptingDelivery;
  const noPickup =
    (!pickupSlots.length && !curbsideSlots.length) ||
    (!isAcceptingCurbside && !isAcceptingPickup);

  const unavailableSlot =
    !slotValid && (reservation_mode !== 'delivery' || hasFetchedDelivery);
  const unavailableMode =
    (reservation_mode === 'delivery' && noDelivery) ||
    (reservation_mode === 'pickup' && noPickup);

  const getLabel = (): string | ReactElement => {
    if (!minimumIsMet)
      return `${name} requires a minimum ${reservationModeLabelLowercase} purchase of ${formatCurrency(
        requiredMinimum
      )}`;

    if (!cartLimitValid)
      return `Your bag exceeds the cart size limit for ${name}.`;

    if (unavailableMode)
      return `${reservationModeLabel} is currently unavailable`;

    if (unavailableSlot) return 'Selected time slot is no longer available';

    if (checkoutError) {
      // Aeropay (JanePay) errors start with "AP" followed by 3-4 numbers
      const aeropayRegex = /^Error (AP\d{3,4}): (.+)$/;
      const aeropayError = checkoutError.match(aeropayRegex);
      if (aeropayError) {
        return <AeropayError code={aeropayError[1]} />;
      } else {
        return checkoutError;
      }
    }
    return 'There was an error processing your request.';
  };

  const getActions = () => {
    if (!minimumIsMet || !cartLimitValid)
      return (
        <Link ml="auto" color="error-dark" onClick={openCart}>
          Update Bag
        </Link>
      );

    return null;
  };

  const hasError =
    !cartLimitValid ||
    !minimumIsMet ||
    !!checkoutError ||
    unavailableMode ||
    unavailableSlot;

  if (!hasError) return null;

  return (
    <AlertsRow>
      <Banner
        actions={getActions()}
        label={getLabel()}
        variant="error"
        {...(checkoutError && { onDismiss: dismissCheckoutError })}
        full
        data-testid="checkout-alerts"
      />
    </AlertsRow>
  );
};
