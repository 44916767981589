import { useCallback } from 'react';

import { useCheckout, useGoogleRecaptcha } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { useMfa } from '@jane/shared/providers';
import { trackError } from '@jane/shared/util';

import { useCheckoutPayments } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { useBuildCheckoutRequest } from './useBuildCheckoutRequest';
import { useHasNewPhone } from './useHasNewPhone';
import { useMaybeUpdateUser } from './useMaybeUpdateUser';
import type { PaymentOptions } from './usePaymentOptions';
import { PaymentMethods } from './usePaymentOptions';

//TODO: pass values instead of grab them?
export const useSubmitCheckout = () => {
  const maybeUpdateUser = useMaybeUpdateUser({ checkingOut: true });
  const { hasNewPhone, newPhone } = useHasNewPhone();
  const { getValue, reset, execute } = useGoogleRecaptcha();
  const { launch, requiresMfa } = useMfa();
  const { buildRequestValues } = useBuildCheckoutRequest();

  const {
    customer: { authenticated },
    dispatches: { checkout, checkoutCancelled, checkoutError, checkoutStarted },
    store: { canpay_v2remotepay_enabled },
  } = useCheckout();
  const { selected } = useCheckoutPayments();

  const getTrackingEvent = useCallback(() => {
    switch (selected) {
      case PaymentMethods.janePay:
        return EventNames.ClickedPayWithJanePay;
      case PaymentMethods.stronghold:
        return EventNames.ClickedPayWithStronghold;
      default:
        return null;
    }
  }, [selected]);

  // Submitting checkout operates as three function. When submitted, the first function
  // checks if recaptcha validation is required. Upon successful recaptcha (or if not required),
  // the next function is called that will check if MFA is required. Upon mfa success (or if not required),
  // the actual checkout submission function will be called.
  const handleCheckout = useCallback(() => {
    maybeUpdateUser();
    const recaptchaToken = getValue();
    reset();
    const requestValues = buildRequestValues();

    checkout({
      ...requestValues,
      recaptcha_token: recaptchaToken,
    });
  }, [buildRequestValues, checkout, reset]);

  const handleMfa = useCallback(() => {
    const shouldMfa =
      hasNewPhone ||
      (authenticated &&
        selected &&
        requiresMfa() &&
        (
          [
            PaymentMethods.aeropay,
            PaymentMethods.janePay,
            PaymentMethods.stronghold,
          ] as PaymentOptions[]
        ).includes(selected));

    if (shouldMfa) {
      launch({
        onCancel: () => checkoutCancelled(),
        onSuccess: handleCheckout,
        newPhone: hasNewPhone ? newPhone : undefined,
      });
      return;
    } else {
      handleCheckout();
    }
  }, [buildRequestValues, handleCheckout, launch, requiresMfa, selected]);

  const handleRecaptcha = useCallback(() => {
    checkoutStarted();
    const event = getTrackingEvent();
    event && track({ event });

    const skipRecaptcha =
      (selected &&
        (
          [
            PaymentMethods.aeropay,
            PaymentMethods.janePay,
            PaymentMethods.stronghold,
          ] as PaymentOptions[]
        ).includes(selected)) ||
      (selected === PaymentMethods.canpay && canpay_v2remotepay_enabled);

    const recaptchaToken = getValue();

    if (!skipRecaptcha && !recaptchaToken) {
      try {
        execute({
          onCancel: () => checkoutCancelled(),
          onError: () => {
            checkoutError('There was an error launching the ReCAPTCHA service');
            checkoutCancelled();
          },
          onSuccess: handleMfa,
        });
      } catch (e) {
        trackError('Google ReCAPTCHA error', { error: e });
        checkoutCancelled();
      }
    } else {
      handleMfa();
    }
  }, [
    canpay_v2remotepay_enabled,
    execute,
    getTrackingEvent,
    getValue,
    handleMfa,
  ]);

  return {
    submit: handleRecaptcha,
  };
};
