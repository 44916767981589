import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';

export const useHasNewPhone = () => {
  const { control } = useFormContext();
  const phone = useWatch({
    control,
    name: 'customerInformation.phone',
  });
  const {
    customer: { authenticated, phone: originalPhone },
  } = useCheckout();

  return {
    hasNewPhone: authenticated && phone !== originalPhone,
    newPhone: phone,
  };
};
