import { connect } from 'react-redux';
import type { Store } from 'redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';

import type { Store as StoreStore } from '@jane/shared/models';
import type { DeepPartial } from '@jane/shared/types';

import { commonReducers } from '../common/redux';
import { addGoogleAnalyticsInstance } from '../common/redux/application';
import { identifyCustomerUserAtFirstOpportunity } from '../lib/identifyAtFirstOpportunity';
import { setRequestDispatch } from '../lib/request';
import { setAppMode } from '../lib/routes';
import type { CheckActions } from '../redux-util';
import type { Connect } from '../redux-util/types';
import { setNotificationsDispatch } from '../services/notifications';
import { addressAutocompleteReducer } from './redux/addressAutocomplete';
import { brandReducer } from './redux/brand';
import { bundlePossibilitiesReducer } from './redux/bundlePossibilities';
import { cartReducer, persistCartToLocalStorage } from './redux/cart';
import { customerReducer } from './redux/customer';
import { embeddedAppReducer } from './redux/embeddedApp';
import { headlessAppReducer } from './redux/headlessApp';
import { identificationReducer } from './redux/identification';
import { initialQueryReducer } from './redux/initialQuery';
import { materializedMenuProductReducer } from './redux/materializedMenuProduct';
import { operatorEmbeddedReducer } from './redux/operatorEmbedded';
import { posCartReducer } from './redux/posCart';
import { productReducer } from './redux/product';
import { reviewsReducer } from './redux/reviews';
import { searchReducer } from './redux/search';
import { selectedStoreSortReducer } from './redux/selectedStoreSort';
import { storeReducer } from './redux/store';
import { storesForProductReducer } from './redux/storesForProduct';
import type { CustomerAction, CustomerState } from './redux/types';
import { usersReducer } from './redux/users';

const middleware = [thunk].filter(Boolean);

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureCustomerStore = (
  preloadedState: DeepPartial<CustomerState> = {}
): Store<CustomerState, CustomerAction> => {
  const store = createStore<
    CustomerState,
    CheckActions<CustomerAction>,
    {},
    {}
  >(
    combineReducers<CustomerState>({
      ...commonReducers,
      addressAutocomplete: addressAutocompleteReducer,
      brand: brandReducer,
      bundlePossibilities: bundlePossibilitiesReducer,
      cart: cartReducer,
      customer: customerReducer,
      embeddedApp: embeddedAppReducer,
      headlessApp: headlessAppReducer,
      identification: identificationReducer,
      initialQuery: initialQueryReducer,
      materializedMenuProduct: materializedMenuProductReducer,
      operatorEmbedded: operatorEmbeddedReducer,
      posCart: posCartReducer,
      product: productReducer,
      reviews: reviewsReducer,
      search: searchReducer,
      selectedStoreSort: selectedStoreSortReducer,
      store: storeReducer,
      storesForProduct: storesForProductReducer,
      users: usersReducer,
    }),
    preloadedState as CustomerState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  setAppModeForRoutes(store);
  setRequestDispatch(store.dispatch);
  setNotificationsDispatch(store.dispatch);
  identifyCustomerUserAtFirstOpportunity(store);
  persistCartToLocalStorage(store);

  const anyWindow = window as any;
  if (anyWindow['Cypress']) {
    anyWindow['store'] = store;
  }

  return store;
};

const setAppModeForRoutes = (
  reduxStore: ReturnType<typeof configureCustomerStore>
) => {
  reduxStore.subscribe(() => {
    const { operatorEmbedded, embeddedApp, store, application } =
      reduxStore.getState();
    const { appMode, partnerId, partnerName } = embeddedApp;
    const { operatorId } = operatorEmbedded;
    const storeStore = store.store as StoreStore;
    let analyticsIntegration;
    if (storeStore.analytics_integration) {
      analyticsIntegration = { ...storeStore.analytics_integration };
    }

    switch (appMode) {
      case 'default':
        setAppMode({ type: appMode });
        break;
      case 'embedded':
      case 'whiteLabel':
      case 'brandEmbed':
      case 'framelessEmbed':
        if (partnerId != null && partnerName != null) {
          setAppMode({ partnerId, partnerName, type: appMode });
        }
        break;
      case 'operatorEmbed':
        if (operatorId != null && partnerId != null) {
          setAppMode({ operatorId, storeId: partnerId, type: appMode });
        }
        break;
      case 'headless':
        setAppMode({ type: appMode });
        break;
    }

    if (
      analyticsIntegration &&
      analyticsIntegration.provider_id &&
      analyticsIntegration.enabled &&
      application.googleAnalyticsInstance !==
        analyticsIntegration.provider_id &&
      !['brandEmbed', 'default'].includes(appMode) // we only use GA EC in app modes other than brandEmbed and default
    ) {
      reduxStore.dispatch(
        addGoogleAnalyticsInstance({
          trackingId: analyticsIntegration.provider_id,
        })
      );
    }
  });
};

export type CustomerThunkAction<
  Result = void,
  ExtraArgument = void
> = ThunkAction<Result, CustomerState, ExtraArgument, CustomerAction>;

export type CustomerThunkActionCreator<
  Args = void,
  Result = void,
  ExtraArgument = void
> = (args: Args) => CustomerThunkAction<Result, ExtraArgument>;

export const customerConnect: Connect<CustomerState> = connect;
