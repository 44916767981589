import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { useDetectIdle } from '@jane/shared/hooks';
import { trackError } from '@jane/shared/util';

import { useBuildAbandonedCheckoutPayload } from './useBuildAbandonedCheckoutPayload';

const IDLE_TIME_MS = 1000 * 60 * 5; // 5 minutes

export const useCheckoutUnload = () => {
  const location = useLocation();
  const { buildPayload } = useBuildAbandonedCheckoutPayload();

  const handleUnload = () => {
    try {
      const payload = buildPayload();
      track({
        event: EventNames.AbandonedCheckoutPage,
        ...payload,
      });
    } catch (e) {
      trackError(new Error('AbandonedCheckoutPage track event error'));
    }
  };

  useDetectIdle(IDLE_TIME_MS, handleUnload);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
      if (
        ((location.state && location.state?.action === 'POP') ||
          (location.state && location.state?.action === 'REPLACE')) &&
        !location.pathname.includes('checkout')
      ) {
        handleUnload();
      }
    };
  }, [location, handleUnload]);
};
