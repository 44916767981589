import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useCheckout, useUserPreferences } from '@jane/shared-ecomm/providers';
import type {
  CheckoutNewPaymentMethods,
  CheckoutNewRequest,
} from '@jane/shared-ecomm/types';
import { formatTime, getAnonymousUserId } from '@jane/shared/util';

import type { CanPayIntent } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { useCheckoutPayments } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { useCheckoutDocs } from './useCheckoutDocs';
import { PaymentMethods } from './usePaymentOptions';
import { useUserConsents } from './useUserConsents';

export const useBuildCheckoutRequest = () => {
  const location = useLocation();
  const { userLocation, userPreferences } = useUserPreferences();

  const { checkoutConsents } = useUserConsents();
  const {
    requiredDocs: { governmentId, medicalPhoto, medicalNumber },
  } = useCheckoutDocs();

  const { getValues } = useFormContext();

  const {
    appInfo: { appMode, headlessCheckoutPartnerId, janeDeviceId },
    cart,
    customer,
    deliveryAddress,
    checkoutRequirements,
    tags,
    userGroupSpecialId,
  } = useCheckout();
  const {
    aeropayBankId,
    aeropayPreauthorizationId,
    canPayIntent,
    monerisTicket,
    selected,
    squareToken,
    strongholdSourceId,
  } = useCheckoutPayments();

  const buildPaymentKeys = useCallback((): CheckoutNewPaymentMethods => {
    const { payfirma, canPayToken } = getValues('paymentOptions');

    switch (selected) {
      case PaymentMethods.janePay:
        return {
          payment_method: PaymentMethods.janePay,
          aeropay_bank_account_id: aeropayBankId,
        };
      case PaymentMethods.aeropay:
        return {
          payment_method: PaymentMethods.aeropay,
          aeropay_preauthorization_id: aeropayPreauthorizationId,
        };
      case PaymentMethods.payfirma:
        return {
          payment_method: PaymentMethods.payfirma,
          payfirma_card_cvv2: payfirma.cardCvv2,
          payfirma_card_expiry_month: payfirma.cardExpiry.slice(0, 2),
          payfirma_card_expiry_year: payfirma.cardExpiry.slice(2),
          payfirma_card_number: payfirma.cardNumber,
          payfirma_card_postal_code: payfirma.cardPostalCode,
        };
      case PaymentMethods.canpay:
        return {
          payment_method: PaymentMethods.canpay,
          canpay_token: canPayToken,
          canpay_v2remotepay_transaction: canPayIntent as CanPayIntent,
        };
      case PaymentMethods.moneris:
        return {
          payment_method: PaymentMethods.moneris,
          moneris_ticket: monerisTicket,
        };
      case PaymentMethods.square:
        return {
          payment_method: PaymentMethods.square,
          square_payment_token: squareToken,
        };
      case PaymentMethods.stronghold:
        return {
          payment_method: PaymentMethods.stronghold,
          stronghold_payment_source_id: strongholdSourceId,
        };
      default:
        return {
          payment_method: selected as string,
        };
    }
  }, [
    aeropayBankId,
    aeropayPreauthorizationId,
    canPayIntent,
    getValues,
    monerisTicket,
    selected,
    squareToken,
    strongholdSourceId,
  ]);

  const buildRequestValues = useCallback((): CheckoutNewRequest => {
    const [customerInformation, pickupOptions, deliveryOptions] = getValues([
      'customerInformation',
      'pickupOptions',
      'deliveryOptions',
    ]);

    const pickupMode = pickupOptions.pickupMode;

    const isGuestCheckout = location.pathname.includes('guest_checkout');

    const fulfillmentOptions =
      cart.reservation_mode === 'delivery' ? deliveryOptions : pickupOptions;

    const isCurbside =
      cart.reservation_mode === 'pickup' && pickupMode === 'curbside';

    const [delivery_started_at, delivery_finished_at] =
      fulfillmentOptions.fulfillmentTime?.split('>') || [];

    const formattedBirthDate = customerInformation.birthDate
      ? formatTime(customerInformation.birthDate, 'YYYY-MM-DD', 'MMDDYYYY')
      : '';

    const requestValues = {
      age_verification: {
        birth_date: formattedBirthDate,
        government_identification_url:
          !customer.authenticated && governmentId
            ? customerInformation.governmentIdentificationUrl
            : null,
      },
      ...(isGuestCheckout && { anonymous_user_id: getAnonymousUserId() }),
      current_url: window.location.href,
      customer: {
        email: customerInformation.email,
        external_user_id: customer.externalId,
        first_name: customerInformation.firstName,
        last_name: customerInformation.lastName,
        phone: customerInformation.phone,
      },
      ...(cart.reservation_mode === 'delivery' && {
        delivery_address: deliveryAddress,
        delivery_fee_amount: cart.delivery_fee_amount,
      }),
      delivery_finished_at: parseInt(delivery_finished_at),
      delivery_started_at: parseInt(delivery_started_at),
      headless_checkout_partner_id: headlessCheckoutPartnerId,
      is_curbside_pickup: isCurbside,
      j_device_id: janeDeviceId,
      medical_identification: {
        id_back_url:
          !customer.authenticated && medicalPhoto
            ? customerInformation.mmjIdBackUrl
            : null,
        id_front_url:
          !customer.authenticated && medicalPhoto
            ? customerInformation.mmjIdFrontUrl
            : null,
        id_number: medicalNumber ? customerInformation.mmjIdNumber : null,
      },
      message: fulfillmentOptions.message || '',
      promotions_opt_out:
        appMode === 'default' ? !fulfillmentOptions.promotionsOptIn : undefined,
      reservation_mode: cart.reservation_mode,
      tags: tags || cart.tags,
      tip_amount: parseFloat(cart.tip_amount) || 0,
      uuid: cart.uuid,
      user_consent: checkoutConsents || [],
      user_group_special_id: userGroupSpecialId,
      user_location: userLocation,
      user_preferences: userPreferences,
      ...buildPaymentKeys(),
    };

    return requestValues;
  }, [
    buildPaymentKeys,
    cart.reservation_mode,
    cart.tags,
    cart.tip_amount,
    cart.uuid,
    checkoutConsents,
    checkoutRequirements.data?.medical_documentation_requirement,
    customer.externalId,
    deliveryAddress,
    location.pathname,
    tags,
    userGroupSpecialId,
    userLocation,
    userPreferences,
  ]);

  return { buildRequestValues };
};
