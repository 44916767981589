export const getGridPosition = (
  index: number,
  numberOfColumns?: number
): { column: number | null; row: number | null } => {
  if (!numberOfColumns) {
    return { row: null, column: null };
  }

  const row = Math.floor(index / numberOfColumns);
  const column = index % numberOfColumns;
  return { row, column };
};
