import { useMemo } from 'react';

import { useSearchContext } from '@jane/search/providers';
import {
  MIN_REVIEWS_QUERY,
  buildGoldUserSegmentsFilter,
  composeFilters,
} from '@jane/search/util';
import { useMenu } from '@jane/shared-ecomm/providers';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';

export const useComposeMenuFilters = (
  filters: Array<string | null | undefined>
) => {
  const { staticFilters } = useMenu();
  const { searchState } = useSearchContext();
  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const userSegmentIds = useUserSegmentIds();

  const goldFilterValues = searchState.filters?.['has_brand_discount'] ?? [];
  const isFilteredByGold = goldFilterValues?.length > 0;
  const addUserSegmentsFilter = janeGoldSegmentation && isFilteredByGold;
  const ratingFilterValues =
    searchState.bucketFilters?.['aggregate_rating'] ?? [];
  const isFilteredByRating = ratingFilterValues?.length > 0;

  return useMemo(
    () =>
      composeFilters(
        staticFilters,
        isFilteredByRating ? MIN_REVIEWS_QUERY : '',
        addUserSegmentsFilter
          ? buildGoldUserSegmentsFilter(userSegmentIds)
          : '',
        ...filters
      ),
    [
      staticFilters,
      isFilteredByRating,
      addUserSegmentsFilter,
      userSegmentIds,
      filters,
    ]
  );
};
