import type { QualifyingStatus } from '@jane/shared/models';
import {
  Card,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { StoreSpecial } from '@jane/shared/types';
import { formatCurrency } from '@jane/shared/util';

import { DealUnlockedCard } from './DealUnlockedCard';
import {
  ProgressBar,
  ProgressContainer,
  ProgressFill,
} from './MenuSpecialDetailProgressBar.styles';

interface MenuSpecialDetailProgressBarProps {
  special: StoreSpecial;
  status: QualifyingStatus;
}

export const MenuSpecialDetailProgressBar = ({
  status,
  special,
}: MenuSpecialDetailProgressBarProps) => {
  const isMobile = useMobileMediaQuery({});
  const {
    target_weight,
    product_weight,
    qualifying_status,
    qualifying_threshold,
    product_price,
    discount_total_amount,
  } = status;

  const isBulk = special.special_type === 'bulk_pricing';
  const isCartTotal = special.special_type === 'cart_total';

  const targetAmount = (isBulk ? target_weight : qualifying_threshold) ?? 0;
  const productAmount = (isBulk ? product_weight : product_price) ?? 0;

  const formatAmount = isCartTotal
    ? formatCurrency
    : (amount: string | number) => `${amount}g`;

  const progress = Math.min(100, (productAmount / targetAmount) * 100);
  const completed = qualifying_status === 'applied';

  return (
    <ProgressContainer id="progress-container-scroll-position">
      <Card flat width="100%">
        <Card.Content background="grays-ultralight">
          <Flex
            p={isMobile ? 12 : 40}
            alignItems="center"
            flexDirection="column"
          >
            {!completed && (
              <Typography branded variant="header-bold" mb={24}>
                Buy {formatAmount(targetAmount)} to unlock deal
              </Typography>
            )}
            {completed && (
              <Typography branded variant="header-bold" color="info" mb={24}>
                Deal unlocked!
              </Typography>
            )}
            <ProgressBar>
              <ProgressFill progress={progress} completed={completed} />
            </ProgressBar>
            <Typography mt={12} mb={20}>
              {formatAmount(completed ? targetAmount : productAmount)} of{' '}
              {formatAmount(targetAmount)}
            </Typography>

            {completed && (
              <DealUnlockedCard discountAmount={discount_total_amount ?? 0} />
            )}
          </Flex>
        </Card.Content>
      </Card>
    </ProgressContainer>
  );
};
