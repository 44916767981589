import { Suspense, memo } from 'react';

import type { LoadingWrapperVariant } from './loadingWrapper/loadingWrapper';
import { LoadingWrapper } from './loadingWrapper/loadingWrapper';
import { withScrollRestoration } from './withScrollRestoration/withScrollRestoration';

export const Loader =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Component: React.FC, variant?: LoadingWrapperVariant) =>
    memo((props: any) => {
      const { restoreScroll = false, ...componentProps } = props;

      const WrappedComponent = restoreScroll
        ? withScrollRestoration(Component)
        : Component;

      return (
        <Suspense
          fallback={
            <LoadingWrapper isLoading variant={variant || 'full-page'} />
          }
        >
          <WrappedComponent {...componentProps} />
        </Suspense>
      );
    });
