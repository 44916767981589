import { useCheckout } from '@jane/shared-ecomm/providers';
import { Flex, PartnerLogo, Typography } from '@jane/shared/reefer';

export const CheckoutStoreCard = () => {
  const { store } = useCheckout();

  return (
    <Flex alignItems="center">
      <PartnerLogo name={store.name} variant="store" image={store.photo} />
      <Flex flexDirection="column" ml={16} justifyContent="center">
        <Typography variant="body-bold">{store.name}</Typography>
        <Typography>{store.address}</Typography>
        <Typography>{`${store.city}, ${store.state} ${store.zip}`}</Typography>
      </Flex>
    </Flex>
  );
};
