import { useEffect, useState } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, Flex, Link, Loading, Typography } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { loadSquareCard } from './loadSquareCard';

const SQUARE_FORM_ID = 'square-payment-form';

export const SquarePayment = () => {
  const {
    store: { square_integration },
  } = useCheckout();
  const { squareCard, squareCardDetails, squareToken, updatePrepayments } =
    useCheckoutPayments();
  const [loaded, setLoaded] = useState(!!squareToken);

  const handleRemoveCard = () => {
    setLoaded(false);
    updatePrepayments({ squareCardDetails: undefined, squareToken: '' });
  };

  useEffect(() => {
    if (square_integration?.location_id && !loaded && !squareToken) {
      const initializeCard = async () => {
        const initializedCard = await loadSquareCard(
          square_integration?.location_id
        );
        await initializedCard.attach(`#${SQUARE_FORM_ID}`);
        squareCard.current = initializedCard;
        setLoaded(true);
      };

      try {
        initializeCard();
      } catch (e) {
        trackError(
          new Error('Square Web Payments SDK initializing card failed')
        );
      }
    }
  }, [squareCard, square_integration?.location_id, squareToken, loaded]);

  if (squareCardDetails?.last4 && squareToken)
    return (
      <Flex>
        <Typography variant="body-bold">
          {squareCardDetails.brand} ending in {squareCardDetails.last4}
        </Typography>
        <Link ml="auto" onClick={handleRemoveCard}>
          Change
        </Link>
      </Flex>
    );

  return (
    <Box id={SQUARE_FORM_ID}>
      {!loaded && (
        <Box mt={12} position="relative" height={24}>
          <Loading />
        </Box>
      )}
    </Box>
  );
};
