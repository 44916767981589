import { useNavigate } from 'react-router-dom';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { useJaneUser } from '@jane/shared/data-access';
import {
  Button,
  ChevronLeftIcon,
  Flex,
  Link,
  Typography,
  useDesktopMediaQuery,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import { pluralize } from '@jane/shared/util';

import { StyledHeader } from './checkoutHeader.styles';

interface CheckoutHeaderProps {
  handleShowLogin: () => void;
}

export const CheckoutHeader = ({ handleShowLogin }: CheckoutHeaderProps) => {
  const navigate = useNavigate();
  const { data: janeUserData } = useJaneUser();
  const isMobile = useTabletMediaQuery({ width: 'max' });
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });
  const {
    appInfo: { bloomUserExperience },
    cart,
    appInfo: { disableAuthFeatures },
    dispatches: { openCart },
  } = useCheckout();

  const signedIn = Boolean(janeUserData?.user?.id);
  const showLogin = !signedIn && !isMobile && !disableAuthFeatures;

  const cartItems = cart.products.reduce((acc, curr) => (acc += curr.count), 0);

  return (
    <StyledHeader bloomUserExperience={bloomUserExperience}>
      <Flex alignItems="center" ml={isDesktop ? 24 : 16}>
        <Link variant="minimal" onClick={() => navigate(-1)}>
          <ChevronLeftIcon altText="Go to homepage" size="md" />
        </Link>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Typography
          variant={
            isDesktop && !bloomUserExperience ? 'header-bold' : 'body-bold'
          }
          color="grays-black"
          branded
        >
          Checkout
          {(isDesktop || bloomUserExperience) && (
            <>
              &nbsp;
              <Link onClick={() => openCart()}>
                (
                {pluralize({
                  noun: 'item',
                  number: cartItems,
                })}
                )
              </Link>
            </>
          )}
        </Typography>
      </Flex>

      {showLogin && (
        <Button
          ml="auto"
          my="auto"
          size={isDesktop ? 'default' : 'small'}
          mr={isDesktop ? 64 : 16}
          label="Sign in"
          variant={isDesktop ? 'secondary' : 'minimal'}
          onClick={handleShowLogin}
        />
      )}
    </StyledHeader>
  );
};
