import { Navigate, useLocation } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { FramelessEmbedAppContainer } from '@jane/monolith/boost';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

import MenuSpecialsRedirect from './features/MenuSpecialsRedirect';

const Checkout = Loader(load(() => import('./features/Checkout')));
const EmbeddedCart = Loader(load(() => import('./features/EmbeddedCart')));
const Menu = Loader(
  load(() => import('./features/Menu')),
  'menu'
);
const MenuFeatured = Loader(load(() => import('./features/MenuFeatured')));
const MenuIndex = Loader(load(() => import('./features/MenuIndex')));
const MenuProductDetail = Loader(
  load(() => import('./features/MenuProductDetail')),
  'menu-product-detail'
);
const MenuProducts = Loader(load(() => import('./features/MenuProducts')));
const MenuSpecialDetail = Loader(
  load(() => import('./features/MenuSpecialDetail'))
);
const MenuSpecialDetailRedirect = Loader(
  load(() => import('./features/MenuSpecialDetailRedirect'))
);
const MenuSpecials = Loader(load(() => import('./features/MenuSpecials')));
const EnsureNotLoggedInContainer = Loader(
  load(() => import('./features/EnsureNotLoggedInContainer'))
);
const ForgotPassword = Loader(load(() => import('./features/ForgotPassword')));
const Identification = Loader(load(() => import('./features/Identification')));
const Login = Loader(load(() => import('./features/Login')));
const Logout = Loader(load(() => import('./features/Logout')));
const ProductReview = Loader(load(() => import('./features/ProductReview')));
const Profile = Loader(load(() => import('./features/Profile')));
const ProfileChangePassword = Loader(
  load(() => import('./features/ProfileChangePassword'))
);
const ProfileEdit = Loader(load(() => import('./features/ProfileEdit')));
const Registration = Loader(load(() => import('./features/Registration')));
const ReplaceCart = Loader(load(() => import('./features/ReplaceCart')));

const RequiresAuthentication = Loader(
  load(() => import('./features/RequiresAuthentication'))
);
const ReservationDetail = Loader(
  load(() => import('./features/ReservationDetail'))
);
const ReservationHistory = Loader(
  load(() => import('./features/ReservationHistory'))
);
const ReservationReceipt = Loader(
  load(() => import('./features/ReservationReceipt'))
);

const ResetPassword = Loader(load(() => import('./features/ResetPassword')));

const Root = () => <FramelessEmbedAppContainer />;

const ProfileRoot = () => (
  <RequiresAuthentication>
    <Profile embeddedMode />
  </RequiresAuthentication>
);

const Redirect = ({ to }) => {
  const location = useLocation();

  return <Navigate to={`${to}${location.search}`} />;
};

export const buildBoostForAllRoutes = (): RouteObject[] => {
  return [
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '/',
          element: <Redirect to="/menu" />,
        },
        {
          path: '/menu',
          element: <Menu />,
          children: [
            {
              index: true,
              element: <MenuIndex />,
            },
            {
              path: 'featured',
              element: <MenuFeatured restoreScroll />,
            },
            {
              path: ':category',
              element: <MenuProducts restoreScroll />,
            },
            {
              path: ':category/:featured_category',
              element: <MenuProducts restoreScroll />,
            },
            {
              path: 'specials',
              element: <MenuSpecials restoreScroll />,
            },
            {
              path: 'specials/:special_id/:slug',
              element: <MenuSpecialDetail />,
            },
          ],
        },
        {
          path: '/menu/products/:product_id/:slug',
          element: <MenuProductDetail />,
        },
        {
          path: '/products/:product_id/:slug',
          element: <MenuProductDetail />,
        },
        {
          path: '/products/:product_id/:slug/reviews/new',
          element: (
            <RequiresAuthentication>
              <ProductReview />
            </RequiresAuthentication>
          ),
        },
        {
          path: '/logout',
          element: <Logout />,
        },
        {
          path: '/cart',
          element: <EmbeddedCart />,
        },
        {
          path: '/cart/checkout',
          element: (
            <RequiresAuthentication>
              <Checkout />
            </RequiresAuthentication>
          ),
        },
        {
          path: '/cart/guest_checkout',
          element: <Checkout />,
        },
        {
          path: '/reservation_receipt/:reservation_id',
          element: <ReservationReceipt />,
        },
        {
          path: '/replaceCart',
          element: <ReplaceCart />,
        },
        {
          path: '/login',
          element: (
            <EnsureNotLoggedInContainer>
              <Login />
            </EnsureNotLoggedInContainer>
          ),
        },
        {
          path: '/register',
          element: (
            <EnsureNotLoggedInContainer>
              <Registration />
            </EnsureNotLoggedInContainer>
          ),
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/reset-password/:token',
          element: <ResetPassword />,
        },
        {
          path: '/specials',
          element: <MenuSpecialsRedirect />,
        },
        {
          path: '/specials/:special_id',
          element: <MenuSpecialDetailRedirect />,
        },
        {
          path: '/specials/:special_id/:slug',
          element: <MenuSpecialDetailRedirect />,
        },
        {
          path: '/profile',
          element: <ProfileRoot />,
          children: [
            {
              path: '/profile/reservations',
              element: <ReservationHistory />,
            },
            {
              path: '/profile/reservations/:reservation_id',
              element: <ReservationDetail />,
            },
            {
              path: '/profile/identification',
              element: <Identification />,
            },
            {
              path: '/profile/edit',
              element: <ProfileEdit />,
            },
            {
              path: '/profile/password',
              element: <ProfileChangePassword />,
            },
          ],
        },
      ],
    },
  ];
};
