import { useNavigate } from 'react-router-dom';

import { useMenu } from '@jane/shared-ecomm/providers';
import { scrollToTop } from '@jane/shared-ecomm/util';
import { Button, Flex, Link } from '@jane/shared/reefer';

interface MenuNavButtonsProps {
  isMobile: boolean;
}

export const MenuNavButtons = ({ isMobile }: MenuNavButtonsProps) => {
  const navigate = useNavigate();
  const { paths } = useMenu();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      py={isMobile ? 32 : 48}
      px={isMobile ? 24 : 48}
    >
      <Button
        mb={16}
        label={'Shop all products'}
        full={isMobile}
        onClick={() => navigate(paths.menuCategory('all'))}
      />
      <Link onClick={scrollToTop}>Back to top</Link>
    </Flex>
  );
};
