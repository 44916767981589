import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';

import { queryKeys, urls } from './moneris.util';

interface MonerisResponse {
  ticket: string;
}
interface MonerisPreloadProps {
  amount: string;
  storeId: Id;
}

const postMonerisPreload = async ({ amount, storeId }: MonerisPreloadProps) =>
  await janeApi.post<MonerisResponse>(
    urls.preload,
    { amount, store_id: storeId },
    getJwt()
  );

export const useMonerisPreload = createUseQueryHook<
  MonerisPreloadProps,
  MonerisResponse
>(postMonerisPreload, queryKeys.preload);
