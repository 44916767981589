import type { AccordionItems } from './CheckoutAccordionProvider';

interface GetDefaultExpandedArgs {
  bloomUserExperience?: boolean;
  infoValid: boolean;
  optionsValid: boolean;
}

// Upon first load, we always expand the options section, but if the user signs in after completing some steps, we'll skip to where they left off.
export const getDefaultExpanded = ({
  bloomUserExperience,
  optionsValid,
  infoValid,
}: GetDefaultExpandedArgs): AccordionItems => {
  if (bloomUserExperience) {
    if (!infoValid) {
      return 'info';
    } else if (!optionsValid) {
      return 'options';
    } else {
      return 'payments';
    }
  } else {
    if (!optionsValid) {
      return 'options';
    } else if (!infoValid) {
      return 'info';
    } else {
      return 'payments';
    }
  }
};
