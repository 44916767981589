import { useMenu, useUserPreferences } from '@jane/shared-ecomm/providers';
import { trackStoreInfoClick } from '@jane/shared-ecomm/tracking';
import { Box, Link, Typography } from '@jane/shared/reefer';
import { presentDistance, statePostalCode } from '@jane/shared/util';

import { StyledGrid } from './MenuHeader.styles';

export const MenuContact = () => {
  const {
    store: { address, city, lat, long, phone, state, zip },
  } = useMenu();
  const {
    userLocation: { coordinates },
  } = useUserPreferences();

  const distance = presentDistance(coordinates, { lat, long });

  const destinationParams =
    address && city && state && zip
      ? `${address}, ${city}, ${state} ${zip}`
      : lat && long
      ? `${lat}, ${long}`
      : '';

  const directionsUrl = coordinates
    ? `https://www.google.com/maps/dir/?api=1&destination=${destinationParams}&origin=${coordinates.lat}, ${coordinates.long}`
    : `https://www.google.com/maps/place/${destinationParams}`;

  return (
    <Box>
      <Typography variant="body-bold" pb={8}>
        Contact
      </Typography>
      <StyledGrid>
        <Box pr={4}>
          {address && <Typography>{address}</Typography>}
          <Typography>
            {`${city}, ${statePostalCode(state)}`}
            {zip && ` ${zip}`}
          </Typography>
        </Box>
        <Box>
          {distance && (
            <Link
              onClick={() => trackStoreInfoClick('map')}
              href={directionsUrl}
              mr={4}
            >
              <Typography>{distance}</Typography>
            </Link>
          )}
          <Link
            href={`tel:${phone}`}
            onClick={() => trackStoreInfoClick('phone')}
          >
            <Typography>{phone}</Typography>
          </Link>
        </Box>
      </StyledGrid>
    </Box>
  );
};
