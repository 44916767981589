import { useMenu } from '@jane/shared-ecomm/providers';
import { trackStoreInfoClick } from '@jane/shared-ecomm/tracking';
import {
  Flex,
  Link,
  PartnerLogo,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

export const MenuHeaderMin = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  const { store, paths } = useMenu();

  const isMobile = useMobileMediaQuery({});

  return (
    <Flex alignItems="center" gap={isMobile ? 16 : 24} py={16}>
      <PartnerLogo
        variant="store"
        name={store.name}
        image={store.photo}
        size="xs"
      />
      <Link variant="minimal" to={paths.menu()}>
        <Typography
          variant="body-bold"
          ariaLabel={`Shop ${store.name}'s dispensary menu`}
        >
          Shop
        </Typography>
      </Link>
      {store.reviews_count > 0 && (
        <Link
          variant="minimal"
          to={paths.menuReviews()}
          onClick={() => trackStoreInfoClick('ratings')}
        >
          <Typography variant="body-bold">Reviews</Typography>
        </Link>
      )}
      <Link variant="minimal" onClick={handleOpenModal}>
        <Typography variant="body-bold">About</Typography>
      </Link>
    </Flex>
  );
};
