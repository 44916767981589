import { useInfiniteQuery } from '@tanstack/react-query';
import type { QueryFunctionContext } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';
import type { StoreSpecial } from '@jane/shared/types';

import { queryKeys } from './storeSpecials.util';

interface StoreSpecialsPageArgs {
  limit: number;
  pageOffset: number;
  storeId: Id;
}

interface StoreSpecialsResponse {
  offset: number;
  specials: StoreSpecial[];
  total: number;
}

const getStoreSpecialsPage = async ({
  limit,
  pageOffset,
  storeId,
}: StoreSpecialsPageArgs) => {
  const params = new URLSearchParams({
    limit: `${limit}`,
    offset: `${pageOffset}`,
  });

  return await api.get<StoreSpecialsResponse>(
    `${config.apiPath}/stores/${storeId}/specials/page?${params.toString()}`
  );
};

type SpecialsPageQueryResponse = StoreSpecialsResponse & { pageParam: number };

export const useGetStoreSpecialsPage = (
  storeId: Id,
  queryOptions: object = {},
  limit = 15
) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam = 0 }: QueryFunctionContext) => {
      const result = await getStoreSpecialsPage({
        storeId,
        pageOffset: pageParam,
        limit,
      });
      // The backend returns the offset that is used for the next call
      // so we are storing that as the pageParam
      return { ...result, pageParam: result.offset };
    },
    queryKey: queryKeys.storeSpecialsPage(storeId, limit),
    getNextPageParam: (lastPage: SpecialsPageQueryResponse) => {
      const isLastPage = lastPage.specials.length < limit;

      return isLastPage ? undefined : lastPage.pageParam;
    },
    ...queryOptions,
  });
};

export const storeSpecialsPage = (
  storeId: Id,
  queryOptions: object = {},
  limit = 15
) => ({
  enabled: !!storeId,
  queryFn: () => getStoreSpecialsPage({ storeId, pageOffset: 0, limit }),
  queryKey: queryKeys.storeSpecialsPage(storeId, limit),
  ...queryOptions,
});
