import { config } from '@jane/shared/config';
import type { Id } from '@jane/shared/models';
import { tStoreSpecials } from '@jane/shared/models';

const urlRoot = (storeId: Id) => `${config.apiPath}/stores/${storeId}/specials`;

export const getCurrentStoreSpecials = (storeId: Id) => ({
  url: urlRoot(storeId),
  type: tStoreSpecials,
});
