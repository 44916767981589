import type { Id } from '@jane/shared/models';

export const urls = {
  preload: '/moneris/preload',
};

const queryKeyBase = ['moneris'];

export const queryKeys = {
  preload: ({ amount, storeId }: { amount: string; storeId: Id }) => [
    ...queryKeyBase,
    'preload',
    amount,
    storeId,
  ],
};
