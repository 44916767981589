import { useEffect, useState } from 'react';

import {
  BankLinkingModal,
  SelectBankAccountModal,
} from '@jane/shared-ecomm/components';
import {
  useAeropayUser,
  useSelectAeropayBankAccount,
} from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import {
  trackChangeBankAccountSuccess,
  trackChangeBankAccountTap,
} from '@jane/shared-ecomm/tracking';
import { useJaneUser } from '@jane/shared/data-access';
import type { Id } from '@jane/shared/models';
import {
  Box,
  Button,
  EditIcon,
  Flex,
  JanePayLogo,
  Link,
  Skeleton,
  Typography,
  useToast,
} from '@jane/shared/reefer';

export const JanePayHeader = () => {
  const [openSelectBankModal, setOpenSelectBankModal] = useState(false);
  const [openBankLinkingModal, setOpenBankLinkingModal] = useState(false);
  const [addNewAccount, setAddNewAccount] = useState(false);
  const toast = useToast();
  const {
    store: { jane_pay_integration, photo },
  } = useCheckout();

  const { data: janeUserData } = useJaneUser();
  const { data: aeropayUserData, isFetching } = useAeropayUser({
    janeUserId: janeUserData?.user?.id || null,
  });

  const {
    mutate: selectAeropayBankAccount,
    isLoading,
    isError,
  } = useSelectAeropayBankAccount({
    janeUserId: janeUserData?.user.id as Id,
    onMutationSuccess: () =>
      trackChangeBankAccountSuccess({ source: 'checkout' }),
  });

  const selectedBank =
    aeropayUserData &&
    aeropayUserData?.bank_accounts.find((bank) => bank.is_selected);

  const defaultBank = selectedBank || aeropayUserData?.bank_accounts[0];
  const hideBranding = Boolean(jane_pay_integration?.white_label);

  useEffect(() => {
    if (!isLoading && isError) {
      toast.add({
        label: 'Could not update primary bank account',
        variant: 'error',
      });
    }
  }, [isLoading, isError]);

  return (
    <>
      {isFetching || isLoading ? (
        <Box>
          <Skeleton.Bone height={24} width={250} />
        </Box>
      ) : defaultBank ? (
        <Flex alignItems="center" width="100%">
          <Box>
            {!hideBranding && <JanePayLogo width={98} height={20} />}
            <Typography mt={hideBranding ? 0 : 8} variant="body-bold">
              {defaultBank.name}
            </Typography>
          </Box>
          {aeropayUserData.bank_accounts.length > 0 && (
            <Box ml="auto">
              <Button.Icon
                variant="tertiary"
                icon={<EditIcon />}
                onClick={() => {
                  trackChangeBankAccountTap();
                  setOpenSelectBankModal(true);
                }}
              />
            </Box>
          )}
        </Flex>
      ) : (
        <Box>
          {!hideBranding && <JanePayLogo width={98} height={20} />}
          <Typography as="div" mt={hideBranding ? 0 : 8}>
            Pay by linking your bank account{!hideBranding ? '.' : ''}&nbsp;
            {!hideBranding && (
              <Link href="https://customer-support.iheartjane.com/s/article/Jane-Pay-FAQs">
                Learn more
              </Link>
            )}
          </Typography>
        </Box>
      )}
      {aeropayUserData &&
        aeropayUserData.bank_accounts.length > 0 &&
        openSelectBankModal && (
          <SelectBankAccountModal
            open={openSelectBankModal}
            onRequestClose={() => setOpenSelectBankModal(false)}
            onSave={selectAeropayBankAccount}
            bankAccounts={aeropayUserData.bank_accounts}
            onAddNewAccount={() => {
              setOpenSelectBankModal(false);
              setOpenBankLinkingModal(true);
              setAddNewAccount(true);
            }}
          />
        )}
      {openBankLinkingModal && (
        <BankLinkingModal
          addNewAccount={addNewAccount}
          hideJanePayBranding={hideBranding}
          open={openBankLinkingModal}
          onRequestClose={() => {
            setOpenBankLinkingModal(false);
            setAddNewAccount(false);
          }}
          location="checkout"
          isJanePay
          storePhoto={photo || undefined}
        />
      )}
    </>
  );
};
