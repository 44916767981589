import { useEffect, useState } from 'react';

import {
  usePaytenderAuthorize,
  usePaytenderCancel,
  usePaytenderReservationStatus,
  usePaytenderStatus,
} from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, Loading, Modal, Typography } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

import { SharedButton } from '../../CheckoutButton/SharedButton';
import { useSubmitCheckout } from '../../util/hooks/useSubmitCheckout';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { PaytenderModal } from './PaytenderModal';
import { useBuildPaytenderPayload } from './useBuildPaytenderPayload';

export const PaytenderButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { submit } = useSubmitCheckout();
  const {
    dispatches: { checkoutError },
  } = useCheckout();

  const { setVerified } = useCheckoutPayments();

  const { buildPayload, isLoading } = useBuildPaytenderPayload();

  const { data: paytenderAuthorizeData, isFetching } = usePaytenderAuthorize(
    buildPayload(),
    {
      enabled: !isLoading,
      onError: () => {
        checkoutError('Could not authorize Paytender');
        setVerified(false);
      },
    }
  );

  const { mutate: cancelPaytender } = usePaytenderCancel();

  const { data: paytenderStatus, refetch: refetchStatus } = usePaytenderStatus({
    orderReference: paytenderAuthorizeData?.order_reference,
  });

  const handleError = (err: unknown) => {
    if (typeof err === 'string') {
      if (err.includes('status has not updated')) {
        if (paytenderAuthorizeData?.order_reference) {
          cancelPaytender({
            orderReference: paytenderAuthorizeData?.order_reference,
          });
        }
        trackError(
          new Error(
            'Cart status has not updated for completed paytender order'
          ),
          { orderReference: paytenderAuthorizeData?.order_reference }
        );

        checkoutError('There was an error getting Paytender status');
      }
    }
  };

  const { data: paytenderReservationStatus } = usePaytenderReservationStatus({
    enabled: paytenderStatus?.status === 'COMPLETED',
    orderReference: paytenderAuthorizeData?.order_reference,
    onError: handleError,
  });

  const handleSuccess = () => {
    setModalOpen(false);
    submit();
  };

  const handleClose = () => {
    refetchStatus();
    setModalOpen(false);
  };

  const handleMessage = (event: MessageEvent) => {
    if (!event.data.orderReference) return;

    if (event.data.statusMessage === 'success') {
      handleClose();
    }

    if (event.data.statusMessage === 'cancelled') {
      setModalOpen(false);
    }
  };

  // If the user somehow closes the Paytender modal after paying but without confirmation;
  // we re-check the status and finish the checkout.
  useEffect(() => {
    if (paytenderReservationStatus?.status === 'verification') {
      handleSuccess();
    }
  }, [paytenderStatus, paytenderReservationStatus]);

  if (paytenderAuthorizeData?.authorized) return null;

  return (
    <>
      <SharedButton
        label="Pay now with Paytender"
        onClick={() => setModalOpen(true)}
        loading={isFetching || modalOpen}
      />

      {paytenderReservationStatus?.status === 'pending' && (
        <Modal variant="dialogue" open={true} onRequestClose={() => null}>
          <Modal.Content>
            <Box mb={16} position="relative">
              <Loading size="lg" />
            </Box>
            <Typography textAlign="center">Processing payment...</Typography>
          </Modal.Content>
        </Modal>
      )}

      {paytenderAuthorizeData?.redirect_url && (
        <Modal onRequestClose={handleClose} overlayClose open={modalOpen}>
          <Modal.Content>
            <PaytenderModal
              handleMessage={handleMessage}
              src={paytenderAuthorizeData.redirect_url}
            />
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
