import type { MenuTabTapSources } from '@jane/shared-ecomm/tracking';
import type { MenuCategoryPath } from '@jane/shared/types';

export const getTabTrackingSource = (
  pathname: string,
  category?: MenuCategoryPath
): MenuTabTapSources => {
  if (pathname.includes('/specials')) return '/specials';
  if (pathname.includes('/featured')) return '/featured';
  if (!category) return null;
  return `/${category}`;
};
