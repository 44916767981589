import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type {
  LocalizedProductContent,
  PendingCartProduct,
} from '@jane/shared/models';

export const localizeCartProduct = (
  product: PendingCartProduct,
  localizedContent: LocalizedProductContent[]
) => {
  const localizedProductContent = localizedContent?.find(
    (localized) => localized.product_id === product.product_id
  );

  if (localizedProductContent) {
    // override default value with localized value (state-specified) if field is populated
    const localizedProperties = {
      name: isNil(localizedProductContent.name)
        ? product.name
        : localizedProductContent.name,
      description: isNil(localizedProductContent.description)
        ? product.description
        : localizedProductContent.description,
      brand_subtype: isNil(localizedProductContent.brand_subtype)
        ? product.brand_subtype
        : localizedProductContent.brand_subtype,
      image_urls: isEmpty(localizedProductContent.image_urls)
        ? product.image_urls
        : localizedProductContent.image_urls,
      category: isNil(localizedProductContent.category)
        ? product.category
        : localizedProductContent.category,
    };

    return {
      ...product,
      ...localizedProperties,
    };
  }

  return product;
};
