import { PaymentMethods } from '../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from './CheckoutPaymentsProvider';
import { JanePayButton } from './JanePay/JanePayButton';
import { SquareButton } from './Square/SquareButton';
import { StrongholdButton } from './Stronghold/StrongholdButton';

export const CheckoutPrePaymentsButton = () => {
  const { selected } = useCheckoutPayments();

  const renderButton = () => {
    switch (selected) {
      case PaymentMethods.janePay:
        return <JanePayButton />;
      case PaymentMethods.square:
        return <SquareButton />;
      case PaymentMethods.stronghold:
        return <StrongholdButton />;
      default:
        return null;
    }
  };

  return renderButton();
};
