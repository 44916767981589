import qs from 'qs';

import { config } from '@jane/shared/config';

import type { TrackedEvent } from './eventNames';
import { EventNames } from './eventNames';

const CRM_TRACKED_EVENTS = [
  EventNames.ViewedProduct,
  EventNames.ViewedSpecial,
  EventNames.AddedProductToCart,
  EventNames.CheckoutSuccess,
  EventNames.SearchedMenuProducts,
];

export const crmTrack = (event: TrackedEvent, isFlagEnabled = false) => {
  const { event: eventName, ...meta } = event;

  if (!isFlagEnabled) {
    return;
  }

  if (!CRM_TRACKED_EVENTS.includes(eventName)) {
    return;
  }

  const isKioskMode = 'appMode' in meta && meta.appMode === 'kiosk';

  // If url has query param "crm_session", save event as part of crm flow
  //remove the leading ? with slice 1
  const search = qs.parse(window.location.search.slice(1));

  if (config.dev && !isKioskMode) {
    // eslint-disable-next-line no-console
    console.log('Crm Tracking', eventName, meta);
    return;
  }

  if (!search['crm_session']) {
    return;
  }

  if (eventName === EventNames.SearchedMenuProducts) {
    if ('filters' in meta && meta.filters.includes('has_brand_discount:true')) {
      crmMixpanel?.track(EventNames.ViewedSpecial, {
        ...meta,
        session_id: search['crm_session'],
        user_id: search['uid'],
        isGold: true,
      });
      return;
    } else {
      return;
    }
  }

  !isKioskMode &&
    crmMixpanel?.track(eventName, {
      ...meta,
      session_id: search['crm_session'],
    });
};
