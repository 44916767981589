import { useMenu } from '@jane/shared-ecomm/providers';
import type { EligibleCartProduct, MenuProduct } from '@jane/shared/models';
import {
  Box,
  DismissIcon,
  Flex,
  Image,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { productPhotoMetadata } from '@jane/shared/util';

import { StyledButtonIcon } from './MenuSpecialDetailBundleProgress.styles';

interface BundleProductCardProps {
  product: EligibleCartProduct;
  type: 'qualified' | 'discounted';
}

export const BundleProductCard = ({
  product,
  type,
}: BundleProductCardProps) => {
  const isMobile = useMobileMediaQuery({});
  const {
    cart,
    dispatches: { deleteCartItem, attemptToAddToCart },
    store,
  } = useMenu();
  const { id, price_id, name } = product;
  const { url: photoUrl } = productPhotoMetadata(product);

  const handleRemoveItem = () => {
    const cartProduct = cart.products.find(
      (cartProduct) =>
        cartProduct.id === id && cartProduct.price_id === price_id
    );

    if (cartProduct && cartProduct.count > 1) {
      attemptToAddToCart({
        menuProduct: product as MenuProduct,
        location: 'menu',
        count: cartProduct.count - 1,
        price_id,
        store,
        withoutDrawer: true,
      });
    } else {
      deleteCartItem(product.id, product.price_id);
    }
  };

  return (
    <Flex
      width={isMobile ? 98 : 120}
      height={isMobile ? 98 : 120}
      alignItems="center"
      justifyContent="center"
      border="grays-light"
      borderRadius="sm"
      position="relative"
      background="grays-white"
      data-testid={`bundle-${type}-product`}
    >
      <Box position="absolute" zIndex={2} top={-8} right={-8}>
        <StyledButtonIcon
          variant="minimal-inverse"
          icon={<DismissIcon size="sm" />}
          onClick={handleRemoveItem}
        />
      </Box>

      <Image
        src={photoUrl}
        altText={name}
        objectFit="cover"
        borderRadius="rounded"
        height="100%"
        responsive
        sizes="card"
      />
    </Flex>
  );
};
