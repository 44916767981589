import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import GoogleReCAPTCHA from 'react-google-recaptcha';

import { Portal } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import { Hide } from '@jane/shared/reefer';
import { inIframe, postMessageToIframeParent } from '@jane/shared/util';

interface ReCAPTCHAProps {
  onCancel?: () => void;
  onError?: () => void;
  onExpired?: () => void;
  onSuccess: () => void;
}

interface GoogleRecaptchaContextType {
  ReCAPTCHA: FunctionComponent;
  execute: (props: ReCAPTCHAProps) => void;
  getValue: () => string | null;
  reset: () => void;
}

const GoogleRecaptchaContext = createContext<GoogleRecaptchaContextType>(
  {} as GoogleRecaptchaContextType
);

export const GoogleRecaptchaProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isOpen = useRef<boolean>(false);
  const isInIframe = inIframe();

  const recaptchaRef = useRef<GoogleReCAPTCHA>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const onCancelRef = useRef<(() => void) | null>(null);
  const onErrorRef = useRef<(() => void) | null>(null);
  const onExpiredRef = useRef<(() => void) | null>(null);
  const onSuccessRef = useRef<(() => void) | null>(null);

  const { disableRecaptcha, recaptchaKey } = config;

  const clearRefs = () => {
    isOpen.current = false;
    onCancelRef.current = null;
    onErrorRef.current = null;
    onExpiredRef.current = null;
    onSuccessRef.current = null;
  };

  const handleCancel = () => {
    onCancelRef.current && onCancelRef.current();
    clearRefs();
    isInIframe && handleIframeClose();
  };

  const handleSuccess = () => {
    onSuccessRef.current && onSuccessRef.current();
    clearRefs();
    isInIframe && handleIframeClose();
  };

  const handleExpired = () => {
    onExpiredRef.current && onExpiredRef.current();
    clearRefs();
    isInIframe && handleIframeClose();
  };

  const handleError = () => {
    onErrorRef.current && onErrorRef.current();
    clearRefs();
    isInIframe && handleIframeClose();
  };

  const handleClick = ({ target }: MouseEvent) => {
    if (
      isOpen.current &&
      containerRef.current &&
      !containerRef.current.contains(target as Node)
    ) {
      handleCancel();
    }
  };

  const handleIframeOpen = () => {
    postMessageToIframeParent({
      messageType: 'enableShrinkEveryResize',
    });

    postMessageToIframeParent({
      messageType: 'openModal',
    });

    postMessageToIframeParent({
      messageType: 'scrollToTop',
    });
  };

  const handleIframeClose = () => {
    postMessageToIframeParent({
      messageType: 'disableShrinkEveryResize',
    });

    postMessageToIframeParent({
      messageType: 'closeModal',
    });
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClick);

    return () => {
      window.removeEventListener('mousedown', handleClick);
      handleCancel();
    };
  }, []);

  const DisabledReCAPTCHA = () => <></>;
  const ReCAPTCHA = () => (
    <Portal>
      <Hide isHidden>
        <div ref={containerRef}>
          <GoogleReCAPTCHA
            onChange={handleSuccess}
            onExpired={handleExpired}
            onErrored={handleError}
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            size="invisible"
            badge="bottomleft"
          />
        </div>
      </Hide>
    </Portal>
  );

  const handleExecute = ({
    onError,
    onCancel,
    onExpired,
    onSuccess,
  }: ReCAPTCHAProps) => {
    if (recaptchaRef.current) {
      onSuccessRef.current = onSuccess;
      onCancelRef.current = onCancel || null;
      onErrorRef.current = onError || null;
      onExpiredRef.current = onExpired || null;

      recaptchaRef.current.execute();
      isOpen.current = true;
      isInIframe && handleIframeOpen();
    }
  };

  const handleReset = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    clearRefs();
  };

  const handleGetValue = (): string | null => {
    if (recaptchaRef.current) {
      return recaptchaRef.current.getValue();
    }
    return null;
  };

  const value = useMemo(() => {
    return {
      ReCAPTCHA: disableRecaptcha ? DisabledReCAPTCHA : ReCAPTCHA,
      execute: handleExecute,
      getValue: handleGetValue,
      reset: handleReset,
    };
  }, [disableRecaptcha]);

  return (
    <GoogleRecaptchaContext.Provider value={value}>
      {children}
    </GoogleRecaptchaContext.Provider>
  );
};

export const useGoogleRecaptcha = () => {
  const context = useContext(GoogleRecaptchaContext);

  if (context === undefined) {
    throw new Error(
      'useGoogleRecaptcha must be used within a GoogleRecaptchaProvider'
    );
  }

  return context;
};
