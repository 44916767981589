import { useSearchContext } from '@jane/search/providers';
import type { ProductCardWithTrackingProps } from '@jane/shared-ecomm/components';
import { ProductCardWithTracking } from '@jane/shared-ecomm/components';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';

type MenuProductCardProps = Pick<
  ProductCardWithTrackingProps,
  | 'algoliaIndexName'
  | 'appliedWeightFilter'
  | 'breadcrumbs'
  | 'cardIndex'
  | 'carouselView'
  | 'columnPosition'
  | 'maxWeightOption'
  | 'menuRowName'
  | 'numColumns'
  | 'product'
  | 'productLocation'
  | 'rowPosition'
  | 'showOnlyWeights'
  | 'zone'
  | 'weightFilter'
  | 'withoutDrawerOnAddToCart'
  | 'placement'
>;

export const MenuProductCard = (props: MenuProductCardProps) => {
  const { searchState } = useSearchContext();

  const {
    appInfo: { appMode, janeDeviceId },
    cart,
    dispatches: { attemptToAddToCart, closeCart, deleteCartItem },
    listView,
    paths,
    store,
  } = useMenu();

  const { specials } = useSpecials();

  const cartIsOpen = Boolean(cart.is_open);
  const cartProducts = cart.products;
  const cartStoreId = cart.store?.id;

  return (
    <ProductCardWithTracking
      appMode={appMode}
      attemptToAddToCart={attemptToAddToCart}
      cartIsOpen={cartIsOpen || false}
      cartProducts={cartProducts}
      cartStoreId={cartStoreId}
      closeCart={closeCart}
      deleteCartItem={deleteCartItem}
      janeDeviceId={janeDeviceId}
      listView={listView}
      pdpPath={paths.menuProduct}
      searchState={searchState}
      specials={specials}
      store={store}
      {...props}
    />
  );
};
