import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';
import type { Brand } from '@jane/shared/models';

const queryKeys = {
  brand: (id: string) => ['brand', id],
};

const enableBrandFetch = (id: string) => !!id;
const fetchBrand = async (id: string) => {
  const { brand } = await janeApi.get<{ brand: Brand }>(
    `/brands/${id}`,
    getJwt()
  );
  return brand;
};

export const useBrand = createUseQueryHook<string, Brand>(
  fetchBrand,
  queryKeys.brand,
  enableBrandFetch
);
