import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutAccordion } from '../../CheckoutAccordion/CheckoutAccordionProvider';
import { useCheckoutPayments } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { useCartIsValid } from './useCartIsValid';
import { useCheckoutInfo } from './useCheckoutInfo';
import { useCheckoutOptionsIsValid } from './useCheckoutOptionsIsValid';
import { useUserConsents } from './useUserConsents';

const STEP_MAP = {
  info: 'customerInformation',
  options: 'reservationOptions',
  payments: 'payment',
  review: 'review',
};

const sanitizeString = (string: string | number | null | undefined) => {
  if (typeof string === 'string' || typeof string === 'number') {
    return string.toString().replace(/./g, '*');
  }
  return string;
};

const sanitizeObject = (
  object: { [key: string]: string | number | null | undefined },
  removeNull = false
) => {
  return Object.keys(object).reduce((prev, curr) => {
    if (removeNull && !object[curr]) return prev;
    return {
      ...prev,
      [curr]: sanitizeString(object[curr]),
    };
  }, {});
};

export const useBuildAbandonedCheckoutPayload = () => {
  const location = useLocation();
  const {
    appInfo: { appMode, checkoutError },
    cart: { reservation_mode: reservationMode },
    cartLineItems: { total: checkoutTotal },
    deliveryAddress,
    store: { id: storeId },
  } = useCheckout();

  const { getValues } = useFormContext();

  const { isValid: infoValid, docsValid } = useCheckoutInfo();
  const { expanded } = useCheckoutAccordion();
  const checkoutPayments = useCheckoutPayments();
  const {
    canPayIntent,
    selected: paymentMethod,
    setVerified,
    selectPaymentOption,
    squareCard,
    squareCardDetails,
    updatePrepayments,
    verified: paymentsValid,
    ...sanitizePayments
  } = checkoutPayments;
  const { cartIsValid, minimumIsMet: minimumMet } = useCartIsValid();
  const { checkoutConsents } = useUserConsents();
  const optionsValid = useCheckoutOptionsIsValid();

  const isDelivery = reservationMode === 'delivery';

  const buildFormState = () => {
    const formValues = getValues();
    const fulfillmentOptions = isDelivery
      ? formValues['deliveryOptions']
      : formValues['pickupOptions'];

    const [from, to] = fulfillmentOptions.fulfillmentTime?.split('>') || [];

    const { payfirma } = formValues['paymentOptions'];
    const payfirmaInfo =
      Object.keys(payfirma).length > 0
        ? {
            payfirmaCardCvv2: payfirma.cardCvv2,
            payfirmaCardExpiryMonth: payfirma.cardExpiry.slice(0, 2),
            payfirmaCardExpiryYear: payfirma.cardExpiry.slice(2),
            payfirmaCardNumber: payfirma.cardNumber,
            payfirmaCardPostalCode: payfirma.cardPostalCode,
          }
        : {};

    const {
      governmentIdentificationUrl,
      mmjIdBackUrl,
      mmjIdFrontUrl,
      mmjIdNumber,
      ...sanitizeInfo
    } = formValues['customerInformation'];

    const formState = {
      customerInformation: {
        hasGovernmentId: !!governmentIdentificationUrl,
        hasMMJIdFront: !!mmjIdFrontUrl,
        hasMMJIdBack: !!mmjIdBackUrl,
        mmjIdNumber: mmjIdNumber ? sanitizeString(mmjIdNumber) : null,
        ...sanitizeObject(sanitizeInfo),
      },
      paymentOptions: {
        paymentMethod,
        ...sanitizeObject({ ...sanitizePayments, ...payfirmaInfo }, true),
      },
      reservationOptions: {
        reservationMode,
        fulfillmentTime: {
          from: from ? parseInt(from) : null,
          to: to ? parseInt(to) : null,
        },
        ...(isDelivery
          ? {
              delivery_address: {
                ...deliveryAddress,
                street: sanitizeString(deliveryAddress?.street),
                street2: sanitizeString(deliveryAddress?.street2),
              },
            }
          : {}),
        message: fulfillmentOptions.message,
        userConsent: checkoutConsents,
        ...(!isDelivery
          ? { curbsidePickup: formValues['pickupOptions'].pickupMode }
          : {}),
      },
    };

    return formState;
  };

  const buildPayload = () => {
    const currentStep =
      !expanded || (expanded === 'payments' && paymentsValid)
        ? 'review'
        : expanded;

    const checkoutStep = STEP_MAP[currentStep];

    return {
      appMode,
      checkoutError,
      checkoutTotal,
      formState: buildFormState(),
      guestCheckout: location.pathname.includes('/guest_checkout'),
      imageUploadFieldsValid: docsValid,
      invalid: !cartIsValid || !infoValid || !paymentsValid || !optionsValid,
      minimumMet,
      pathname: location?.pathname,
      step: checkoutStep,
      storeId,
    };
  };

  return { buildPayload };
};
