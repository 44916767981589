import isEqual from 'lodash/isEqual';
import type { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';

import { crmTrack } from './crmTrack';
import { EventNames, type TrackedEvent } from './eventNames';
import { track } from './track';

export const useTrack: (event: TrackedEvent | null) => void = (event) => {
  const lastEvent = useRef<TrackedEvent | null>(null);
  const crmTrackingEnabled = useFlag(FLAGS.bbCrmTracking);

  useEffect(() => {
    if (event && !isEqual(event, lastEvent.current)) {
      // regular tracking does not track viewed special
      if (event.event !== EventNames.ViewedSpecial) {
        track(event);
      }

      crmTrack(event, crmTrackingEnabled);
      lastEvent.current = event;
    }
  });
};

export const Track: FunctionComponent<{
  event: TrackedEvent | null;
}> = (props) => {
  useTrack(props.event);
  return null;
};
