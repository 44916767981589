/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Tag24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Tag 24">
      <g className="SVG">
        <path fill="#0E0E0E" d="M7.414 9.414a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M22.414 15.243a4 4 0 0 0 0-5.657l-8-8A4 4 0 0 0 11.586.414H4.414a4 4 0 0 0-4 4v7.172a4 4 0 0 0 1.172 2.828l8 8a4 4 0 0 0 5.657 0l7.171-7.171ZM13.828 21A2 2 0 0 1 11 21l-8-8a2 2 0 0 1-.586-1.414V4.414a2 2 0 0 1 2-2h7.172A2 2 0 0 1 13 3l8 8a2 2 0 0 1 0 2.829L13.828 21Z"
          clipRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: Tag24,
  sm: Tag24,
  lg: Tag24,
  xl: Tag24,
  xxl: Tag24,
  xxxl: Tag24,
};

export const TagIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'tag-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
