import type { ReactNode } from 'react';

import { LoadingWrapper } from '@jane/shared/components';
import {
  Box,
  ConditionalWrapper,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

export const MenuBanner = ({
  children,
  isLoading,
  isImage = false,
}: {
  children: ReactNode;
  isImage?: boolean;
  isLoading: boolean;
}) => {
  const isMobile = useMobileMediaQuery({});
  return (
    <LoadingWrapper
      variant="block"
      height={isImage ? 240 : 56}
      isLoading={isLoading}
      px={isMobile ? 24 : 64}
    >
      <ConditionalWrapper
        condition={!isImage}
        wrapper={(children) => (
          <Box
            width="100%"
            p={16}
            borderRadius="sm"
            background="grays-ultralight"
          >
            {children}
          </Box>
        )}
      >
        {children}
      </ConditionalWrapper>
    </LoadingWrapper>
  );
};
