import { useMemo } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { STALE_TIMES } from '@jane/shared/config';
import { useReservationSlots } from '@jane/shared/data-access';
import type { ReservationSlots } from '@jane/shared/models';

import { isAcceptingOrders } from '../isAcceptingOrders';

export interface FulfillmentSchedules {
  curbsideSlots: ReservationSlots;
  deliverySlots: ReservationSlots;
  hasFetchedDelivery: boolean;
  isAcceptingCurbside: boolean;
  isAcceptingDelivery: boolean;
  isAcceptingPickup: boolean;
  isLoadingDelivery: boolean;
  isLoadingPickup: boolean;
  pickupSlots: ReservationSlots;
  refetchSlots: () => void;
}

export const useFulfillmentSchedule = (): FulfillmentSchedules => {
  const {
    cart: { reservation_mode },
    deliveryAddress,
    store,
  } = useCheckout();
  const { lat, lng, zipcode } = deliveryAddress || {};
  const { id: storeId, curbside_pickup_setting, delivery, pickup } = store;

  const storeHasPickup = !!pickup && curbside_pickup_setting !== 'mandatory';
  const storeHasCurbside =
    !!pickup &&
    !!curbside_pickup_setting &&
    curbside_pickup_setting !== 'not_allowed';

  const isAcceptingCurbsideOrders = useMemo(
    () => isAcceptingOrders(store, 'curbside'),
    [store?.id]
  );
  const isAcceptingDeliveryOrders = useMemo(
    () => isAcceptingOrders(store, 'delivery'),
    [store?.id]
  );
  const isAcceptingPickupOrders = useMemo(
    () => isAcceptingOrders(store, 'pickup'),
    [store?.id]
  );

  const isAcceptingCurbside = storeHasCurbside && isAcceptingCurbsideOrders;
  const isAcceptingDelivery = !!delivery && isAcceptingDeliveryOrders;
  const isAcceptingPickup = storeHasPickup && isAcceptingPickupOrders;

  const curbsideEnabled = storeHasCurbside && isAcceptingCurbside;
  const pickupEnabled = storeHasPickup && isAcceptingPickup;
  const deliveryEnabled = isAcceptingDelivery && !!lat && !!lng && !!zipcode;

  const {
    data: curbsideData,
    isInitialLoading: curbsideLoading,
    refetch: refetchCurbside,
  } = useReservationSlots(
    {
      reservationMode: 'curbside',
      storeId,
    },
    { enabled: curbsideEnabled, staleTime: STALE_TIMES.short }
  );

  const {
    data: pickupData,
    isInitialLoading: pickupLoading,
    refetch: refetchPickup,
  } = useReservationSlots(
    {
      reservationMode: 'pickup',
      storeId,
    },
    { enabled: pickupEnabled, staleTime: STALE_TIMES.short }
  );

  const {
    data: deliveryData,
    isFetched: deliveryFetched,
    isInitialLoading: deliveryLoading,
    refetch: refetchDelivery,
  } = useReservationSlots(
    {
      reservationMode: 'delivery',
      storeId,
      lat,
      lng,
      zipcode,
    },
    { enabled: deliveryEnabled, staleTime: STALE_TIMES.short }
  );

  const refetchSlots = () => {
    if (reservation_mode === 'delivery') {
      deliveryEnabled && refetchDelivery();
    } else {
      curbsideEnabled && refetchCurbside();
      pickupEnabled && refetchPickup();
    }
  };

  const curbsideSlots = curbsideData?.reservation_slots || [];
  const deliverySlots = deliveryData?.reservation_slots || [];
  const pickupSlots = pickupData?.reservation_slots || [];

  return {
    curbsideSlots,
    deliverySlots,
    hasFetchedDelivery: deliveryFetched,
    isAcceptingCurbside,
    isAcceptingDelivery,
    isAcceptingPickup,
    isLoadingDelivery: deliveryLoading,
    isLoadingPickup: curbsideLoading || pickupLoading,
    pickupSlots,
    refetchSlots,
  };
};
