import { useQuery } from '@tanstack/react-query';

import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type {
  Id,
  QualifyingStatus,
  StoreSpecial as TStoreSpecial,
} from '@jane/shared/models';
import type { Nullable, StoreSpecial } from '@jane/shared/types';

const getStoreSpecials = async (storeId: Id) => {
  const { specials } = await api.get<{ specials: StoreSpecial[] }>(
    `${config.apiPath}/stores/${storeId}/specials`
  );
  return specials;
};

interface SpecialQualifyingStatusArgs {
  cartUuid?: Nullable<string>;
  special?: TStoreSpecial | StoreSpecial;
  storeId: Id;
}

const getSpecialQualifyingStatus = async ({
  cartUuid,
  special,
  storeId,
}: SpecialQualifyingStatusArgs) => {
  if (!special) {
    return null;
  }

  const qualifyingStatusData = await api.get<QualifyingStatus>(
    `${config.apiPath}/stores/${storeId}/specials/${
      special.id
    }/qualifying_status/${cartUuid ?? 0}`
  );

  return qualifyingStatusData;
};

export const getSpecialsQueryBody = (
  storeId: Id,
  options = { enabled: true }
) => {
  return {
    enabled: options.enabled,
    queryFn: () => getStoreSpecials(storeId),
    queryKey: ['storeSpecials', storeId],
  };
};

export const useGetStoreSpecials = (
  storeId: Id,
  options = { enabled: true }
) => {
  return useQuery(getSpecialsQueryBody(storeId, options));
};

export const useGetSpecialQualifyingStatus = ({
  cartUuid,
  special,
  storeId,
}: SpecialQualifyingStatusArgs) => {
  return useQuery({
    enabled: Boolean(special && cartUuid && storeId),
    queryFn: async () =>
      await getSpecialQualifyingStatus({ storeId, special, cartUuid }),
    queryKey: ['storeSpecialQualifyingStatus', storeId, special, cartUuid],
  });
};
