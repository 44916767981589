import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';
import { useMemo } from 'react';

import type { QualifyingStatus } from '@jane/shared/models';
import {
  Box,
  Card,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { StoreSpecial } from '@jane/shared/types';
import { pluralize } from '@jane/shared/util';

import { DealUnlockedCard } from '../MenuSpecialDetailProgressBar/DealUnlockedCard';
import { ProgressContainer } from '../MenuSpecialDetailProgressBar/MenuSpecialDetailProgressBar.styles';
import { BundleAddCard } from './BundleAddCard';
import { BundleProductCard } from './BundleProductCard';
import { BundleTagCard } from './BundleTagCard';

interface MenuSpecialDetailBundleProgressProps {
  special: StoreSpecial;
  status: QualifyingStatus;
}

export const MenuSpecialDetailBundleProgress = ({
  special,
  status,
}: MenuSpecialDetailBundleProgressProps) => {
  const isMobile = useMobileMediaQuery({});

  const conditions = special.conditions?.bundle;

  const {
    qualified_products = [],
    discounted_products = [],
    qualifying_status,
    discount_total_amount,
  } = status;

  const maxNumberOfDiscountedProducts =
    conditions?.dependent?.max_number_of_discounted_products ?? 0;

  // How many prerequisite products are needed to unlock the special.
  const requiredQualifyingProducts =
    conditions?.independent?.threshold_number_of_items_in_cart ?? 0;

  // If a qualifying product can also be discounted once unlocked.
  const allowQualifiersToBeDiscounted =
    conditions?.settings?.allow_discounts_on_required_products ?? false;

  // If the number of products to unlock the discount is the same as the max number of products,
  // AND the prerequisite products can also be the discounted products.
  const perfectMixAndMatch =
    allowQualifiersToBeDiscounted &&
    isEqual(
      omit(conditions?.dependent, ['max_number_of_discounted_products']),
      omit(conditions?.independent, ['threshold_number_of_items_in_cart'])
    );

  // If prerequisite products can also be discounted, the max number of discounted items
  // needs to take into account the total items required to unlock said discount.
  const actualMaxDiscountedProducts =
    perfectMixAndMatch && !isEmpty(qualified_products)
      ? maxNumberOfDiscountedProducts - requiredQualifyingProducts
      : maxNumberOfDiscountedProducts;

  // discounted products that are also qualified products use the qualified product count.
  const discountedProducts = discounted_products.map((product) => {
    const matchingQualifiedProduct = find(qualified_products, {
      id: product.id,
      price_id: product.price_id,
    });

    return {
      ...product,
      count: matchingQualifiedProduct?.count || product.count,
      discountableIsAlsoQualifier: !!matchingQualifiedProduct,
    };
  });

  const totalQualifiedCount = sumBy(qualified_products, 'count');
  const totalDiscountedCount = sumBy(discountedProducts, 'count');

  const remainingQualifiedProducts =
    requiredQualifyingProducts - totalQualifiedCount;
  const remainingDiscountProducts =
    actualMaxDiscountedProducts - totalDiscountedCount;

  const isApplied = qualifying_status === 'applied';
  const isUnlocked = remainingQualifiedProducts <= 0;

  const showAddCards = remainingQualifiedProducts > 0 && !isApplied;
  const showTagCards = remainingDiscountProducts > 0 && !perfectMixAndMatch;

  const renderAddCards = useMemo(
    () => (count: number) => {
      return Array.from({ length: count }, (_, i) => (
        <BundleAddCard key={`add-qualified-${i}`} isActive={i === 0} />
      ));
    },
    []
  );

  const renderTagCards = useMemo(
    () => (count: number) => {
      return Array.from({ length: count }, (_, i) => (
        <BundleTagCard
          key={`add-discounted-${i}`}
          isActive={Boolean(i === 0 && isUnlocked)}
        />
      ));
    },
    []
  );

  const renderQualifiedProductCards = useMemo(
    () => () => {
      return qualified_products.flatMap((product) =>
        Array.from({ length: product.count }, (_, i) => (
          <BundleProductCard
            key={`${product.id}-${i}`}
            product={product}
            type="qualified"
          />
        ))
      );
    },
    [qualified_products]
  );

  const renderDiscountedProductCards = useMemo(
    () => () => {
      // If a qualifying product can also be a discounted product,
      // we don't display that additional card.
      const discountedProductsToShow = discountedProducts.filter(
        (product) =>
          !allowQualifiersToBeDiscounted || !product.discountableIsAlsoQualifier
      );

      return discountedProductsToShow.flatMap((product) =>
        Array.from({ length: product.count }, (_, i) => (
          <BundleProductCard
            key={`${product.id}-${i}`}
            product={product}
            type="discounted"
          />
        ))
      );
    },
    [allowQualifiersToBeDiscounted, qualified_products, discountedProducts]
  );

  return (
    <ProgressContainer id="progress-container-scroll-position">
      <Card flat width="100%">
        <Card.Content background="grays-ultralight">
          <Box p={isMobile ? 12 : 40}>
            <Flex alignItems="center" flexDirection="column" pb={24}>
              {!isUnlocked && (
                <Typography branded variant="header-bold">
                  Add{' '}
                  {pluralize({
                    noun: 'product',
                    number: remainingQualifiedProducts,
                  })}{' '}
                  to unlock deal
                </Typography>
              )}
              {isUnlocked && (
                <Typography branded variant="header-bold" color="info">
                  Deal unlocked!
                </Typography>
              )}
            </Flex>
            <Flex
              justifyContent="center"
              flexWrap="wrap"
              gap={isMobile ? 16 : 24}
            >
              {renderQualifiedProductCards()}
              {showAddCards && renderAddCards(remainingQualifiedProducts)}
              {renderDiscountedProductCards()}
              {showTagCards && renderTagCards(remainingDiscountProducts)}
            </Flex>

            {isApplied && (
              <Box pt={24}>
                <DealUnlockedCard
                  discountAmount={discount_total_amount as number}
                />
              </Box>
            )}
          </Box>
        </Card.Content>
      </Card>
    </ProgressContainer>
  );
};
