import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutAccordion } from '../../CheckoutAccordion/CheckoutAccordionProvider';
import { useCheckoutInfoIsValid } from './useCheckoutInfoIsValid';
import { useMaybeUpdateUser } from './useMaybeUpdateUser';

export const useCheckoutInfo = () => {
  const maybeUpdateUser = useMaybeUpdateUser();
  const { setExpanded } = useCheckoutAccordion();
  const {
    appInfo: { bloomUserExperience },
  } = useCheckout();

  const handleContinue = () => {
    maybeUpdateUser();
    setExpanded(bloomUserExperience ? 'options' : 'payments');
  };

  const { docsValid, formValid } = useCheckoutInfoIsValid();

  return {
    docsValid,
    formValid,
    handleContinue,
    isValid: formValid && docsValid,
  };
};
