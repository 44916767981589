import { createContext, useContext } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import type { CheckoutNewRequest } from '@jane/shared-ecomm/types';
import type {
  Address,
  AppMode,
  CartLineItems,
  CheckoutAgreementVersion,
  Id,
  Identification,
  RouteAppMode,
} from '@jane/shared/models';
import type { Cart, Store, Tags } from '@jane/shared/types';

import type {
  Customer,
  PreAuthOptions,
  PrepaymentProviders,
} from './checkoutProvider.types';

export interface CheckoutContextType {
  appInfo: {
    appMode: AppMode;
    bloomUserExperience?: boolean;
    checkoutError: string | null;
    disableAuthFeatures?: boolean;
    disablePrepayment?: boolean;
    headlessCheckoutPartnerId?: string;
    headlessLoginUrl?: string;
    isCheckingOut?: boolean;
    isEmbeddedMode: boolean;
    janeDeviceId?: string;
    partnerHostedConfig: {
      isPartnerHosted: boolean;
      partnerHostedPath?: string;
    };
    routeAppMode: RouteAppMode;
  };
  cart: Cart;
  cartLineItems: CartLineItems;
  checkoutRequirements: {
    data: {
      checkout_agreement_versions: CheckoutAgreementVersion[];
      medical_documentation_requirement:
        | 'photo'
        | 'number'
        | 'photo_and_number'
        | 'none'
        | null;
    };
    loading: boolean;
  };
  customer: Customer;
  deliveryAddress: Address;
  deliveryValidation: {
    data: {
      can_deliver: boolean | null;
      fee: number | null;
      last_call_minutes: number | null;
      lead_time_minutes: number | null;
      message: string | null;
      minimum: number | null;
    };
    loading: boolean;
  };
  dispatches: {
    changeReservationMode: (mode: 'delivery' | 'pickup' | 'curbside') => void;
    checkout: (payload: CheckoutNewRequest) => void;
    checkoutCancelled: () => void;
    checkoutError: (error: string) => void;
    checkoutStarted: () => void;
    dismissCheckoutError: () => void;
    openCart: () => void;
    updateIdentification: ({
      document,
      file,
    }: {
      document: keyof Identification;
      file: string | null;
    }) => void;
    updateTip: (value: number) => void;
    updateUser: (values: {
      birthDate?: string;
      firstName?: string;
      lastName?: string;
      phone?: string;
      promotionsOptOut?: boolean;
    }) => void;
  };
  guestCheckout: {
    preAuthSelection: PrepaymentProviders | 'janeGold' | null;
    setPreAuthCustomer: Dispatch<SetStateAction<Partial<Customer>>>;
    setPreAuthOptions: Dispatch<SetStateAction<PreAuthOptions>>;
    setPreAuthSelection: Dispatch<
      SetStateAction<PrepaymentProviders | 'janeGold' | null>
    >;
    setShowLoginModal: Dispatch<SetStateAction<boolean>>;
    showLoginModal: boolean;
  };
  identification: {
    document: Identification;
    isUploading: boolean;
  };
  prepaymentProviders: PrepaymentProviders[];
  store: Store;
  tags: Tags;
  userGroupSpecialId?: Id;
}

const CheckoutContext = createContext<CheckoutContextType>(
  {} as CheckoutContextType
);

interface Props {
  children: ReactNode;
  value: CheckoutContextType;
}

export const CheckoutProvider = ({ children, value }: Props) => {
  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => {
  const context = useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }

  return context;
};
