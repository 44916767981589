import { useSearchContext } from '@jane/search/providers';
import { handleFilterDeselect } from '@jane/search/util';
import { EmptyResults } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { Button, Typography } from '@jane/shared/reefer';

export const MenuEmptyResults = () => {
  const { searchState, setSearchState } = useSearchContext();
  const { paths, store } = useMenu();

  const isFilteringProducts =
    Object.values(searchState).filter(Boolean).length > 0;

  return (
    <EmptyResults>
      {isFilteringProducts ? (
        <>
          <Typography>No products match your search and filter(s)</Typography>
          <Button
            onClick={() => {
              setSearchState(handleFilterDeselect('all', searchState));
              setSearchState({ searchText: '' });
            }}
            label={'Reset search'}
          />
        </>
      ) : (
        <Button to={paths.menu()} label={`Return to ${store.name}`} />
      )}
    </EmptyResults>
  );
};
