import type { RouteObject } from 'react-router-dom';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { FramelessEmbedAppContainer } from '@jane/monolith/boost';
import { Loader } from '@jane/shared/components';
import { load } from '@jane/shared/util';

import MenuSpecialsRedirect from './features/MenuSpecialsRedirect';

const Checkout = Loader(load(() => import('./features/Checkout')));
const EmbeddedCart = Loader(load(() => import('./features/EmbeddedCart')));
const Menu = Loader(
  load(() => import('./features/Menu')),
  'menu'
);
const MenuFeatured = Loader(load(() => import('./features/MenuFeatured')));
const MenuIndex = Loader(load(() => import('./features/MenuIndex')));
const MenuProductDetail = Loader(
  load(() => import('./features/MenuProductDetail')),
  'menu-product-detail'
);
const MenuProducts = Loader(load(() => import('./features/MenuProducts')));
const MenuSpecialDetail = Loader(
  load(() => import('./features/MenuSpecialDetail'))
);
const MenuSpecialDetailRedirect = Loader(
  load(() => import('./features/MenuSpecialDetailRedirect'))
);
const MenuSpecials = Loader(load(() => import('./features/MenuSpecials')));

const EnsureNotLoggedInContainer = Loader(
  load(() => import('./features/EnsureNotLoggedInContainer'))
);
const ForgotPassword = Loader(load(() => import('./features/ForgotPassword')));
const Identification = Loader(load(() => import('./features/Identification')));
const Login = Loader(load(() => import('./features/Login')));
const Logout = Loader(load(() => import('./features/Logout')));
const ProductReview = Loader(load(() => import('./features/ProductReview')));
const Profile = Loader(load(() => import('./features/Profile')));
const ProfileChangePassword = Loader(
  load(() => import('./features/ProfileChangePassword'))
);
const ProfileEdit = Loader(load(() => import('./features/ProfileEdit')));
const Registration = Loader(load(() => import('./features/Registration')));
const ReplaceCart = Loader(load(() => import('./features/ReplaceCart')));

const RequiresAuthentication = Loader(
  load(() => import('./features/RequiresAuthentication'))
);
const ReservationDetail = Loader(
  load(() => import('./features/ReservationDetail'))
);
const ReservationHistory = Loader(
  load(() => import('./features/ReservationHistory'))
);
const ReservationReceipt = Loader(
  load(() => import('./features/ReservationReceipt'))
);

const ResetPassword = Loader(load(() => import('./features/ResetPassword')));

const Root = () => <FramelessEmbedAppContainer />;

const ProfileRoot = () => (
  <RequiresAuthentication>
    <Profile embeddedMode />
  </RequiresAuthentication>
);

const Redirect = ({ isPartnerHosted, basePath }) => {
  const params = useParams();
  const location = useLocation();
  /**
   * Support for legacy deep linking scripts
   * Partners may still be using old scripts, do not remove.
   */
  if (location.pathname.includes('path=')) {
    const adjustedPath = location.pathname.replace('path=', '');
    return <Navigate to={`${adjustedPath}/${location.search}`} />;
  }
  /** ------------------------------------------ */
  return (
    <Navigate
      to={
        isPartnerHosted
          ? `${basePath}/menu/${location.search}`
          : `/embed/stores/${params['store_id']}/menu/${location.search}`
      }
    />
  );
};

export const buildRoutes = (
  isPartnerHosted,
  partnerHostedPath
): RouteObject[] => {
  if (!isPartnerHosted) return [];

  return [
    {
      path: `${partnerHostedPath}/`,
      element: <Root />,
      children: [
        {
          path: `${partnerHostedPath}/`,
          element: <Menu />,
          children: [
            {
              index: true,
              element: <MenuIndex />,
            },
          ],
        },
        {
          path: `${partnerHostedPath}/menu`,
          element: <Menu />,
          children: [
            {
              index: true,
              element: <MenuIndex />,
            },
            {
              path: 'featured',
              element: <MenuFeatured restoreScroll />,
            },
            {
              path: ':category',
              element: <MenuProducts restoreScroll />,
            },
            {
              path: ':category/:featured_category',
              element: <MenuProducts restoreScroll />,
            },
            {
              path: 'specials',
              element: <MenuSpecials restoreScroll />,
            },
            {
              path: 'specials/:special_id/:slug',
              element: <MenuSpecialDetail />,
            },
          ],
        },
        {
          path: `${partnerHostedPath}/menu/products/:product_id/:slug`,
          element: <MenuProductDetail />,
        },
        {
          path: `${partnerHostedPath}/products/:product_id/:slug`,
          element: <MenuProductDetail />,
        },
        {
          path: `${partnerHostedPath}/products/:product_id/:slug/reviews/new`,
          element: (
            <RequiresAuthentication>
              <ProductReview />
            </RequiresAuthentication>
          ),
        },
        {
          path: `${partnerHostedPath}/logout`,
          element: <Logout />,
        },
        {
          path: `${partnerHostedPath}/cart`,
          element: <EmbeddedCart />,
        },
        {
          path: `${partnerHostedPath}/cart/checkout`,
          element: (
            <RequiresAuthentication>
              <Checkout />
            </RequiresAuthentication>
          ),
        },
        {
          path: `${partnerHostedPath}/cart/guest_checkout`,
          element: <Checkout />,
        },
        {
          path: `${partnerHostedPath}/reservation_receipt/:reservation_id`,
          element: <ReservationReceipt />,
        },
        {
          path: `${partnerHostedPath}/replaceCart`,
          element: <ReplaceCart />,
        },
        {
          path: `${partnerHostedPath}/login`,
          element: (
            <EnsureNotLoggedInContainer>
              <Login />
            </EnsureNotLoggedInContainer>
          ),
        },
        {
          path: `${partnerHostedPath}/register`,
          element: (
            <EnsureNotLoggedInContainer>
              <Registration />
            </EnsureNotLoggedInContainer>
          ),
        },
        {
          path: `${partnerHostedPath}/forgot-password`,
          element: <ForgotPassword />,
        },
        {
          path: `${partnerHostedPath}/reset-password/:token`,
          element: <ResetPassword />,
        },
        {
          path: `${partnerHostedPath}/specials`,
          element: <MenuSpecialsRedirect />,
        },
        {
          path: `${partnerHostedPath}/specials/:special_id`,
          element: <MenuSpecialDetailRedirect />,
        },
        {
          path: `${partnerHostedPath}/specials/:special_id/:slug`,
          element: <MenuSpecialDetailRedirect />,
        },
        {
          path: `${partnerHostedPath}/profile`,
          element: <ProfileRoot />,
          children: [
            {
              path: `${partnerHostedPath}/profile/reservations`,
              element: <ReservationHistory />,
            },
            {
              path: `${partnerHostedPath}/profile/reservations/:reservation_id`,
              element: <ReservationDetail />,
            },
            {
              path: `${partnerHostedPath}/profile/identification`,
              element: <Identification />,
            },
            {
              path: `${partnerHostedPath}/profile/edit`,
              element: <ProfileEdit />,
            },
            {
              path: `${partnerHostedPath}/profile/password`,
              element: <ProfileChangePassword />,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: (
        <Redirect
          isPartnerHosted={isPartnerHosted}
          basePath={partnerHostedPath}
        />
      ),
    },
  ];
};
