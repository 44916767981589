import type { SmartSortProduct } from '@iheartjane/dm-sdk';

import type { AlgoliaProduct } from '@jane/search/types';
import type {
  PendingCartProduct,
  ProductWeight,
  QualifyingStatus,
} from '@jane/shared/models';
import type { MenuProduct, PriceId, StoreSpecial } from '@jane/shared/types';
import {
  PRICE_ID_TO_GRAMS,
  WEIGHT_OPTIONS,
  isSoldByWeight,
} from '@jane/shared/util';

export const getSpecialType = (
  specialType?: string,
  qualifyingStatus?: string | null
) => {
  switch (specialType) {
    case 'cart_total': {
      return 'cart_total_special_ids';
    }
    case 'bulk_pricing': {
      return 'bulk_special_ids';
    }
    case 'bundle': {
      return qualifyingStatus === 'applied' || qualifyingStatus === 'qualified'
        ? 'bundle_special_ids.discount'
        : 'bundle_special_ids.qualify';
    }
    default: {
      return 'special_ids';
    }
  }
};

export const getApplicablePriceIds = (
  special?: StoreSpecial,
  qualifyingStatus?: QualifyingStatus['qualifying_status']
) => {
  const { special_type } = special || {};

  if (special_type === 'bundle') {
    const isQualified = ['qualified', 'applied'].includes(
      String(qualifyingStatus)
    );

    return isQualified
      ? special?.conditions?.bundle?.dependent?.weights
      : special?.conditions?.bundle?.independent?.weights;
  }

  if (special_type === 'bulk_pricing') {
    return (special?.conditions?.bulk_pricing?.bulk_weights || []).map(
      ({ price_id }) => price_id
    );
  }

  if (special_type === 'product' || special_type === 'cart_total') {
    const includesWeights = special?.rules?.includes?.[0]?.weights || [];
    const excludesWeights = special?.rules?.excludes?.[0]?.weights || [];

    return includesWeights.length > 0
      ? includesWeights.filter((weight) => !excludesWeights.includes(weight))
      : WEIGHT_OPTIONS.map(({ id }) => id).filter(
          (weight) => !excludesWeights.includes(weight as ProductWeight)
        );
  }

  return null;
};

const productInCart = (
  cartProducts: PendingCartProduct[],
  productId: number,
  priceId?: PriceId
) => {
  return cartProducts?.some(
    (cartProduct) =>
      cartProduct.product_id === productId &&
      (!priceId || cartProduct.price_id === priceId)
  );
};

interface ShouldShowWeight {
  cartProducts?: PendingCartProduct[];
  currentSpecial?: StoreSpecial;
  qualifyingStatus?: QualifyingStatus | null;
  showOnlyWeights?: string[];
}

/**
 * Creates a weight filtering function based on special conditions.
 * Returns a function that determines whether a specific weight should be shown,
 * handling cases like cart status, qualifying status, bundle conditions, and price thresholds.
 * The returned function maintains closure over the provided special conditions, cart state,
 * and qualifying status, allowing efficient reuse across multiple weight checks.
 *
 * Handles different special types:
 * - Bulk pricing: Checks weight limits, price thresholds, and remaining target weight
 * - Bundle: Uses dependent/independent weights based on qualification status
 * - Product/Cart Total: Filters based on allowed weights and price thresholds
 */
export const shouldShowWeight = ({
  cartProducts = [],
  currentSpecial,
  qualifyingStatus,
  showOnlyWeights,
}: ShouldShowWeight) => {
  return (weight: string, product: MenuProduct | AlgoliaProduct) => {
    if (!currentSpecial) return true;

    const productId = 'id' in product ? product.id : product.product_id;
    const weightId = weight.replace(' ', '_') as ProductWeight;

    const stringPrice = product[`price_${weightId}`];
    const stringDiscountedPrice = product[`discounted_price_${weightId}`];
    const price = stringPrice && parseFloat(stringPrice);
    const discountedPrice =
      stringDiscountedPrice && parseFloat(stringDiscountedPrice);

    if (productInCart(cartProducts, productId, weightId)) return true;

    if (showOnlyWeights?.length && !showOnlyWeights.includes(weightId)) {
      return false;
    }

    if ((stringDiscountedPrice && discountedPrice === 0) || !price)
      return false;

    if (['product', 'bundle'].includes(currentSpecial?.special_type || '')) {
      // Handle product/bundle conditions
      const isQualifiedBundle = ['qualified', 'applied'].includes(
        qualifyingStatus?.qualifying_status || ''
      );
      const specialCondition =
        currentSpecial?.special_type === 'product'
          ? currentSpecial.conditions?.product
          : isQualifiedBundle
          ? currentSpecial.conditions?.bundle?.dependent
          : currentSpecial.conditions?.bundle?.independent;

      if (specialCondition) {
        const { weights: productWeights, product_threshold } = specialCondition;

        if (productWeights?.length && !productWeights.includes(weightId)) {
          return false;
        }

        if (product_threshold) {
          const { minimum_price, maximum_price } = product_threshold;
          if (minimum_price && price < minimum_price) return false;
          if (maximum_price && price > maximum_price) return false;
        }
      }

      return true;
    }

    // Handle bulk pricing conditions
    const cartWeight = qualifyingStatus?.product_weight ?? 0;
    const targetWeight = qualifyingStatus?.target_weight;
    if (
      targetWeight &&
      PRICE_ID_TO_GRAMS[weightId] > targetWeight - cartWeight
    ) {
      return false;
    }

    const bulkWeights =
      currentSpecial?.conditions?.bulk_pricing?.bulk_weights || [];
    if (!bulkWeights.length) return true;

    const weightCondition = bulkWeights.find((w) => w.price_id === weightId);
    if (!weightCondition) return false;

    const { minimum_price, maximum_price } = weightCondition;
    if (minimum_price && price < minimum_price) return false;
    if (maximum_price && price > maximum_price) return false;

    return true;
  };
};

/**
 * Filters out products that would have no valid weights after applying special conditions.
 * Products in cart are always kept regardless of weight restrictions.
 */
export const filterProductsByWeight = ({
  cartProducts = [],
  currentSpecial,
  products,
  qualifyingStatus,
  showOnlyWeights,
}: ShouldShowWeight & {
  products: SmartSortProduct<AlgoliaProduct>[];
}): SmartSortProduct<AlgoliaProduct>[] => {
  if (!currentSpecial) return products;

  const weightFilter = shouldShowWeight({
    currentSpecial,
    cartProducts,
    qualifyingStatus,
    showOnlyWeights,
  });

  return products.filter((product) => {
    const productKind = product.attributes['kind'];

    if (!isSoldByWeight(productKind)) return true;

    const productId = product.attributes['product_id'];

    const availableWeights = product.attributes['available_weights'];

    if (productInCart(cartProducts, productId)) return true;

    return (availableWeights || []).some((weight: string) =>
      weightFilter(weight, product.attributes)
    );
  });
};
