import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCanPayIntent } from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { trackError } from '@jane/shared/util';

import type { CanPayIntent } from '../CheckoutPaymentsProvider';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

declare const canpay: {
  init: (payload: object) => void;
};

export const useCanPay = () => {
  const {
    cart: { uuid },
    cartLineItems,
    dispatches: { checkoutError },
  } = useCheckout();
  const { control } = useFormContext();
  const phone = useWatch({
    control,
    name: 'customerInformation.phone',
  });

  const { updatePrepayments } = useCheckoutPayments();
  const { data: canPayIntentData, isLoading } = useCanPayIntent(
    {
      amount: cartLineItems.total,
      cartUuid: uuid,
    },
    {
      onError: (err: unknown) => {
        checkoutError(
          'Something went wrong with CanPay, please try again. If the problem persists, please contact us at support@iheartjane.com'
        );
        trackError(
          new Error('Unable to launch CanPay V2-RemotePay checkout.'),
          {
            canpayError: err,
          }
        );
      },
    }
  );

  const handleProcessed = (data: CanPayIntent) => {
    updatePrepayments({ canPayIntent: data });
  };

  const handleOpenWidget = useCallback(() => {
    if (canPayIntentData?.intent_id) {
      const canpayPayload = {
        intent_id: canPayIntentData.intent_id,
        amount: canPayIntentData.amount,
        tip_amount: 0.0,
        original_merchant: '',
        is_guest: 'true',
        passthrough: {
          cart: canPayIntentData.cart_uuid,
          phone_last_four: phone ? phone.slice(-4) : '',
        },
        auth_id: '',
        need_modification_url: 'true',
        login_callback: undefined,
        link_callback: undefined,
        processed_callback: (canpay_response: {
          data: Omit<CanPayIntent, 'intent_id'>;
        }) => {
          handleProcessed({
            intent_id: canPayIntentData.intent_id,
            ...canpay_response.data,
          });
        },
      };

      canpay.init(canpayPayload);
    }
  }, [canPayIntentData, phone]);

  return {
    openWidget: handleOpenWidget,
    widgetLoaded: !!canPayIntentData?.intent_id && !isLoading,
  };
};
