import { useEffect, useState } from 'react';

import {
  ImageCommsBanner,
  TextCommsBanner,
  cleanBannerText,
} from '@jane/shared-ecomm/components';
import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';
import { useMenu } from '@jane/shared-ecomm/providers';
import {
  trackOpenedAccordionItem,
  trackStoreCommunicationBannerLoad,
} from '@jane/shared-ecomm/tracking';
import { useStoreCommsBanner } from '@jane/shared/data-access';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuBanner } from '../MenuBanner';
import { hasDismissedModal, setDismissedModal } from './MenuCommsBanner.util';

export const MenuCommsBanner = () => {
  const isMobile = useMobileMediaQuery({});
  const [showModal, setShowModal] = useState(false);
  useHandleIframeModal(showModal);

  const {
    appInfo: { storeId },
  } = useMenu();

  const { data: commsBanner, isFetching: isLoading } = useStoreCommsBanner(
    storeId || ''
  );

  const isImage = commsBanner?.banner_type === 'image';
  const isText = commsBanner?.banner_type === 'text';

  const bannerIsEnabled = commsBanner?.enabled;
  const bannerHasContent =
    isImage ||
    Boolean(
      commsBanner?.title?.trim().length && commsBanner?.message?.trim().length
    );

  const textBannerText =
    isText && bannerHasContent
      ? cleanBannerText({
          title: commsBanner.title || '',
          message: commsBanner.message || '',
        })
      : undefined;

  // biz admin wysiwyg will send empty `<p> </p>` tags, this checks if a string exists within the tags
  const textBannerContent = isText
    ? !!textBannerText?.split(':')[1]?.trim().length
    : true;

  const defaultOpen = Boolean(
    bannerIsEnabled &&
      bannerHasContent &&
      isText &&
      !!textBannerText?.split(':')[1].trim().length &&
      commsBanner.is_expanded &&
      storeId &&
      !hasDismissedModal(storeId, commsBanner.updated_at)
  );

  const handleShowModal = (show: boolean) => {
    if (show) {
      trackOpenedAccordionItem('communicationBanner');
    } else {
      storeId && setDismissedModal(storeId);
    }
    setShowModal(show);
  };

  useEffect(() => {
    if (defaultOpen) {
      setShowModal(true);
    }
  }, [defaultOpen]);

  useEffect(() => {
    if (!isLoading && !!commsBanner) {
      trackStoreCommunicationBannerLoad({
        bannerEnabled: commsBanner.enabled,
        bannerMessage: commsBanner.message || null,
        isExpanded: commsBanner.is_expanded,
        storeId: commsBanner.store_id,
      });
    }
  }, [commsBanner, isLoading]);

  if (
    (!isLoading && !commsBanner) ||
    !bannerIsEnabled ||
    !bannerHasContent ||
    !textBannerContent
  )
    return null;

  return (
    <MenuBanner isLoading={isLoading} isImage={isImage}>
      {isImage && (
        <ImageCommsBanner
          buttonFillColor={`#${commsBanner?.button_fill_color}`}
          buttonLink={commsBanner?.button_link}
          buttonText={commsBanner?.button_text}
          buttonTextColor={`#${commsBanner?.button_text_color}`}
          fallbackSrc={commsBanner?.fallbackSrc}
          headerTextColor={`#${commsBanner?.header_text_color}`}
          imageAltText={commsBanner?.alt_text}
          imageOpacity={commsBanner?.image_opacity}
          imageUrl={commsBanner?.image?.file?.url}
          message={commsBanner?.message}
          sizes={commsBanner?.sizes}
          srcSet={commsBanner?.srcSet}
          title={commsBanner?.title}
        />
      )}
      {isText && (
        <TextCommsBanner
          bannerText={textBannerText}
          title={commsBanner?.title}
          message={commsBanner?.message}
          isMobile={isMobile}
          handleShowModal={handleShowModal}
          showModal={showModal}
        />
      )}
    </MenuBanner>
  );
};
