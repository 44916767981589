import { postMessageToIframeParent } from '@jane/shared/util';

import { inIframe } from './inIframe';

export const scrollToTop = () => {
  const isInIframe = inIframe();

  return isInIframe
    ? postMessageToIframeParent({
        messageType: 'scrollToTop',
        payload: 'smooth',
      })
    : window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
};
