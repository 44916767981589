import type { SearchSort } from '@iheartjane/dm-sdk';
import {
  bestSellerRank,
  categoryAndNameAsc,
  categoryAndNameDesc,
  defaultOrder,
  priceAsc,
  priceDesc,
  priceEighthOunceAsc,
  priceEighthOunceDesc,
  priceGramAsc,
  priceGramDesc,
  priceHalfGramAsc,
  priceHalfGramDesc,
  priceHalfOunceAsc,
  priceHalfOunceDesc,
  priceOunceAsc,
  priceOunceDesc,
  priceQuarterOunceAsc,
  priceQuarterOunceDesc,
  priceTwoGramAsc,
  priceTwoGramDesc,
  rating,
  recommendation,
  specialAndReview,
  thcPotencyAsc,
  thcPotencyDesc,
} from '@iheartjane/dm-sdk';

import type { CurrentSort } from '@jane/search/types';

export const mapCurrentSortToSearchSort = (
  currentSort: CurrentSort | undefined
): SearchSort => {
  const suffix = currentSort?.suffix || '';
  switch (suffix) {
    case 'by-category-and-name-':
      return categoryAndNameAsc;
    case 'by-category-and-name-desc-':
      return categoryAndNameDesc;
    case 'by-price-':
      return priceAsc;
    case 'by-price-desc-':
      return priceDesc;
    case 'by-price-eighth-ounce-':
      return priceEighthOunceAsc;
    case 'by-price-eighth-ounce-desc-':
      return priceEighthOunceDesc;
    case 'by-price-gram-':
      return priceGramAsc;
    case 'by-price-gram-desc-':
      return priceGramDesc;
    case 'by-price-half-gram-':
      return priceHalfGramAsc;
    case 'by-price-half-gram-desc-':
      return priceHalfGramDesc;
    case 'by-price-half-ounce-':
      return priceHalfOunceAsc;
    case 'by-price-half-ounce-desc-':
      return priceHalfOunceDesc;
    case 'by-price-ounce-':
      return priceOunceAsc;
    case 'by-price-ounce-desc-':
      return priceOunceDesc;
    case 'by-price-quarter-ounce-':
      return priceQuarterOunceAsc;
    case 'by-price-quarter-ounce-desc-':
      return priceQuarterOunceDesc;
    case 'by-price-two-gram-':
      return priceTwoGramAsc;
    case 'by-price-half-two-desc-':
      return priceTwoGramDesc;
    case 'by-rating-':
      return rating;
    case 'by-recommendation-ordinal-asc-':
      return recommendation;
    case 'by-special-and-review-':
      return specialAndReview;
    case 'by-thc-potency-asc-':
      return thcPotencyAsc;
    case 'by-thc-potency-desc-':
      return thcPotencyDesc;
    case 'best-seller-rank':
      return bestSellerRank;
    default:
      return defaultOrder;
  }
};
