import { useCallback, useEffect, useRef, useState } from 'react';

import { useMonerisPreload } from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { config } from '@jane/shared/config';
import { trackError } from '@jane/shared/util';

import { useCheckoutPayments } from '../CheckoutPaymentsProvider';

interface UseMonerisProps {
  onCancel?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  onSuccess?: (ticket: string) => void;
}

export const MONERIS_WIDGET_DIV = 'monerisCheckout';

interface WidgetProps {
  closeCheckout: (ticket: string) => void;
  startCheckout: (ticket: string) => void;
}

export const useMoneris = ({
  onCancel,
  onError,
  onLoad,
  onSuccess,
}: UseMonerisProps) => {
  const [loaded, setLoaded] = useState(false);
  const {
    cartLineItems: { total },
    dispatches: { checkoutError },
    store: { id },
  } = useCheckout();
  const { updatePrepayments } = useCheckoutPayments();
  const { data: monerisPreloadData } = useMonerisPreload(
    {
      storeId: String(id),
      amount: total.toFixed(2).toString(),
    },
    { onError: () => checkoutError('Could not authorize Moneris') }
  );

  const widgetRef = useRef<WidgetProps>();

  const handleSuccess = () => {
    onSuccess && onSuccess(monerisPreloadData?.ticket || '');
  };

  const handleError = (e: { response_code: string }) => {
    updatePrepayments({ monerisTicket: '' });
    onError && onError();
    checkoutError(
      'Unable to charge credit or debit card. Please try another payment option.'
    );
    trackError(new Error(`Moneris Payments Error, code: ${e.response_code}`));
  };

  const handleCancel = () => {
    updatePrepayments({ monerisTicket: '' });
    document.body.style.overflow = 'unset';
    onCancel && onCancel();
  };

  const handleLoad = () => {
    setLoaded(true);
    onLoad && onLoad();
  };

  useEffect(() => {
    if (monerisPreloadData?.ticket) {
      const typedWindow = window as any;
      if (!typedWindow.monerisCheckout) {
        trackError(
          new Error('Moneris Web Payments SDK failed to load properly')
        );
      } else {
        const monerisPay = new typedWindow.monerisCheckout();
        monerisPay.setMode(config.monerisEnv);
        monerisPay.setCheckoutDiv('monerisCheckout');
        monerisPay.setCallback('page_loaded', handleLoad);
        monerisPay.setCallback('cancel_transaction', handleCancel);
        monerisPay.setCallback('error_event', handleError);
        monerisPay.setCallback('payment_receipt', handleSuccess);
        monerisPay.setCallback('payment_complete', handleSuccess);
        widgetRef.current = monerisPay;
        setLoaded(true);
      }
    }
  }, [monerisPreloadData?.ticket]);

  const handleOpenWidget = useCallback(() => {
    if (loaded && monerisPreloadData?.ticket) {
      document.body.style.overflow = 'hidden';
      (widgetRef.current as WidgetProps).startCheckout(
        monerisPreloadData.ticket
      );
    }
  }, [loaded, monerisPreloadData?.ticket]);

  const handleCloseWidget = useCallback(() => {
    if (loaded && monerisPreloadData?.ticket) {
      document.body.style.overflow = 'unset';
      (widgetRef.current as WidgetProps).closeCheckout(
        monerisPreloadData.ticket
      );
    }
  }, [loaded, monerisPreloadData?.ticket]);

  return {
    closeWidget: handleCloseWidget,
    openWidget: handleOpenWidget,
    widgetLoaded: loaded,
  };
};
