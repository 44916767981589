import { Prop65Warnings } from '@jane/shared-ecomm/components';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, Flex, useDesktopMediaQuery } from '@jane/shared/reefer';

import { InlineBlockTypography, InlineLink } from './checkoutTerms.styles';

export const CheckoutTerms = () => {
  const { store } = useCheckout();
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });

  const showProp65 = store?.state === 'California';

  return (
    <Flex flexDirection="column" p={isDesktop ? 40 : 24} pt={0} pb={40}>
      <InlineBlockTypography as="div" textAlign="center" variant="mini">
        This site is protected by reCAPTCHA and the&nbsp;
        <InlineLink href="https://policies.google.com/privacy">
          Google Privacy Policy
        </InlineLink>
        &nbsp;and&nbsp;
        <InlineLink href="https://policies.google.com/terms">
          Terms of Service apply.
        </InlineLink>
      </InlineBlockTypography>

      <InlineBlockTypography as="div" textAlign="center" variant="mini" mt={8}>
        By continuing, I agree to&nbsp;
        <InlineLink href="https://www.iheartjane.com/terms">
          Jane’s Terms of Service
        </InlineLink>
        &nbsp;and&nbsp;
        <InlineLink href="https://www.iheartjane.com/privacy-policy">
          Privacy Policy.
        </InlineLink>
      </InlineBlockTypography>

      {showProp65 && (
        <Box pt={24}>
          <Prop65Warnings color="text-light" />
        </Box>
      )}
    </Flex>
  );
};
