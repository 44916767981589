import type { CanPayIntentProps } from './canPay.queries';

export const urls = {
  canPayIntent: '/can_pay/v2_remote_pay/intent',
};

const queryKeyBase = ['canPay'];

export const queryKeys = {
  canPayIntent: ({ amount, cartUuid }: CanPayIntentProps) => [
    ...queryKeyBase,
    'intent',
    amount,
    cartUuid,
  ],
};
