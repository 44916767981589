import kebabCase from 'lodash/kebabCase';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useGetStoreSpecials } from '@jane/shared-ecomm/data-access';
import { Loading } from '@jane/shared/reefer';
import type { StoreSpecial } from '@jane/shared/types';

export const MenuSpecialDetailRedirect = () => {
  const location = useLocation();
  const { store_id: storeId, special_id: specialId, slug } = useParams();

  const { data: specials = [], isFetched } = useGetStoreSpecials(storeId || 0, {
    enabled: !slug,
  });

  const currentPath = location.pathname.split('/specials');
  const withSlugUrl = `${currentPath[0]}/menu/specials/${currentPath[1]}`;

  if (slug) return <Navigate to={withSlugUrl} replace />;

  const currentSpecial = specials.find(
    (special: StoreSpecial) => String(special.id) === specialId
  );

  if (!isFetched) return <Loading size="lg" />;

  const specialsUrl = `${currentPath[0]}/menu/specials`;

  if (isFetched && !currentSpecial)
    return <Navigate to={specialsUrl} replace />;

  const addSlugToUrl = `${currentPath[0]}/menu/specials${
    currentPath[1]
  }/${kebabCase(currentSpecial?.title)}`;

  return <Navigate to={addSlugToUrl} replace />;
};
