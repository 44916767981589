import { useEffect } from 'react';

import { PaymentMethod } from '@jane/shared/models';

import { useCheckoutPayments } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import { useSubmitCheckout } from './useSubmitCheckout';

// This is essentially a payments spy. When certain tokens/authorizations are captured,
// we automatically submit the checkout.
export const useAutoSubmitCheckout = () => {
  const { selected, aeropayPreauthorizationId, canPayIntent, monerisTicket } =
    useCheckoutPayments();
  const { submit } = useSubmitCheckout();

  const autoSubmit =
    (selected === PaymentMethod.aeropay && !!aeropayPreauthorizationId) ||
    (selected === PaymentMethod.canpay && !!canPayIntent) ||
    (selected === PaymentMethod.moneris && !!monerisTicket);

  useEffect(() => {
    if (autoSubmit) {
      submit();
    }
  }, [aeropayPreauthorizationId, canPayIntent, monerisTicket]);
};
