import { JaneGoldCallout } from '@jane/shared-ecomm/components';
import { useAuthentication, useCheckout } from '@jane/shared-ecomm/providers';

import { CheckoutStep } from '../checkout.styles';

export const CheckoutJaneGoldCallout = ({
  headlessLoginUrl,
  hideJanePayBranding,
  isSummary,
}: {
  headlessLoginUrl?: string;
  hideJanePayBranding?: boolean;
  isSummary?: boolean;
}) => {
  const { defaultLogin, ...authentication } = useAuthentication();
  const {
    cart: {
      brand_discounts_total,
      brand_discounts_service_fee_total,
      brand_discounts_total_with_fee,
    },
    customer: { authenticated },
    guestCheckout: { preAuthSelection, setPreAuthSelection },
  } = useCheckout();

  if (!brand_discounts_total) return null;

  if (isSummary) {
    return (
      <JaneGoldCallout
        authentication={{
          ...authentication,
          login: defaultLogin,
        }}
        discountLabel={brand_discounts_total}
        onLinkAccountClick={() => {
          setPreAuthSelection('janeGold');
        }}
        variant="checkoutSummary"
      />
    );
  }

  return (
    <CheckoutStep width="100%">
      <JaneGoldCallout
        authentication={{
          ...authentication,
          login: defaultLogin,
        }}
        defaultOpen={authenticated && preAuthSelection === 'janeGold'}
        discountLabel={brand_discounts_total}
        feeLabel={brand_discounts_service_fee_total}
        headlessLoginUrl={headlessLoginUrl}
        hideJanePayBranding={hideJanePayBranding}
        onLinkAccountClick={() => {
          if (!authenticated) {
            setPreAuthSelection('janeGold');
          }
          if (authenticated && preAuthSelection === 'janeGold') {
            setPreAuthSelection(null);
          }
        }}
        variant="checkout"
        totalLabel={brand_discounts_total_with_fee}
      />
    </CheckoutStep>
  );
};
