import { postMessageToIframeParent } from '@jane/shared/util';

import {
  showErrorNotification,
  showSuccessNotification,
} from '../common/redux/application';

let dispatch = () => undefined;

export const setNotificationsDispatch = (newDispatch) => {
  dispatch = newDispatch;
};

export const NotificationsService = {
  success: (message) => {
    dispatch(showSuccessNotification(message));
  },
  error: (errorOrMessage) => {
    const message =
      typeof errorOrMessage === 'string'
        ? errorOrMessage
        : 'Something went wrong';
    dispatch(showErrorNotification(message));
  },
  notifyErrorToIFrameParent: (error, source, messageType) => {
    postMessageToIframeParent({
      messageType,
      payload: {
        name: messageType,
        properties: {
          error,
          source,
        },
      },
    });
  },
};
