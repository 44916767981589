import { useSearchContext } from '@jane/search/providers';
import {
  Box,
  DismissFilledIcon,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { PaddingProperties } from '@jane/shared/reefer';

import { BrandTitle } from './BrandTitle';
import { DefaultTitle } from './DefaultTitle';
import { JaneGoldTitle } from './JaneGoldTitle';

export interface MenuTitleProps extends PaddingProperties {
  description?: string;
  title?: string;
  variant?: 'default' | 'brand' | 'gold';
}

export const MenuTitle = ({
  variant = 'default',
  title,
  description,
  ...props
}: MenuTitleProps) => {
  const isMobile = useMobileMediaQuery({});

  const noTitle = variant === 'default' && !title;

  const {
    searchState: { searchText },
    setSearchState,
  } = useSearchContext();

  const handleClearQuery = () => {
    setSearchState({ searchText: '' });
  };

  return (
    <Box {...props}>
      {variant === 'default' && (
        <DefaultTitle title={title} description={description} />
      )}

      {variant === 'gold' && <JaneGoldTitle />}

      {variant === 'brand' && <BrandTitle />}

      {!!searchText && (
        <Flex
          pt={!noTitle ? (isMobile ? 24 : 48) : 0}
          pb={noTitle ? (isMobile ? 24 : 48) : 0}
          alignItems="center"
        >
          <Typography as="h2" branded variant="header-bold" mr={8}>
            Search for &#34;{searchText}&#34;
          </Typography>
          <DismissFilledIcon
            onClick={handleClearQuery}
            altText="Clear search"
            size="md"
          />
        </Flex>
      )}
    </Box>
  );
};
