import styled from '@emotion/styled';
import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import omit from 'lodash/omit';
import { useCallback } from 'react';
import { InView } from 'react-intersection-observer';

import type { AlgoliaProduct } from '@jane/search/types';
import {
  QuickAddCard,
  buildClickTrackingEvent,
} from '@jane/shared-ecomm/components';
import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import {
  useMenu,
  useSpecials,
  useUserPreferences,
} from '@jane/shared-ecomm/providers';
import { track, trackProductListingTap } from '@jane/shared-ecomm/tracking';
import { SponsoredTag } from '@jane/shared/components';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { MenuProduct, StoreSpecial } from '@jane/shared/models';
import type { Breadcrumb, PriceId } from '@jane/shared/types';
import {
  deserializeCannabinoids,
  findSpecialForProductPriceId,
  getJaneGoldTagLabel,
  getSpecialTagLabel,
} from '@jane/shared/util';

const InViewFlex = styled(InView)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

interface MenuQuickAddCardProps {
  algoliaIndexName?: string;
  breadcrumbs?: Breadcrumb[];
  index: number;
  priceId: PriceId;
  product: SmartSortProduct<AlgoliaProduct>;
}
export const MenuQuickAddCard = ({
  algoliaIndexName,
  breadcrumbs,
  index,
  priceId,
  product,
}: MenuQuickAddCardProps) => {
  const {
    appInfo: { appMode, janeDeviceId },
    cart,
    dispatches: { attemptToAddToCart },
    paths,
    store,
  } = useMenu();
  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const userSegments = useUserSegmentIds();

  const menuRowName = 'Buy again';
  const productLocation = `featured/${menuRowName}`;

  const { specials } = useSpecials();
  const { userLocation, userPreferences = {} } = useUserPreferences();
  const shouldShowGold = useShouldShowGold({ product: product.attributes });

  const cartProduct = cart.products.find(
    (cartProduct) => cartProduct.product_id === product.attributes['product_id']
  );

  /**
   * This is to bring this card/product into parity with
   * MenuProductCard/ProductCardWithTracking
   */
  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(product.attributes, 'menuProduct'),
      id: product.attributes.product_id,
    };
    return omit(mp, 'product_id') as MenuProduct;
  }, [product]);

  const pdpUrl = paths.menuProduct(menuProduct);

  const applicableSpecial = findSpecialForProductPriceId(
    menuProduct,
    specials as StoreSpecial[],
    appMode,
    priceId
  );
  const specialTagLabel = getSpecialTagLabel({
    defaultWeight: priceId,
    product: menuProduct,
    store,
  });
  const specialApplies = applicableSpecial && specialTagLabel;

  const janeGoldLabel =
    shouldShowGold &&
    getJaneGoldTagLabel({
      menuProduct: menuProduct,
      priceId,
    });

  /** Tracking handlers */
  const handleAddToCart = () => {
    attemptToAddToCart({
      columnPosition: index,
      count: cartProduct ? cartProduct.count + 1 : 1,
      location: 'menu',
      menuProduct,
      productLocation,
      price_id: priceId,
      rowPosition: 1,
      store,
    });
  };

  const clickTrackingEvent = buildClickTrackingEvent({
    indexName: algoliaIndexName || '',
    store,
    menuProduct,
    carouselView: true,
    cardIndex: index,
    listView: false,
    menuRowName,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const handleTrackImpression = useCallback(
    async (isInView: boolean) => {
      if (isInView) {
        product.impress();
      }
    },
    [product]
  );

  const handleOnClick = useCallback(() => {
    const { city, state, name, id } = store;

    const { storeFulfillmentType, storeSearchRadius, storeType } =
      userPreferences;

    product.click();

    clickTrackingEvent && track(clickTrackingEvent);
    trackProductListingTap({
      cityState: userLocation?.cityState,
      productLocation,
      product: menuProduct,
      source: 'menu',
      storeId: String(id),
      storeName: name,
      storeCity: city || undefined,
      storeState: state || undefined,
      storeFulfillmentType,
      storeSearchRadius,
      storeType,
      zipcode: userLocation?.zipcode,
    });
  }, [
    clickTrackingEvent,
    janeDeviceId,
    menuProduct,
    product,
    productLocation,
    store,
    userLocation?.cityState,
    userLocation?.zipcode,
    userPreferences,
  ]);

  return (
    <InViewFlex
      data-testid="buy-again-card-with-tracking"
      delay={1000}
      onChange={(isInView) => handleTrackImpression(isInView)}
      threshold={0.5}
      triggerOnce
    >
      <QuickAddCard
        addToCart={handleAddToCart}
        breadcrumbs={breadcrumbs}
        cartCount={cartProduct?.count}
        hasApplicableSpecial={!!applicableSpecial}
        janeGoldLabel={janeGoldLabel || ''}
        onClick={handleOnClick}
        pdpUrl={pdpUrl}
        priceId={priceId}
        product={menuProduct}
        specialLabel={specialApplies ? specialTagLabel : ''}
      />
      <SponsoredTag hideText={!product.isSponsored} mt={8} />
    </InViewFlex>
  );
};
