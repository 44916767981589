import { useState } from 'react';

import { useMenu } from '@jane/shared-ecomm/providers';
import { getReservationModeLabels } from '@jane/shared-ecomm/util';
import {
  Box,
  Hide,
  Link,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { StoreHours } from '@jane/shared/types';
import {
  getAllTodaysScheduleHours,
  getTodaysScheduleHours,
} from '@jane/shared/util';

import { StyledGrid } from './MenuHeader.styles';
import { MenuSchedule } from './MenuSchedule';

export const MenuHours = ({ inModal = true }: { inModal?: boolean }) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const isMobile = useMobileMediaQuery({});
  const {
    store: {
      allow_off_hours_ordering,
      curbside_hours,
      delivery_hours,
      pickup_hours,
      reservation_mode_labels,
      time_zone_identifier,
    },
  } = useMenu();

  const { pickupLabel, deliveryLabel, curbsideLabel } =
    getReservationModeLabels(reservation_mode_labels);

  const { pickupHours, deliveryHours, curbsideHours } =
    getAllTodaysScheduleHours(time_zone_identifier, {
      curbside: curbside_hours as unknown as StoreHours[],
      delivery: delivery_hours as unknown as StoreHours[],
      pickup: pickup_hours as unknown as StoreHours[],
    });

  const pickupClosingTime = getTodaysScheduleHours(
    time_zone_identifier,
    pickup_hours as unknown as StoreHours[],
    true
  );

  const deliveryClosingTime = getTodaysScheduleHours(
    time_zone_identifier,
    delivery_hours as unknown as StoreHours[],
    true
  );

  const fallbackLabel = allow_off_hours_ordering
    ? 'Pre-order for later'
    : 'Closed';

  if (!inModal) {
    return isMobile ? (
      <Typography>
        {pickupHours ? `Open until ${pickupClosingTime}` : fallbackLabel}
        <Typography mx={4} as="span">
          &#8226;
        </Typography>
        {deliveryLabel}{' '}
        {deliveryHours ? `until ${deliveryClosingTime}` : fallbackLabel}
      </Typography>
    ) : (
      <>
        <Typography>
          {pickupLabel} {pickupHours || fallbackLabel}
        </Typography>
        <Typography mx={4} as="span">
          &#8226;
        </Typography>
        <Typography>
          {deliveryLabel} {deliveryHours || fallbackLabel}
        </Typography>
      </>
    );
  }

  return (
    <Box>
      <Typography variant="body-bold" pb={8}>
        Today's hours
      </Typography>
      <StyledGrid pb={8}>
        <Typography>{pickupLabel}</Typography>
        <Typography color="text-light" whiteSpace="nowrap">
          {pickupHours || fallbackLabel}
        </Typography>
        <Typography>{deliveryLabel}</Typography>
        <Typography color="text-light" whiteSpace="nowrap">
          {deliveryHours || fallbackLabel}
        </Typography>

        <Typography>{curbsideLabel}</Typography>
        <Typography color="text-light" whiteSpace="nowrap">
          {curbsideHours || fallbackLabel}
        </Typography>
      </StyledGrid>
      <Link
        onClick={() => {
          showSchedule ? setShowSchedule(false) : setShowSchedule(true);
        }}
      >
        {showSchedule ? 'Hide' : 'View'} schedule
      </Link>
      <Hide isHidden={!showSchedule}>
        <MenuSchedule />
      </Hide>
    </Box>
  );
};
