import pickBy from 'lodash/pickBy';
import { useFormContext } from 'react-hook-form';

import type { Customer } from '@jane/shared-ecomm/providers';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { formatTime } from '@jane/shared/util';

export const useMaybeUpdateUser = (
  { checkingOut } = { checkingOut: false }
): (() => void) => {
  const {
    appInfo: { appMode },
    cart: { reservation_mode },
    customer,
    dispatches: { updateUser },
    guestCheckout: { setPreAuthCustomer },
  } = useCheckout();
  const { getValues } = useFormContext();

  // If the user is authenticated and they change their info, we update their account.
  // If they are guest users, we store their information in case they login/sign up
  const handleMaybeUpdateUser = () => {
    const values = getValues('customerInformation');
    const promotionsOptIn = getValues(
      `${
        reservation_mode === 'delivery' ? 'delivery' : 'pickup'
      }Options.promotionsOptIn`
    );

    const formattedBirthDate = values.birthDate
      ? formatTime(values.birthDate, 'YYYY-MM-DD', 'MMDDYYYY')
      : '';

    const newValues = {
      ...values,
      birthDate: formattedBirthDate,
      promotionsOptOut: appMode === 'default' ? !promotionsOptIn : undefined,
    };

    if (customer?.authenticated) {
      const { firstName, lastName, birthDate, phone, promotionsOptOut } =
        newValues;
      const attributes = {
        firstName,
        lastName,
        birthDate,
        phone,
        promotionsOptOut,
      };

      const changedAttributes = pickBy(
        attributes,
        (value: string, key: string) =>
          customer[key as keyof Customer] !== value
      );

      // even if promotionsOptOut is the only changed attribute, a user parameter (i.e. firstName)
      // needs to be passed with it to build a API proper request
      if (
        Object.keys(changedAttributes).length === 1 &&
        Object.keys(changedAttributes).includes('promotionsOptOut')
      ) {
        updateUser({ firstName, promotionsOptOut });
      } else if (Object.keys(changedAttributes).length > 0) {
        updateUser(changedAttributes);
      }
    } else if (!checkingOut) {
      setPreAuthCustomer(newValues);
    }
  };

  return handleMaybeUpdateUser;
};
