import type { AppMode, MenuProduct, StoreSpecial } from '@jane/shared/models';
import { doesSpecialApply } from '@jane/shared/util';

export const findSpecialForProduct = (
  menuProduct: MenuProduct,
  specials: StoreSpecial[] = [],
  appMode: AppMode
) =>
  specials
    .filter(Boolean)
    .find((special: StoreSpecial) =>
      doesSpecialApply(appMode, special, menuProduct)
    );
