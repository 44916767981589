import type { Theme } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import type { ReactNode } from 'react';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import { LegacyButton } from '../legacyButton';

const baseStyle = ({ theme }: { theme: Theme }) =>
  css(spacing({ px: 16 }), {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    borderStyle: 'none',
    background: 'unset',
    color: theme?.colors.secondary.main,
    cursor: 'pointer',
    fontWeight: 600,
    height: 40,
    position: 'relative',
    whiteSpace: 'nowrap',
    [mediaQueries.mobile('legacy', 'min')]: spacing({ px: 24 }),
  });

export const headerButtonStyle = ({ theme }: { theme: Theme }) =>
  css(baseStyle({ theme }), {
    borderRadius: 4,
  });

export const buttonGroupStyle = ({ theme }: { theme: Theme }) =>
  css(baseStyle({ theme }), {
    '& + &': {
      borderLeft: `1px solid ${theme?.colors.grays.white}`,
    },
    '&:first-of-type': {
      borderRadius: '4px 0 0 4px',
    },
    '&:last-of-type': {
      borderRadius: '0 4px 4px 0',
    },
  });

interface Props {
  ariaLabel?: string;
  children?: ReactNode;
  icon?: string;
  inGroup?: boolean;
  onClick?: () => void;
  to?: string;
}

export const EmbeddedButton = ({
  ariaLabel,
  children,
  icon,
  onClick,
  to,
  inGroup,
}: Props) => {
  const theme = useTheme();
  const style = inGroup
    ? buttonGroupStyle({ theme })
    : headerButtonStyle({ theme });

  return to ? (
    <LegacyButton
      ariaLabel={ariaLabel || 'Header button'}
      onClick={onClick}
      data-testid="embed-header-button"
      css={style}
      variant="secondary-inverse"
      to={to}
      icon={icon}
      textColor={theme?.colors.secondary.main}
    >
      {children}
    </LegacyButton>
  ) : (
    <LegacyButton
      ariaLabel={ariaLabel || 'Header button'}
      css={style}
      onClick={onClick}
      variant="secondary-inverse"
      type="button"
      icon={icon}
      textColor={theme?.colors.secondary.main}
    >
      {children}
    </LegacyButton>
  );
};
