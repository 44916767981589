import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type {
  MenuProduct,
  PriceId,
  WeightSelectorWeight,
} from '@jane/shared/models';
import {
  enabledWeightsForMenuProduct,
  getMaxCartQuantity,
  getOriginalAndDiscountedTotalPrices,
  isSoldByWeight,
  usePDPDetector,
} from '@jane/shared/util';

import { DisplayMode, useProductCardContext } from '../productCardProvider';
import { firstAvailablePriceIdFromFilters } from './utils/firstAvailablePriceIdFromFilters';
import initialPriceIdForProduct from './utils/initialPriceIdForProduct';

export interface RenderProps {
  decrementQuantity: () => void;
  discountedPrice?: number;
  incrementQuantity: () => void;
  multipleCountDisabled: boolean;
  noSelectedWeightDefault: boolean;
  onAddToCartPressed: () => void;
  onWeightSelected: (selectedWeight: PriceId) => void;
  originalPrice?: number;
  selectedQuantity: number;
  selectedWeight: PriceId;
  shoppingDisabled: boolean;
  soldByQuantityOnly: boolean;
  soldByWeight: boolean;
  weights: WeightSelectorWeight[];
}

interface Props {
  children: (arg: RenderProps) => JSX.Element;
  sortedByPrice?: boolean;
}

const ListViewStateContainer = ({ sortedByPrice, children }: Props) => {
  const {
    appliedWeightFilter,
    availableWeights,
    cartProduct,
    currentSpecial,
    defaultWeight,
    disableInteraction,
    displayMode,
    listView,
    menuProduct,
    onAddToCart,
    onDeleteFromCart,
    setDisplayMode,
    showOnlyWeights,
    store,
    trackListViewClick,
    searchState,
    weightFilter,
  } = useProductCardContext();
  const isPDP = usePDPDetector();

  const { selectedWeight, setSelectedWeight } = useProductCardContext();

  const [selectedQuantity, setSelectedQuantity] = useState(
    cartProduct && cartProduct.length > 0 ? cartProduct[0].count : 1
  );
  const [noSelectedWeightDefault, setNoSelectedWeightDefault] = useState(
    !cartProduct
  );
  const showAlmostGone = useFlag(FLAGS.almostGone);

  useEffect(() => {
    if (listView) {
      if (!(cartProduct && cartProduct.length === 0)) {
        setDisplayMode(DisplayMode.Confirmation);
      }
    }
  }, [cartProduct, listView, setDisplayMode, setSelectedWeight]);

  useEffect(() => {
    if (!listView && menuProduct) {
      if (weightFilter) {
        // if using the new weights function, defaultWeight has already been determined,
        // and it took cartProduct into account.
        setSelectedWeight && setSelectedWeight(defaultWeight);
        setSelectedQuantity(
          cartProduct && cartProduct.length > 0 ? cartProduct[0].count : 1
        );
      } else {
        let initialPriceId = initialPriceIdForProduct({
          menuProduct: menuProduct,
          cartProduct: cartProduct?.length ? cartProduct[0] : undefined,
          showOnlyWeights,
          special: currentSpecial,
          appliedWeightFilter,
          sortedByPrice,
        });

        const priceKey = `price_${initialPriceId}`;

        if (get(menuProduct, priceKey) === null) {
          initialPriceId = firstAvailablePriceIdFromFilters({
            menuProduct,
            appliedFilters: get(
              searchState?.filters || {},
              'available_weights'
            ) as string[],
            defaultValue: initialPriceId,
          });
        }

        setSelectedWeight && setSelectedWeight(initialPriceId);
        setSelectedQuantity(
          cartProduct && cartProduct.length > 0 ? cartProduct[0].count : 1
        );
      }
    }
  }, [
    appliedWeightFilter,
    showOnlyWeights,
    sortedByPrice,
    displayMode,
    menuProduct,
    setSelectedWeight,
  ]);

  const onAddToCartPressed = debounce(
    () => {
      if (disableInteraction) return;

      if (selectedQuantity === 0 && cartProduct && cartProduct.length > 0) {
        onDeleteFromCart &&
          onDeleteFromCart({ itemId: cartProduct[0].id, selectedWeight });
        setSelectedQuantity(1);
      } else {
        const inventoryCount =
          !!menuProduct && !!selectedWeight
            ? getMaxCartQuantity(menuProduct, selectedWeight)
            : undefined;

        onAddToCart &&
          selectedWeight &&
          store &&
          menuProduct &&
          onAddToCart({
            almostGone:
              showAlmostGone && inventoryCount < 4 ? inventoryCount : null,
            store: store,
            menuProduct: menuProduct,
            price_id: selectedWeight,
            count: selectedQuantity,
            special: currentSpecial,
            location: isPDP ? 'productDetailPage' : 'menu',
          });
      }
      if (!listView) {
        setDisplayMode(DisplayMode.Confirmation);
      }
      listView && trackListViewClick && trackListViewClick();
    },
    500,
    { leading: true, trailing: false }
  );

  const onWeightSelected = (selectedWeight: PriceId) => {
    if (disableInteraction) return;

    const isInCart = (cartProduct || []).find(
      (product) => product.price_id === selectedWeight
    );
    setNoSelectedWeightDefault(false);

    setSelectedWeight && setSelectedWeight(selectedWeight);
    setSelectedQuantity(isInCart ? isInCart.count : 1);
    setDisplayMode(DisplayMode.Edit);
    listView && trackListViewClick && trackListViewClick();
  };

  const incrementQuantity = () => {
    if (disableInteraction) return;

    setSelectedQuantity((prevQuantity) => prevQuantity + 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const decrementQuantity = () => {
    if (disableInteraction) return;

    setSelectedQuantity((prevQuantity) => prevQuantity - 1);
    listView && trackListViewClick && trackListViewClick();
  };

  const multipleCountDisabled =
    menuProduct?.kind === 'flower' && !menuProduct?.allow_multiple_flower_count;

  const weights =
    // the new specialWeights functions have already determined the available_weights,
    // this just formats it to work as a weight selector value
    weightFilter && availableWeights
      ? availableWeights.map((priceId) => ({
          price: menuProduct?.[`price_${priceId}`],
          value: priceId,
        }))
      : enabledWeightsForMenuProduct(
          menuProduct as MenuProduct,
          currentSpecial
        );

  const soldByWeight = menuProduct ? isSoldByWeight(menuProduct.kind) : false;
  const soldByQuantityOnly = !(multipleCountDisabled || soldByWeight);

  const { originalPrice, discountedPrice } =
    getOriginalAndDiscountedTotalPrices({
      menuProduct: menuProduct as MenuProduct,
      count: selectedQuantity,
      priceId: selectedWeight || undefined,
    });

  const shoppingDisabled = store?.hide_prices;

  const propsForChildren = {
    multipleCountDisabled,
    weights,
    soldByWeight,
    soldByQuantityOnly,
    shoppingDisabled,
    originalPrice,
    discountedPrice,
    onAddToCartPressed,
    incrementQuantity,
    decrementQuantity,
    onWeightSelected,
    setDisplayMode,
    noSelectedWeightDefault,
    displayMode,
    selectedQuantity,
  };

  return children(propsForChildren as any);
};

export default ListViewStateContainer;
