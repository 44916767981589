import { createContext, useContext, useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

export const SearchTrackingContext = createContext<{
  preventTracking: boolean;
  registerPreventer: null | (() => () => void);
}>({
  preventTracking: false,
  registerPreventer: null,
});

export const useSearchTrackingContext = () => useContext(SearchTrackingContext);

export const SearchTrackingProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const preventerCount = useRef(0);
  const [preventTracking, setPreventTracking] = useState(false);

  const registerPreventer = () => {
    preventerCount.current++;
    if (preventerCount.current === 1) {
      setPreventTracking(true);
    }

    return () => {
      preventerCount.current--;
      if (preventerCount.current === 0) {
        setPreventTracking(false);
      }
    };
  };

  return (
    <SearchTrackingContext.Provider
      value={{ preventTracking, registerPreventer }}
    >
      {children}
    </SearchTrackingContext.Provider>
  );
};

export const PreventSearchTracking = () => {
  const { registerPreventer } = useSearchTrackingContext();

  useEffect(() => {
    if (!registerPreventer) {
      throw new Error(
        'PreventSearchTracking must be inside a SearchTrackingProvider'
      );
    }
    return registerPreventer();
  }, []);

  return null;
};
