import { useCheckout } from '@jane/shared-ecomm/providers';
import type { PendingCartProduct } from '@jane/shared/models';
import {
  Box,
  Flex,
  Typography,
  useDesktopMediaQuery,
} from '@jane/shared/reefer';
import { deserializeCannabinoids, formatCurrency } from '@jane/shared/util';

import { CheckoutButton } from '../CheckoutButton/CheckoutButton';
import { useCheckoutPayments } from '../CheckoutPayments/CheckoutPaymentsProvider';
import { CheckoutStep } from '../checkout.styles';
import { CheckoutReviewProductCard } from './CheckoutReviewProductCard';

const sortAndDeserialize = (products: PendingCartProduct[]) =>
  products
    .map((product) => deserializeCannabinoids(product, 'menuProduct'))
    .sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

export const CheckoutReview = () => {
  const isDesktop = useDesktopMediaQuery({ size: 'sm', width: 'min' });

  const { selected: selectedPaymentMethod } = useCheckoutPayments();
  const {
    cart: { jane_pay_service_fee_amount, products },
    cartLineItems: { total },
  } = useCheckout();
  const totalToDisplay =
    selectedPaymentMethod === 'jane_pay'
      ? total + jane_pay_service_fee_amount
      : total;

  return (
    <CheckoutStep width="100%">
      <Typography variant="header-bold" branded>
        Review your items
      </Typography>

      <Flex flexDirection="column" gap={24} mt={24}>
        {sortAndDeserialize(products as PendingCartProduct[]).map((product) => (
          <CheckoutReviewProductCard
            key={`${product.id}-${product.price_id}`}
            product={product as PendingCartProduct}
          />
        ))}
      </Flex>

      {isDesktop && (
        <Box>
          <Flex justifyContent="space-between" my={24}>
            <Typography variant="header-bold" branded>
              Estimated total
            </Typography>
            <Typography variant="header-bold" branded>
              {formatCurrency(totalToDisplay)}
            </Typography>
          </Flex>

          <CheckoutButton />
        </Box>
      )}
    </CheckoutStep>
  );
};
