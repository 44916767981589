import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutAccordion } from '../CheckoutAccordion/CheckoutAccordionProvider';
import { useCheckoutForm } from '../CheckoutFormProvider/CheckoutFormProvider';
import { useCheckoutInfo } from '../util/hooks/useCheckoutInfo';
import { useFulfillmentSchedule } from '../util/hooks/useFulfillmentSchedule';
import { useSelectedFulfillmentTime } from '../util/hooks/useSelectedFulfillmentTime';
import { useUserConsents } from '../util/hooks/useUserConsents';

export const useCheckoutOptionsForm = () => {
  const {
    customer: { authenticated },
    cart: { reservation_mode },
    guestCheckout: { setPreAuthOptions },
  } = useCheckout();

  const { autoSelectSlot } = useCheckoutForm();
  const { hasFetchedDelivery } = useFulfillmentSchedule();
  const { isValid: slotValid } = useSelectedFulfillmentTime();
  const { getValues } = useFormContext();
  const { setExpanded } = useCheckoutAccordion();
  const { isValid: infoValid } = useCheckoutInfo();
  const { consentItems, isValid: consentsValid } = useUserConsents();

  const isDelivery = reservation_mode === 'delivery';
  const formKey = isDelivery ? 'deliveryOptions' : 'pickupOptions';

  const isValid = slotValid && consentsValid;

  const handleContinue = () => {
    // If this is a guest user, we store their consent agreements and selected reservation time in case they sign in later.
    if (!authenticated) {
      const deliveryMessage = getValues('deliveryOptions.message');
      const formAgreements = getValues(`${formKey}.consentItems`);
      const fulfillmentTime = getValues(`${formKey}.fulfillmentTime`);
      const pickupMode = getValues('pickupOptions.pickupMode');
      const pickupMessage = getValues('pickupOptions.message');

      const consents = Object.keys(formAgreements)
        .filter((key) => formAgreements[key])
        .map(Number);

      setPreAuthOptions({
        consents,
        deliveryMessage,
        fulfillmentTime,
        pickupMessage,
        pickupMode,
      });
    }

    setExpanded(!infoValid ? 'info' : 'payments');
  };

  useEffect(() => {
    if (!slotValid && (hasFetchedDelivery || reservation_mode !== 'delivery')) {
      autoSelectSlot();
    }
  }, [hasFetchedDelivery, slotValid, reservation_mode]);

  return {
    consentItems,
    consentsValid,
    handleContinue,
    isValid,
  };
};
