import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Link, Typography } from '@jane/shared/reefer';

import { useCheckoutAccordion } from '../CheckoutAccordion/CheckoutAccordionProvider';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useSelectedFulfillmentTime } from '../util/hooks/useSelectedFulfillmentTime';

export const PickupSummary = () => {
  const { displayName } = useSelectedFulfillmentTime();
  const { curbsideLabel, reservationModeLabel } = useReservationModeLabels();
  const { setExpanded } = useCheckoutAccordion();

  const { control } = useFormContext();

  const [message, pickupMode] = useWatch({
    control,
    name: ['pickupOptions.message', 'pickupOptions.pickupMode'],
  });

  return (
    <Box>
      {pickupMode === 'curbside' && <Typography>{curbsideLabel}</Typography>}

      {displayName && <Typography>{displayName}</Typography>}

      {!!message && (
        <Typography mt={24}>
          {reservationModeLabel} instructions: {message}
        </Typography>
      )}

      {!displayName && (
        <Link mt={24} onClick={() => setExpanded('options')}>
          Add pickup time
        </Link>
      )}
    </Box>
  );
};
