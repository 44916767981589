import { useHandleIframeModal } from '@jane/shared-ecomm/hooks';
import {
  Button,
  ErrorIcon,
  Flex,
  Link,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { inIframe } from '@jane/shared/util';

/**
 * This component is used when errors occur during customer flows that exist within modals.
 * ie. Navigating from the MFA modal to the Aeropay/Stronghold modals.
 * Instead of showing a checkout error, we pop a new modal with the error.
 */
export const CheckoutErrorModal = ({
  handleClose,
  open,
}: {
  handleClose: () => void;
  open: boolean;
}) => {
  const isInIframe = inIframe();
  useHandleIframeModal(open);

  return (
    <Modal
      variant="dialogue"
      open={open}
      onRequestClose={handleClose}
      topOverride={isInIframe ? (open ? '160px' : '350px') : undefined}
    >
      <Modal.Content>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <ErrorIcon size="xl" color="primary" />

          <Typography mt={16} variant="body-bold" textAlign="center">
            Something went wrong.
          </Typography>

          <Typography textAlign="center" as="span">
            Try again or{' '}
            <Link href="https://customer-support.iheartjane.com/s/contactsupport">
              contact support
            </Link>{' '}
            for more help.
          </Typography>

          <Button
            label="Done"
            onClick={handleClose}
            mt={16}
            variant="primary"
            full
          />
        </Flex>
      </Modal.Content>
    </Modal>
  );
};
