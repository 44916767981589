import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import { useIsRecommendedSortEnabled } from '@jane/dm/internal';
import { useFacets, useGoldFacets } from '@jane/search/data-access';
import { useFormattedProductFilters } from '@jane/search/hooks';
import { useSearchContext } from '@jane/search/providers';
import type {
  AlgoliaFacets,
  ArrayFilterValue,
  FilterSortOption,
  MenuOptions,
  ProductFilterKeys,
  RangeFilterValue,
  SearchResponse,
} from '@jane/search/types';
import {
  AlgoliaFacetsSchema,
  AlgoliaGoldFacetsSchema,
  makeCurrentSort,
} from '@jane/search/types';
import {
  buildGoldUserSegmentsFilter,
  composeFilters,
  formatActiveFilters,
  getShouldShowRecommendedSort,
  handleFilterChange,
  handleFilterDeselect,
  maybeGetDefaultStoreMenuSort,
  overrideCustomCategoryLabels,
  sortOptions,
  sortOptionsWithRecommended,
  updateFacetCounts,
  updateSortSuffix,
} from '@jane/search/util';
import { ProductFilterBar } from '@jane/shared-ecomm/components';
import {
  useMenu,
  useSpecials,
  useUserPreferences,
} from '@jane/shared-ecomm/providers';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { StoreSpecial } from '@jane/shared/models';
import type { MenuCategoryPath } from '@jane/shared/types';

interface MenuFiltersProps {
  isLoadingProducts?: boolean;
  menuCategory: MenuCategoryPath;
  searchResultFacets: SearchResponse['facets'];
  showOnlyWeights?: MenuOptions['showOnlyWeights'];
  staticFilters: string;
  totalResults: number;
}

export const MenuFilters = ({
  isLoadingProducts,
  menuCategory,
  totalResults,
  searchResultFacets,
  showOnlyWeights,
  staticFilters,
}: MenuFiltersProps) => {
  const shouldShowGold = useShouldShowGold();
  const goldUserSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const userSegments = useUserSegmentIds();
  const {
    appInfo: { appMode, brandPartner, productReviewsEnabled },
    handleSetListView,
    listView,
    loading,
    searchStateWithInitialQuery,
    store,
  } = useMenu();
  const {
    userLocation: { cityState },
  } = useUserPreferences();
  const { specials } = useSpecials();
  const { searchState, setSearchState, indexPrefix } = useSearchContext();
  const [facetsToRender, setFacetsToRender] = useState<Partial<AlgoliaFacets>>(
    {}
  );

  const { currentSort, filters } = searchState;

  const specialsPage = menuCategory === 'specials';
  const categoryPage = !['all', 'featured', 'specials'].includes(menuCategory)
    ? menuCategory
    : undefined;

  const isRecommendedSortEnabled = useIsRecommendedSortEnabled(store, appMode);
  const shouldShowRecommendedSort = getShouldShowRecommendedSort({
    isRecommendedSortEnabled,
    isTable: true,
  });

  const sortOptionsToRender = shouldShowRecommendedSort
    ? sortOptionsWithRecommended
    : sortOptions;

  useEffect(() => {
    if (!isRecommendedSortEnabled) {
      return;
    }
    const newSort = maybeGetDefaultStoreMenuSort({
      currentSort,
      shouldShowRecommendedSort,
    });
    if (currentSort && newSort) {
      setSearchState({ currentSort: newSort });
    }
  }, [
    currentSort,
    isRecommendedSortEnabled,
    setSearchState,
    shouldShowRecommendedSort,
  ]);

  const onQuery = (value: string) => setSearchState({ searchText: value });

  const onSort = (value: FilterSortOption) => {
    const weightFilters = filters?.['available_weights'] as string[];
    const newSort = makeCurrentSort(value);
    newSort.suffix = updateSortSuffix(newSort.suffix, weightFilters);

    setSearchState({ currentSort: newSort });
  };

  const onChange = useCallback(
    (
      filterKey: ProductFilterKeys,
      filterValue: ArrayFilterValue | RangeFilterValue
    ) => {
      return setSearchState(
        handleFilterChange(filterKey, filterValue, searchState)
      );
    },
    [setSearchState, searchState]
  );

  const onDeselect = (filterKey: ProductFilterKeys) =>
    setSearchState(handleFilterDeselect(filterKey, searchState));

  const shouldOverride = Boolean(
    store?.state === 'California' || (cityState && cityState.includes(', CA'))
  );

  const menuOptions = {
    customLabels: {
      root_types: overrideCustomCategoryLabels(
        shouldOverride,
        store?.custom_product_type_labels
      ),
      category: store?.custom_lineage_labels,
    },
    customRanking: store?.custom_product_type_ranking,
    customRows: store?.custom_rows,
    disabledFilters: store?.disabled_feeling_and_activity_tags,
    menuTabs: store?.menu_tabs,
    showOnlyWeights,
    showRatings: productReviewsEnabled,
    specials: specials as StoreSpecial[],
    specialsLabel: store?.store_compliance_language_settings?.['specials'],
    storeId: store?.id,
    storeState: store?.state,
  };

  const appOptions = {
    appMode,
    brandPage: undefined, // * phase 2
    categoryPage,
    specialsPage,
  };

  const { data: facets, isLoading } = useFacets({
    indexPrefix,
    schemaObject: AlgoliaFacetsSchema,
    staticFilters,
  });

  // The gold facets query should always be filtered by gold, however if we're filtering
  // on gold, the staticFilters will already include the gold filter and we don't need to
  // add it.
  const goldFilterValues = searchState.filters?.['has_brand_discount'] ?? [];
  const isFilteredByGold = goldFilterValues?.length > 0;
  const goldFacetsStaticFilters = composeFilters(
    staticFilters,
    goldUserSegmentation && !isFilteredByGold
      ? buildGoldUserSegmentsFilter(userSegments)
      : ''
  );

  // We don't want to fetch gold facets if gold facets don't exist at all
  // (i.e. a store does not have gold enabled, or has no gold products on the menu)
  const goldFacetsEnabled =
    shouldShowGold &&
    Boolean(
      facets &&
        facets['has_brand_discount'] &&
        facets['has_brand_discount']['true']
    );
  const { data: goldFacets } = useGoldFacets({
    enabled: goldFacetsEnabled,
    indexPrefix,
    schemaObject: AlgoliaGoldFacetsSchema,
    searchText: searchStateWithInitialQuery?.searchText,
    staticFilters: goldFacetsStaticFilters,
    requestedFacets: ['has_brand_discount'],
    ...searchState,
  });

  useEffect(() => {
    if (isLoading) return;

    const newFacets = searchResultFacets
      ? updateFacetCounts(
          searchState,
          {
            ...facets,
            ...goldFacets,
          },
          searchResultFacets
        )
      : facets || {};

    if (!isEqual(newFacets, facetsToRender)) {
      setFacetsToRender(newFacets);
    }
  }, [
    facets,
    isLoading,
    goldFacets?.has_brand_discount,
    searchResultFacets,
    searchState,
  ]);

  const activeFilters = formatActiveFilters(searchState, menuOptions);
  const productFilters = useFormattedProductFilters({
    appOptions,
    facets: facetsToRender,
    menuOptions,
  });

  return (
    <ProductFilterBar
      activeFilters={activeFilters}
      currentSort={currentSort}
      filters={productFilters}
      isLoading={isLoadingProducts}
      isLoadingFacets={!facets || loading || isLoading}
      listView={listView}
      onChange={(filterKey, value) => {
        onChange(filterKey as ProductFilterKeys, value);
      }}
      onDeselect={(filterKey) => {
        onDeselect(filterKey as ProductFilterKeys);
      }}
      onQuery={onQuery}
      onSort={onSort}
      onView={handleSetListView}
      modalSearchState={searchState}
      sortOptions={sortOptionsToRender}
      searchId={store?.id || brandPartner?.id}
      setModalSearchState={setSearchState}
      totalResults={totalResults}
      variant="product"
    />
  );
};
