import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';
import type { StrongholdCustomer } from '@jane/shared/models';

import { queryKeys, urls } from './stronghold.util';

export interface UseStrongholdCustomerProps {
  firstName: string;
  lastName: string;
  phone: string;
  storeId: number | string;
}

export const fetchStrongholdCustomer = async ({
  storeId,
  firstName,
  lastName,
  phone,
}: UseStrongholdCustomerProps): Promise<StrongholdCustomer | null> => {
  const jwt = getJwt();

  const encodedFirstName = encodeURIComponent(firstName.trim());
  const encodedLastName = encodeURIComponent(lastName.trim());
  const encodedPhone = encodeURIComponent(phone);

  const url = `${urls.strongholdCustomer}/${storeId}?first_name=${encodedFirstName}&last_name=${encodedLastName}&phone=${encodedPhone}`;

  if (jwt) {
    return await janeApi.get(url, jwt);
  } else {
    return null;
  }
};

const enableCustomerQuery = (props: UseStrongholdCustomerProps) => {
  const jwt = getJwt();
  return !!jwt && Boolean(Object.values(props).every((value) => !!value));
};

export const useStrongholdCustomer = createUseQueryHook<
  UseStrongholdCustomerProps,
  StrongholdCustomer | null
>(fetchStrongholdCustomer, queryKeys.strongholdCustomer, enableCustomerQuery);
