import { Banner, Box, ErrorIcon, Typography } from '@jane/shared/reefer';
import { formatWeight } from '@jane/shared/util';

import type {
  CartLimitPolicyReport,
  CartLimitRuleReport,
} from '../../../../../lib/cartLimitPolicy';
import { LimitItem, LimitItems } from './cartLimitPolicyAlert.styles';

export const CartLimitPolicyAlert = ({
  report,
}: {
  report?: CartLimitPolicyReport | null;
}) => {
  if (!report || !report.over) return null;
  const { overReports, storeName } = report;

  return (
    <Banner
      icon={<ErrorIcon />}
      label={
        <Box>
          <Typography color="inherit" variant="body-bold" mb={8}>
            Cart limit exceeded
          </Typography>
          <LimitItems>
            {overReports.map((ruleReport: CartLimitRuleReport, i: number) => {
              const {
                limit_unit_total,
                limit_value,
                limit_unit,
                product_group_name,
              } = ruleReport;

              return (
                <LimitItem key={i}>
                  <Typography color="inherit" variant="body-bold">
                    {`Your bag has ${
                      limit_unit === 'mg' ? 'a total dosage of' : ''
                    } ${formatWeight(
                      limit_unit_total
                    )}${limit_unit} of ${product_group_name}  products, but ${storeName} only allows ${formatWeight(
                      limit_value
                    )}${limit_unit} per order.`}
                  </Typography>
                </LimitItem>
              );
            })}
          </LimitItems>
        </Box>
      }
      variant="error"
      full
    />
  );
};
