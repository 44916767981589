import dayjs from 'dayjs';

import { Storage } from '@jane/shared/util';

export const LOCAL_STORAGE_KEY = 'dismissedCommsBanners';
const ONE_DAY = 24 * 60 * 60 * 1000;

type StorageItems = { [storeId: string]: number };

/**
 * In order to determine if a comms modal is open by default,
 * we use local storage to record that a user dismissed the modal.
 * The local storage key dismissedCommsBanners will be an object
 * where the object keys are the store ids and the values are the dismissed timestamp (unix ms).
 * The util functions are used to read, set, and clean up the local storage.
 */

const getDismissedModals = (): StorageItems => {
  const dismissedModals = Storage.get(LOCAL_STORAGE_KEY);

  return dismissedModals ? JSON.parse(dismissedModals) : {};
};

export const setDismissedModal = (storeId: string) => {
  const dismissedModals: { [key: string]: number } = getDismissedModals() || {};
  const currentDate = Date.now();

  dismissedModals[storeId] = currentDate;

  // If we continually update this piece of storage,
  // it could theoretically grow forever, so we will periodically clean it up.
  for (const [id, date] of Object.entries(dismissedModals)) {
    if (date + ONE_DAY <= currentDate) {
      delete dismissedModals[id];
    }
  }

  Storage.set(LOCAL_STORAGE_KEY, JSON.stringify(dismissedModals));
};

// If it's been 24 hours since dismissing the modal, we will pop it by default once again.
export const hasDismissedModal = (
  storeId: string,
  bannerUpdatedAt: string
): boolean => {
  const dismissedModals = getDismissedModals();

  const dismissedTimestamp = dismissedModals[storeId];
  if (!dismissedTimestamp) return false;

  const currentDate = Date.now();
  const updatedAt = dayjs(bannerUpdatedAt).valueOf();

  // If the banner has been changed since they dismissed it.
  if (updatedAt > dismissedTimestamp) return false;
  if (dismissedTimestamp + ONE_DAY < currentDate) return false;

  return true;
};
