import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, Typography } from '@jane/shared/reefer';
import { getTodaysScheduleHours } from '@jane/shared/util';

import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';

export const StoreHours = () => {
  const {
    store: {
      time_zone_identifier,
      pickup_hours,
      delivery_hours,
      curbside_hours,
    },
  } = useCheckout();

  const { curbsideLabel, deliveryLabel, pickupLabel } =
    useReservationModeLabels();

  const curbsideHours = getTodaysScheduleHours(
    time_zone_identifier,
    curbside_hours
  );
  const pickupHours = getTodaysScheduleHours(
    time_zone_identifier,
    pickup_hours
  );
  const deliveryHours = getTodaysScheduleHours(
    time_zone_identifier,
    delivery_hours
  );

  if (!pickupHours && !curbsideHours && !deliveryHours) return null;

  return (
    <Box>
      <Typography color="text-light" mt={24}>
        Today's hours
      </Typography>

      {!!pickupHours && (
        <Typography>
          {pickupLabel} {pickupHours}
        </Typography>
      )}

      {!!curbsideHours && (
        <Typography>
          {curbsideLabel} {curbsideHours}
        </Typography>
      )}

      {!!deliveryHours && (
        <Typography>
          {deliveryLabel} {deliveryHours}
        </Typography>
      )}
    </Box>
  );
};
