import { useCheckout } from '@jane/shared-ecomm/providers';

import { AeropayButton } from '../CheckoutPayments/Aeropay/AeropayButton';
import { CanPayButton } from '../CheckoutPayments/CanPay/CanPayButton';
import { useCheckoutPayments } from '../CheckoutPayments/CheckoutPaymentsProvider';
import { MonerisButton } from '../CheckoutPayments/Moneris/MonerisButton';
import { PaytenderButton } from '../CheckoutPayments/Paytender/PaytenderButton';
import type { PaymentOptions } from '../util/hooks/usePaymentOptions';
import {
  PaymentMethods,
  usePaymentOptions,
} from '../util/hooks/usePaymentOptions';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useSubmitCheckout } from '../util/hooks/useSubmitCheckout';
import { SharedButton } from './SharedButton';

export const CheckoutButton = () => {
  const {
    store: { canpay_v2remotepay_enabled, jane_pay_integration },
  } = useCheckout();

  const { selected } = useCheckoutPayments();
  const { paymentOptions, prepaymentOptions } = usePaymentOptions();
  const { submit } = useSubmitCheckout();

  const { reservationModeLabel } = useReservationModeLabels(true);

  const hideBranding = Boolean(jane_pay_integration?.white_label);

  // These payment methods are immediately captured when the user
  // finishes interacting with the third-party widget
  if (selected === PaymentMethods.aeropay) return <AeropayButton />;
  if (selected === PaymentMethods.moneris) return <MonerisButton />;
  if (selected === PaymentMethods.paytender) return <PaytenderButton />;
  if (selected === PaymentMethods.canpay && canpay_v2remotepay_enabled)
    return <CanPayButton />;

  const allOptions = [...paymentOptions, ...prepaymentOptions];
  const getLabel = () => {
    if (!selected || !allOptions.includes(selected))
      return 'Select payment method';
    // In-store payments
    if ((paymentOptions as PaymentOptions[]).includes(selected))
      return `Submit ${reservationModeLabel} reservation`;

    if (selected === PaymentMethods.janePay && !hideBranding)
      return 'Pay now with';
    if (selected === PaymentMethods.janePay && hideBranding)
      return 'Pay now with your linked bank account';
    if (selected === PaymentMethods.canpay) return 'Pay now with CanPay';
    if (selected === PaymentMethods.payfirma) return 'Pay now with credit card';

    return `Pay now with ${
      selected.charAt(0).toUpperCase() + selected.slice(1)
    }`;
  };

  return <SharedButton label={getLabel()} onClick={submit} />;
};
