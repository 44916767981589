import { useMenu } from '@jane/shared-ecomm/providers';
import {
  Box,
  Flex,
  InfoIcon,
  PartnerLogo,
  Tag,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { MenuAbout } from './MenuAbout';
import { MenuRating } from './MenuRating';

export const MenuHeaderMax = ({
  handleOpenModal,
}: {
  handleOpenModal: () => void;
}) => {
  const {
    store: { medical, name, photo, recreational, reviews_count },
  } = useMenu();

  const isMobile = useMobileMediaQuery({});

  return (
    <Flex py={isMobile ? 24 : 48} justifyContent="space-between">
      <Flex gap={24}>
        <PartnerLogo
          image={photo}
          name={name}
          variant="store"
          size={isMobile ? 'md' : 'lg'}
        />
        <Box>
          <Typography
            variant={isMobile ? 'header-bold' : 'title-bold'}
            branded
            as="h1"
          >
            {name}
          </Typography>
          <Flex py={isMobile ? 0 : 4} gap={8}>
            {reviews_count > 0 && <MenuRating />}
            {medical && (
              <Tag
                label="Med"
                color="palm"
                background="palm-light"
                ariaLabel="Medical cannabis dispensary"
              />
            )}
            {recreational && (
              <Tag
                label="Rec"
                color="purple"
                background="purple-light"
                ariaLabel="Recreational cannabis dispensary"
              />
            )}
          </Flex>
          <MenuAbout />
        </Box>
      </Flex>

      <Box>
        <InfoIcon
          onClick={handleOpenModal}
          altText={`See more details, such as store hours, contact information, ownership and description of ${name}`}
        />
      </Box>
    </Flex>
  );
};
