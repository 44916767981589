import { Fragment } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { useIsRoundingAmountDisplayed } from '@jane/shared/hooks';
import { Box, Flex, Typography } from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

import { useCheckoutPayments } from '../CheckoutPayments/CheckoutPaymentsProvider';
import { useReservationModeLabels } from '../util/hooks/useReservationModeLabels';
import { useTipSettings } from '../util/hooks/useTipSettings';
import { Tipping } from './Tipping';
import { LineItemsGrid } from './checkoutSummary.styles';

interface LineItem {
  id: string;
  title: string;
  total: number | undefined;
}

const insertIf = (condition: boolean, value: LineItem) =>
  condition ? [value] : [];

export const CheckoutTotal = () => {
  const {
    cartLineItems,
    cart: {
      jane_pay_service_fee_amount,
      products,
      reservation_mode,
      tax_included,
    },
    store: { id },
  } = useCheckout();
  const { selected: selectedPaymentMethod } = useCheckoutPayments();

  const { showTip } = useTipSettings();

  const { deliveryLabel } = useReservationModeLabels(true);
  const { reservationModeLabel } = useReservationModeLabels();

  const {
    preDiscountSubtotal,
    discountTotal,
    deliveryFee,
    salesTax,
    serviceFee,
    storeTax,
    tipAmount,
    roundingAmount,
    total,
  } = cartLineItems;

  const numberOfItems = products.reduce(
    (prev, curr) => (prev += curr.count),
    0
  );

  const roundingAmountIsDisplayed = useIsRoundingAmountDisplayed(
    roundingAmount as number,
    id
  );

  const applyJanePayServiceFee = Boolean(
    selectedPaymentMethod === 'jane_pay' &&
      jane_pay_service_fee_amount &&
      jane_pay_service_fee_amount > 0
  );
  const estimatedServiceFeeTotal =
    (serviceFee || 0) +
    (applyJanePayServiceFee ? (jane_pay_service_fee_amount as number) : 0);

  const lineItems = [
    {
      title: `Items (${numberOfItems})`,
      total: preDiscountSubtotal,
      id: 'subtotal',
    },
    ...insertIf(discountTotal > 0, {
      title: 'Estimated savings',
      total: -discountTotal,
      id: 'discountTotal',
    }),
    {
      title: 'Estimated service fee',
      total: estimatedServiceFeeTotal,
      id: 'serviceFee',
    },
    ...insertIf(reservation_mode === 'delivery', {
      title: `Estimated ${deliveryLabel} fee`,
      total: deliveryFee,
      id: 'deliveryFee',
    }),
    ...insertIf(storeTax > 0, {
      title: 'Estimated local tax',
      total: storeTax,
      id: 'storeTax',
    }),
    ...insertIf(!tax_included, {
      title: 'Estimated sales tax',
      total: salesTax,
      id: 'salesTax',
    }),
    ...insertIf(showTip, {
      title: `${reservationModeLabel} tip`,
      total: tipAmount,
      id: 'tipAmount',
    }),
    ...insertIf(roundingAmountIsDisplayed, {
      title: 'Rounding',
      total: roundingAmount,
      id: 'roundingAmount',
    }),
  ];

  const totalToDisplay = applyJanePayServiceFee
    ? total + jane_pay_service_fee_amount
    : total;

  return (
    <Box>
      <LineItemsGrid>
        {lineItems.map((lineItem) => (
          <Fragment key={lineItem.id}>
            <Typography>{lineItem.title}</Typography>
            <Typography textAlign="right">
              {formatCurrency(lineItem.total)}
            </Typography>
          </Fragment>
        ))}
      </LineItemsGrid>

      {showTip && <Tipping />}

      <Flex flexDirection="row" mt={24} justifyContent="space-between">
        <Typography variant="header-bold">Estimated Total</Typography>
        <Typography variant="header-bold">
          {formatCurrency(totalToDisplay)}
        </Typography>
      </Flex>
    </Box>
  );
};
