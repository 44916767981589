import { useState } from 'react';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { Box, NumberField } from '@jane/shared/reefer';

import { useTipSettings } from '../util/hooks/useTipSettings';
import {
  SlidingBackground,
  Tip,
  TipContainer,
  TransitionedTypography,
} from './checkoutSummary.styles';

export const Tipping = () => {
  const [selectedTip, setSelectedTip] = useState<number | 'custom'>(0);

  const {
    cartLineItems,
    dispatches: { updateTip },
  } = useCheckout();

  const { tipOptions, tipSettings } = useTipSettings();
  const isPercentage = tipSettings?.variant === 'percentage';

  const handleTipChange = (value: number | 'custom') => {
    setSelectedTip(value);

    if (value !== 'custom') {
      const tip = isPercentage ? cartLineItems?.total * value : value;
      updateTip(parseFloat(tip.toFixed(2)));
    }
  };

  const handleCustomTipChange = (value: number) => {
    updateTip(value);
  };

  return (
    <Box mt={24}>
      <TipContainer>
        <SlidingBackground
          length={tipOptions.length}
          index={tipOptions.findIndex(
            (element) => element.value === selectedTip
          )}
        />

        {tipOptions.map((option, i) => (
          <Tip
            key={`${option.value}-${i}`}
            onClick={() => handleTipChange(option.value as number | 'custom')}
          >
            <TransitionedTypography
              textAlign="center"
              variant="body-bold"
              color={
                selectedTip === option.value ? 'grays-white' : 'grays-black'
              }
            >
              {option.label}
            </TransitionedTypography>
          </Tip>
        ))}
      </TipContainer>

      {selectedTip === 'custom' && (
        <NumberField
          allowedDecimalPlaces={2}
          disableMobileInputStyling
          defaultValue={cartLineItems.tipAmount}
          label=""
          name="custom-tip"
          onChange={handleCustomTipChange}
          startUnit="$"
        />
      )}
    </Box>
  );
};
