import { useEffect, useState } from 'react';

import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import { PrepaymentMethods, useCheckout } from '@jane/shared-ecomm/providers';
import { Accordion } from '@jane/shared/components';
import { Box, Flex, Link, Typography } from '@jane/shared/reefer';
import { titleCase } from '@jane/shared/util';

import { CheckoutAccordionItem } from '../CheckoutAccordion/CheckoutAccordionItem';
import type { PaymentOptions } from '../util/hooks/usePaymentOptions';
import {
  PaymentMethods,
  usePaymentOptions,
} from '../util/hooks/usePaymentOptions';
import { AeropayHeader } from './Aeropay/AeropayHeader';
import { CanPayPayment } from './CanPay/CanPayPayment';
import { CheckoutPaymentsAccordionItem } from './CheckoutPaymentsAccordionItem';
import { useCheckoutPayments } from './CheckoutPaymentsProvider';
import { CheckoutPrePaymentsButton } from './CheckoutPrePaymentsButton';
import { JanePayHeader } from './JanePay/JanePayHeader';
import { MerrcoPayment } from './Merrco/MerrcoPayment';
import { SquarePayment } from './Square/SquarePayment';
import { StrongholdHeader } from './Stronghold/StrongholdHeader';

export const CheckoutPayments = () => {
  const [showMorePrePayments, setShowMorePrePayments] = useState(false);

  const {
    cart: { reservation_mode },
    guestCheckout: { preAuthSelection },
    store: {
      aeropay_integration,
      canpay_v2remotepay_enabled,
      jane_pay_integration,
    },
  } = useCheckout();
  const { selectPaymentOption, selected, verified } = useCheckoutPayments();
  const { prepaymentOptions, paymentOptions } = usePaymentOptions();

  const renderPaymentMethodHeader = (method: PaymentOptions) => {
    switch (method) {
      case PaymentMethods.aeropay:
        return <AeropayHeader />;
      case PaymentMethods.moneris:
        return <Typography>Credit or debit card</Typography>;
      case PaymentMethods.payfirma:
        return <Typography>Credit card</Typography>;
      case PaymentMethods.janePay:
        return <JanePayHeader />;
      case PaymentMethods.stronghold:
        return <StrongholdHeader />;
      case PaymentMethods.canpay:
        return <Typography>CanPay</Typography>;
      case PaymentMethods.paytender:
      case PaymentMethods.square:
      case PaymentMethods.cash:
      case PaymentMethods.credit:
      case PaymentMethods.debit:
        return <Typography>{titleCase(method)}</Typography>;
      default:
        return <Typography>{method}</Typography>;
    }
  };

  const renderPaymentMethodForm = (method: PaymentOptions) => {
    switch (method) {
      case PaymentMethods.canpay:
        return canpay_v2remotepay_enabled ? null : <CanPayPayment />;
      case PaymentMethods.payfirma:
        return <MerrcoPayment />;
      case PaymentMethods.square:
        return <SquarePayment />;
      default:
        return null;
    }
  };

  const getSummary = () => {
    if (
      !selected ||
      ![...prepaymentOptions, ...paymentOptions].includes(selected)
    )
      return null;

    const recipient =
      reservation_mode === 'delivery'
        ? 'directly to delivery driver'
        : 'at store';

    // custom payment method
    if (
      !Object.values(PrepaymentMethods).includes(
        selected as PrepaymentProviders
      )
    ) {
      return `Pay ${recipient} with ${selected}`;
    }

    switch (selected) {
      case PaymentMethods.aeropay:
        return (
          aeropay_integration?.custom_payment_method_label ||
          'Pay now with Aeropay'
        );
      case PaymentMethods.janePay:
        return `${
          jane_pay_integration?.white_label
            ? verified
              ? 'Pay now with your linked bank account'
              : 'Link your bank account'
            : verified
            ? 'Pay now with Jane Pay'
            : 'Set up Jane Pay'
        }`;
      case PaymentMethods.credit:
      case PaymentMethods.debit:
        return `Pay ${recipient} with credit or debit`;
      case PaymentMethods.cash:
        return `Pay ${recipient} with cash`;
      default:
        return `${verified ? 'Pay now with' : 'Set up'} ${titleCase(selected)}`;
    }
  };

  // If Jane pay is an option (and there are other options), we collapse the other options
  // and display a "View more" button. Unless they already selected a method before logging in.
  const onlyShowJanePay =
    (!preAuthSelection || preAuthSelection === PaymentMethods.janePay) &&
    prepaymentOptions.length > 1 &&
    !showMorePrePayments &&
    prepaymentOptions.includes(PaymentMethods.janePay);

  const prepaymentList = onlyShowJanePay
    ? prepaymentOptions.slice(0, 1)
    : prepaymentOptions;

  const inPersonLabel =
    reservation_mode === 'delivery'
      ? 'Pay delivery driver with'
      : 'Pay at store with';

  useEffect(() => {
    if (![...prepaymentOptions, ...paymentOptions, null].includes(selected)) {
      selectPaymentOption(null);
    }
  }, [selected, prepaymentOptions, paymentOptions, reservation_mode]);

  return (
    <CheckoutAccordionItem
      id="payments"
      header="Select your payment method"
      summary={getSummary()}
    >
      <Accordion>
        {!!prepaymentOptions?.length && (
          <Box>
            <Flex justifyContent="space-between" mb={24}>
              <Typography variant="body-bold">Pay now with</Typography>
              {onlyShowJanePay && (
                <Link onClick={() => setShowMorePrePayments(true)}>
                  View more
                </Link>
              )}
            </Flex>
            {prepaymentList.map((option) => (
              <CheckoutPaymentsAccordionItem
                key={option}
                id={option}
                header={renderPaymentMethodHeader(option)}
              >
                {renderPaymentMethodForm(option)}
              </CheckoutPaymentsAccordionItem>
            ))}

            <CheckoutPrePaymentsButton />
          </Box>
        )}

        {!!paymentOptions?.length && (
          <Box>
            <Flex justifyContent="space-between" mb={24}>
              <Typography variant="body-bold">{inPersonLabel}</Typography>
            </Flex>
            {paymentOptions.map((option) => (
              <CheckoutPaymentsAccordionItem
                key={option}
                id={option}
                header={renderPaymentMethodHeader(option)}
              />
            ))}
          </Box>
        )}
      </Accordion>
    </CheckoutAccordionItem>
  );
};
