import { getJwt } from '@jane/shared/auth';
import { createUseQueryHook, janeApi } from '@jane/shared/data-access';

import { queryKeys, urls } from './canPay.util';

interface CanPayResponse {
  amount: number;
  cart_uuid: string;
  intent_id: string;
}
export interface CanPayIntentProps {
  amount: number;
  cartUuid: string;
}
const fetchCanPayIntent = async ({
  amount,
  cartUuid,
}: Omit<CanPayIntentProps, 'onError'>) =>
  await janeApi.get<CanPayResponse>(
    `${urls.canPayIntent}?amount=${amount}&cart_uuid=${cartUuid}`,
    getJwt()
  );

export const useCanPayIntent = createUseQueryHook<
  CanPayIntentProps,
  CanPayResponse
>(fetchCanPayIntent, queryKeys.canPayIntent);
