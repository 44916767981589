import { config } from '@jane/shared/config';
import type {
  Store as StoreType,
  StrongholdCustomer,
  StrongholdPaymentSource,
} from '@jane/shared/models';

interface Props {
  adjustStrongholdIframeHeight?: boolean;
  onClose: () => void;
  onError: (error: any) => void;
  onSuccess: (paymentSource: any) => void;
  strongholdCustomer?: StrongholdCustomer;
  strongholdIntegration: StoreType['stronghold_integration'];
}

export const loadStrongholdWidget = ({
  strongholdCustomer,
  strongholdIntegration,
  onSuccess,
  onError,
  onClose,
  adjustStrongholdIframeHeight = false,
}: Props) => {
  const typedWindow = window as any;

  if (!typedWindow.Stronghold.Pay) {
    onError(new Error('No Stronghold.Pay.JS'));
    return;
  }

  if (!strongholdCustomer) {
    onError(new Error('No Stronghold customer'));
    return;
  }

  if (!strongholdIntegration) {
    onError(new Error('No Stronghold integration'));
    return;
  }

  document.body.style.overflow = 'hidden';

  const strongholdPay = typedWindow.Stronghold.Pay({
    publishableKey: strongholdIntegration.publishable_key,
    environment: config.strongholdEnv,
    integrationId: config.strongholdIntegrationId,
  });

  const onReady = () => {
    if (!adjustStrongholdIframeHeight) {
      return;
    }

    const iframe = document.querySelector(
      '#stronghold-pay-iframe'
    ) as HTMLElement;

    if (iframe) {
      iframe.style.maxHeight = '608px';
    }
  };

  const onExit = () => {
    onClose && onClose();
  };

  strongholdPay.addPaymentSource(strongholdCustomer.token.token, {
    onReady,
    onSuccess: (paymentSource: StrongholdPaymentSource) => {
      onSuccess(paymentSource);
    },
    onExit,
    onError: (error: string) => onError(error),
  });
};
