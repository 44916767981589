import { useMenu } from '@jane/shared-ecomm/providers';
import { trackStoreInfoClick } from '@jane/shared-ecomm/tracking';
import { MenuStarRating } from '@jane/shared/components';
import {
  Flex,
  Link,
  StarFilledIcon,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { pluralize } from '@jane/shared/util';

export const MenuRating = () => {
  const { store, paths } = useMenu();
  const isMobile = useMobileMediaQuery({});

  return (
    <Flex alignItems="center">
      {isMobile ? (
        <StarFilledIcon size="sm" mr={4} />
      ) : (
        <MenuStarRating rating={store.rating} />
      )}
      <Typography variant="body-bold" mr={4}>
        {store.rating.toFixed(1)}
      </Typography>
      <Link
        to={paths.menuReviews()}
        onClick={() => trackStoreInfoClick('ratings')}
        ariaLabel="Go to reviews page"
      >
        <Typography>
          (
          {pluralize({
            noun: 'review',
            number: store.reviews_count,
            kilo: true,
            returnValue: 'count',
          })}
          )
        </Typography>
      </Link>
    </Flex>
  );
};
