import { useCheckout } from '@jane/shared-ecomm/providers';
import { Typography } from '@jane/shared/reefer';

export const AeropayHeader = () => {
  const {
    store: { aeropay_integration },
  } = useCheckout();

  const customLabel = aeropay_integration?.custom_payment_method_label
    ?.replace(/^Pay now with/i, '')
    .trim();
  const label = customLabel
    ? customLabel.charAt(0).toUpperCase() + customLabel.slice(1)
    : 'Aeropay';

  return <Typography>{label}</Typography>;
};
