import cardValidator from 'card-validator';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box, Flex, Form, MerrcoLogo, Typography } from '@jane/shared/reefer';

import { PaymentMethods } from '../../util/hooks/usePaymentOptions';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { Grid, GridItem } from './merrcoPayments.styles';

const CARD_NUMBER_FORMAT = '#### #### #### ####';
const EXPIRY_FORMAT = '##/##';

export const MerrcoPayment = () => {
  const { selected } = useCheckoutPayments();
  const { trigger } = useFormContext();
  const shouldValidate = selected === PaymentMethods.payfirma;

  useEffect(() => {
    trigger('paymentOptions.payfirma');
  }, [shouldValidate]);

  return (
    <Box>
      <Box>
        <Form.NumberField
          name="paymentOptions.payfirma.cardNumber"
          allowLeadingZeros
          autocomplete="cc-number"
          label="Card number"
          maskFormat={CARD_NUMBER_FORMAT}
          disableMobileInputStyling
          errorOnBlur
          validate={(value: string) =>
            !shouldValidate ||
            cardValidator.number(value).isValid ||
            'Please enter a valid credit card number'
          }
        />

        <Grid>
          <Form.NumberField
            name="paymentOptions.payfirma.cardExpiry"
            allowLeadingZeros
            autocomplete="cc-exp"
            label="Expiration"
            placeholder="MM/YY"
            maskFormat={EXPIRY_FORMAT}
            errorOnBlur
            disableMobileInputStyling
            validate={(value: string) =>
              !shouldValidate ||
              cardValidator.expirationDate(value).isValid ||
              'Please enter a valid date (MM/YY)'
            }
          />

          <Form.NumberField
            name="paymentOptions.payfirma.cardCvv2"
            allowLeadingZeros
            label="CVV"
            placeholder="123"
            maskFormat="####"
            disableMobileInputStyling
            errorOnBlur
            validate={(value: string) =>
              !shouldValidate ||
              value?.length === 3 ||
              value?.length === 4 ||
              'Please enter a valid CVV'
            }
          />

          <GridItem>
            <Form.TextField
              name="paymentOptions.payfirma.cardPostalCode"
              autocomplete="postal-code"
              label="Billing postal code"
              placeholder="95060"
              errorOnBlur
              validate={(value: string) =>
                !shouldValidate ||
                (value?.length >= 5 && value?.length <= 10) ||
                'Please enter a valid billing postal code'
              }
              disableMobileInputStyling
            />
          </GridItem>
        </Grid>
      </Box>
      <Flex mt={16} alignItems="center">
        <Typography>Secure payment powered by</Typography>
        <MerrcoLogo ml={4} height={14.25} />
      </Flex>
    </Box>
  );
};
