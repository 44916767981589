import { PRODUCTS_PER_PAGE_CAROUSEL } from '@jane/search/util';
import { SpecialCard } from '@jane/shared-ecomm/components';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import { CardCarousel } from '@jane/shared/components';

import type { MenuCarouselProps } from './MenuBuyAgainCarousel';

export const MenuSpecialsCarousel = ({
  mode = 'ideal-width',
}: MenuCarouselProps) => {
  const { store, paths } = useMenu();
  const { loadingSpecialsCards, specialsCards } = useSpecials();

  const nextBreadcrumbs = [
    { title: 'Featured', url: paths.menuCategory('featured') },
  ];

  if ((!loadingSpecialsCards && specialsCards?.length === 0) || !specialsCards)
    return null;

  return (
    <CardCarousel
      cardGap={24}
      cardGapMobile={16}
      cardHeight={240}
      cardHeightMobile={240}
      cardWidth={310}
      cardWidthMobile={320}
      isLoading={loadingSpecialsCards}
      mode={mode}
      name={store.specials_row_configs?.custom_display_name || 'Specials'}
      buttonTo={paths.menuCategory('specials')}
      buttonOnClick={() =>
        track({
          event: EventNames.AllSpecialsTap,
          storeName: store.name,
          storeId: store.id,
        })
      }
    >
      {specialsCards
        .slice(0, PRODUCTS_PER_PAGE_CAROUSEL)
        .map((special, index) => (
          <CardCarousel.Card key={special.id + special.title}>
            <SpecialCard
              breadcrumbs={nextBreadcrumbs}
              special={special}
              store={store}
              to={paths.menuSpecial({ id: special.id, title: special.title })}
              onClick={() =>
                track({
                  event: EventNames.StoreSpecialTap,
                  source: 'Specials Row',
                  specialRankOnList: index + 1,
                  storeName: store.name,
                  storeId: store.id,
                  specialId: special.id,
                  specialType: special.special_type,
                })
              }
            />
          </CardCarousel.Card>
        ))}
    </CardCarousel>
  );
};
