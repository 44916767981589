import { useCheckout } from '@jane/shared-ecomm/providers';

export interface RequiredDocs {
  governmentId: boolean | null | undefined;
  medicalNumber: boolean;
  medicalPhoto: boolean;
}

export const useCheckoutDocs = () => {
  const {
    store,
    cart: { reservation_mode },
    checkoutRequirements,
  } = useCheckout();

  const medicalIdLanguage =
    store.store_compliance_language_settings?.['medical_id'] ||
    'Medical Marijuana';
  const medicalIdFrontLanguage =
    store.store_compliance_language_settings?.['med_card_front'] || 'front';
  const medicalIdBackLanguage =
    store.store_compliance_language_settings?.['med_card_back'] || 'back';

  const medicalCardUploadText = `Upload the ${medicalIdFrontLanguage} and ${medicalIdBackLanguage} of your valid state ${medicalIdLanguage} ID to make medical reservations.`;

  const isDelivery = reservation_mode === 'delivery';
  const governmentIdRequired =
    (store.delivery_id_required && isDelivery) ||
    (store.pickup_id_required && !isDelivery);

  const requiredMedicalDocs = checkoutRequirements.data
    ?.medical_documentation_requirement as string;

  const medicalPhotoRequired = ['photo', 'photo_and_number'].includes(
    requiredMedicalDocs
  );
  const medicalNumberRequired = ['number', 'photo_and_number'].includes(
    requiredMedicalDocs
  );

  const requiredDocs = {
    governmentId: governmentIdRequired,
    medicalPhoto: medicalPhotoRequired,
    medicalNumber: medicalNumberRequired,
  };

  return { requiredDocs, medicalCardUploadText, medicalIdLanguage };
};
