import {
  DEFAULT_DESKTOP_CARD_WIDTH,
  DEFAULT_DESKTOP_GRID_GAP,
  DEFAULT_MOBILE_CARD_WIDTH,
  DEFAULT_MOBILE_GRID_GAP,
} from '@jane/shared/components';

export const calculateGridColumns = (gridWidth?: number) => {
  if (!gridWidth) return null;

  let numColumns = 0;

  if (gridWidth > 450) {
    numColumns =
      gridWidth / (DEFAULT_DESKTOP_CARD_WIDTH + DEFAULT_DESKTOP_GRID_GAP);
  } else {
    numColumns =
      gridWidth / (DEFAULT_MOBILE_CARD_WIDTH + DEFAULT_MOBILE_GRID_GAP);
  }

  return Math.floor(numColumns);
};
