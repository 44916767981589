import { useMenu } from '@jane/shared-ecomm/providers';
import {
  Box,
  Button,
  Flex,
  Modal,
  ReadMore,
  Typography,
} from '@jane/shared/reefer';

import { MenuContact } from './MenuContact';
import { MenuHours } from './MenuHours';
import { MenuOwnership } from './MenuOwnership';

interface MenuAboutModalProps {
  onRequestClose: () => void;
  open: boolean;
}

export const MenuAboutModal = ({
  onRequestClose,
  open,
}: MenuAboutModalProps) => {
  const {
    store: { description, ownership_identifications },
  } = useMenu();

  return (
    <Modal
      open={open}
      onRequestClose={onRequestClose}
      overlayClose={true}
      variant="dialogue"
    >
      <Modal.Content padding={false}>
        <Box p={24}>
          <Typography branded variant="header-bold" textAlign="center" pb={8}>
            About
          </Typography>
          <Flex gap={24} flexDirection="column" pb={24} maxWidth="345px">
            <MenuHours />
            <MenuContact />
            {!!ownership_identifications?.length && <MenuOwnership />}
            {!!description && (
              <Box>
                <Typography variant="body-bold" pb={8}>
                  Overview
                </Typography>
                <ReadMore
                  content={description}
                  characters={185}
                  more="View more"
                  less="View less"
                />
              </Box>
            )}
          </Flex>
          <Button full label="Close" onClick={onRequestClose} />
        </Box>
      </Modal.Content>
    </Modal>
  );
};
