import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import {
  ConditionalWrapper,
  Flex,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { DisplayMode, useProductCardContext } from '../../productCardProvider';
import { SponsoredText } from '../../sponsoredTag/sponsoredTag';
import ListViewStateContainer from '../listViewStateContainer';
import { ProductDetailPageLink } from '../utils/productCardHelper';
import type { LinkProps } from '../utils/productCardHelper';
import ActionCardContent from './actionCardContent';
import EditingCard from './editingCard';
import { StyledCardGroup } from './listViewProductCard.styles';
import ProductInfoCardContent from './productInfoCardContent';

export const ListViewProductCard = ({ isAd }: { isAd: boolean }) => {
  const isMobile = useMobileMediaQuery({});
  const {
    appMode,
    brand,
    breadcrumbs,
    cartIsOpen,
    currentSpecial,
    disableInteraction,
    displayMode,
    fromSpecialId,
    fromAllSpecials,
    menuProduct: product,
    onCloseCart,
    onSetBreadcrumbs,
    pdpPath,
    routeAppMode,
    routePartnerHostedConfig,
    searchState,
    selectedWeight,
    store,
    trackListViewClick,
  } = useProductCardContext();

  const direction = isMobile ? 'column' : 'row';
  const myHighMenu = useFlag(FLAGS.myHighMenu);

  const productDetailPageLinkProps = {
    appMode,
    brand,
    breadcrumbs,
    cartIsOpen,
    product,
    searchState,
    store,
    fromMenu: true,
    fromAllSpecials,
    fromSpecialId,
    onCloseCart,
    onSetBreadcrumbs,
    pdpPath,
    routeAppMode,
    routePartnerHostedConfig,
    trackListViewClick,
    bundleSpecialId:
      currentSpecial?.special_type === 'bundle' ? currentSpecial.id : undefined,
  } as Omit<LinkProps, 'children'>;

  return (
    <ListViewStateContainer>
      {({
        decrementQuantity,
        discountedPrice,
        incrementQuantity,
        multipleCountDisabled,
        noSelectedWeightDefault,
        onAddToCartPressed,
        onWeightSelected,
        originalPrice,
        selectedQuantity,
        shoppingDisabled,
        soldByWeight,
        weights,
      }) => {
        return (
          <StyledCardGroup
            data-testid="list-view-product-card"
            width="100%"
            border="grays-light"
            mb={myHighMenu ? 0 : 16}
            flat={myHighMenu}
            myHighMenu={myHighMenu}
          >
            <Flex flexDirection={direction}>
              <ConditionalWrapper
                condition={!disableInteraction}
                wrapper={(children) => (
                  <ProductDetailPageLink {...productDetailPageLinkProps}>
                    {children}
                  </ProductDetailPageLink>
                )}
              >
                <Flex flexDirection="column" width="100%" p={24}>
                  {myHighMenu && isAd && <SponsoredText mb={16} />}
                  <ProductInfoCardContent />
                </Flex>
              </ConditionalWrapper>
              <ActionCardContent
                noSelectedWeightDefault={noSelectedWeightDefault}
                onWeightSelected={onWeightSelected}
                selectedWeight={selectedWeight}
                weights={weights}
                isAd={isAd}
              />
            </Flex>
            {displayMode === DisplayMode.Edit && (
              <EditingCard
                decrementQuantity={decrementQuantity}
                discountedPrice={discountedPrice}
                incrementQuantity={incrementQuantity}
                multipleCountDisabled={multipleCountDisabled}
                onAddToCartPressed={onAddToCartPressed}
                originalPrice={originalPrice}
                selectedQuantity={selectedQuantity}
                selectedWeight={selectedWeight}
                shoppingDisabled={shoppingDisabled}
                soldByWeight={soldByWeight}
              />
            )}
          </StyledCardGroup>
        );
      }}
    </ListViewStateContainer>
  );
};
