import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';
import { generateCartLimitReport } from '@jane/shared/util';

export const useCartIsValid = () => {
  const {
    cart,
    cartLineItems: { discountedSubtotal },
    deliveryValidation: { data: deliveryData },
    store,
  } = useCheckout();

  const cartLimitReport = useMemo(() => {
    return generateCartLimitReport({ cart, store });
  }, [cart.products]);

  const { control } = useFormContext();
  const pickupMode = useWatch({ control, name: 'pickupOptions.pickupMode' });

  const { reservation_mode } = cart;
  const { curbside_minimum, delivery_minimum, pickup_minimum } = store;

  const deliveryMinimum =
    typeof deliveryData?.minimum === 'number'
      ? deliveryData.minimum
      : delivery_minimum;

  const requiredMinimum =
    reservation_mode === 'delivery'
      ? deliveryMinimum
      : pickupMode === 'pickup'
      ? pickup_minimum
      : curbside_minimum;

  const cartLimitValid = !cartLimitReport.over;
  const minimumIsMet = discountedSubtotal >= (requiredMinimum || 0);

  return {
    cartIsValid: cartLimitValid && minimumIsMet,
    cartLimitValid,
    minimumIsMet,
    requiredMinimum: requiredMinimum || 0,
  };
};
