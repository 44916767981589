import {
  StoreDetailMetaData,
  StoreDetailStructuredData,
} from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { useRuntimeConfig } from '@jane/shared/runtime-config';

export const MenuSeo = () => {
  const {
    store,
    storeSeo,
    appInfo: { appMode },
  } = useMenu();

  const { disableJaneTitleAndDescriptionMeta } = useRuntimeConfig();

  const omitStoreTitleAndDescriptionMeta =
    appMode === 'framelessEmbed' && disableJaneTitleAndDescriptionMeta;

  return (
    <>
      {!omitStoreTitleAndDescriptionMeta && (
        <StoreDetailMetaData
          store={store}
          appMode={appMode}
          storeSeo={storeSeo}
        />
      )}

      <StoreDetailStructuredData
        store={store}
        structuredData={storeSeo?.structured_data}
      />
    </>
  );
};
