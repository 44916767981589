import { SharedButton } from '../../CheckoutButton/SharedButton';
import { useCanPay } from './useCanPay';

// This button launches the CanPay remote pay widget.
// If remote pay is disabled, the payment method will render a form instead.
export const CanPayButton = () => {
  const { openWidget, widgetLoaded } = useCanPay();

  return (
    <SharedButton
      label="Pay now with CanPay"
      loading={!widgetLoaded}
      onClick={openWidget}
    />
  );
};
