import { config } from '@jane/shared/config';
import type { Store } from '@jane/shared/types';

interface AeropayPreauthorizationResponse {
  id: string;
  merchantId: string;
  total: string;
  userId: string;
}

interface AeropayButtonOptions {
  location?: string;
  locationId?: string;
  merchantId?: string;
  onSuccess: (response: AeropayPreauthorizationResponse) => void;
  type: 'userAuthorize';
}

interface Props {
  aeropayIntegration: Store['aeropay_integration'];
  cartTotal: number;
  onSuccess: (id: string) => void;
}

export const loadAeropayWidget = ({
  cartTotal,
  aeropayIntegration,
  onSuccess,
}: Props) => {
  if (!cartTotal || !aeropayIntegration) {
    return;
  }

  const typedWindow = window as any;

  const handleSuccess = (response: AeropayPreauthorizationResponse) => {
    onSuccess(response.id);
  };

  typedWindow.AeroPay.init({
    env: config.aeropayEnv,
  });

  const buttonOptions: AeropayButtonOptions = {
    type: 'userAuthorize',
    onSuccess: handleSuccess,
  };

  // Aeropay's new store id scheme is a single location uuid field instead of a merchantId + optional locationId
  if (aeropayIntegration.location_uuid) {
    buttonOptions.location = aeropayIntegration.location_uuid || undefined;
  } else {
    buttonOptions.merchantId = aeropayIntegration.merchant_id;
    buttonOptions.locationId = aeropayIntegration.location_id || undefined;
  }

  const apButton = typedWindow.AeroPay.button(buttonOptions);

  apButton.launch(cartTotal.toString());
};
