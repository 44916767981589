import { useMenu } from '@jane/shared-ecomm/providers';
import {
  Button,
  Card,
  CheckCircledIcon,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { formatCurrency } from '@jane/shared/util';

export const DealUnlockedCard = ({ discountAmount = 0 }) => {
  const { paths } = useMenu();
  const isMobile = useMobileMediaQuery({});

  return (
    <Card flat width="100%">
      <Card.Content>
        <Flex p={isMobile ? 24 : 40} alignItems="center" flexDirection="column">
          <CheckCircledIcon size="xxl" mb={24} />
          <Typography variant="title-bold" mb={8}>
            You got the deal
          </Typography>
          <Typography>
            Nice! You just saved {formatCurrency(discountAmount)} with this
            special!
          </Typography>
          <Flex
            width="100%"
            pt={24}
            gap={16}
            {...(isMobile && { flexDirection: 'column-reverse' })}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              full={isMobile}
              variant="tertiary"
              label="Shop more deals"
              to={paths.menuCategory('specials')}
            />

            <Button
              full={isMobile}
              to={paths.menu()}
              variant="tertiary"
              label="Continue shopping"
            />

            <Button full={isMobile} to={paths.checkout()} label="Checkout" />
          </Flex>
        </Flex>
      </Card.Content>
    </Card>
  );
};
