import { useFormContext } from 'react-hook-form';

import { useAeropayUser } from '@jane/shared-ecomm/data-access';
import { useCheckout, useUserPreferences } from '@jane/shared-ecomm/providers';
import type { Preferences } from '@jane/shared/models';
import { formatTime, getAnonymousUserId } from '@jane/shared/util';

import { useUserConsents } from '../../util/hooks/useUserConsents';

export const useBuildPaytenderPayload = () => {
  const { userLocation, userPreferences } = useUserPreferences();
  const {
    customer,
    cart: { id: cartId, reservation_mode, tags, uuid },
    deliveryAddress,
    store,
  } = useCheckout();

  const { getValues } = useFormContext();

  const { checkoutConsents } = useUserConsents();

  const { data: aeropayUserData, isLoading: isLoadingAeropayUser } =
    useAeropayUser({
      janeUserId: customer.id || null,
    });

  const getFulfillmentOptions = () => {
    const [deliveryOptions, pickupOptions] = getValues([
      'deliveryOptions',
      'pickupOptions',
    ]);

    const fulfillmentOptions =
      reservation_mode === 'delivery' ? deliveryOptions : pickupOptions;

    const { fulfillmentTime, message } = fulfillmentOptions;

    const [delivery_started_at, delivery_finished_at] = (
      fulfillmentTime || ''
    ).split('>');

    return {
      delivery_finished_at: parseInt(delivery_finished_at),
      delivery_started_at: parseInt(delivery_started_at),
      fulfillmentTime,
      message,
    };
  };

  const janeGoldUser =
    aeropayUserData && aeropayUserData.bank_accounts.length > 0;

  const getContact = () => {
    const customerInformation = getValues('customerInformation');

    const {
      firstName,
      lastName,
      email,
      phone,
      birthDate,
      mmjIdNumber,
      governmentIdentificationUrl,
      mmjIdBackUrl,
      mmjIdFrontUrl,
    } = customerInformation;

    const formattedBirthDate = birthDate
      ? formatTime(birthDate, 'YYYY-MM-DD', 'MMDDYYYY')
      : '';

    const customerInfo = {
      age_verification: {
        birth_date: formattedBirthDate,
        government_identification_url: governmentIdentificationUrl,
      },
      name: `${firstName || ''} ${lastName || ''}`.trim(),
      email,
      phone,
      medical_identification: {
        id_number: mmjIdNumber,
        id_front_url: mmjIdFrontUrl,
        id_back_url: mmjIdBackUrl,
      },
    };

    if (reservation_mode === 'delivery') {
      return {
        ...customerInfo,
        delivery_address: {
          street: deliveryAddress.street || '',
          street2: deliveryAddress.street2 || null,
          city: deliveryAddress.city || '',
          country_code: deliveryAddress.country_code || '',
          state_code: deliveryAddress.state_code || '',
          zipcode: deliveryAddress.zipcode || '',
          latitude: deliveryAddress.lat || 0,
          longitude: deliveryAddress.lng || 0,
        },
      };
    }

    return {
      ...customerInfo,
      pickup_address: {
        street: store.address || '',
        street2: null,
        city: store.city || '',
        country_code: store.country_code || '',
        zipcode: store.zip || '',
        state_code: store.state || '',
        latitude: store.lat || 0,
        longitude: store.long || 0,
      },
    };
  };

  const handleBuildPayload = () => {
    const pickupMode = getValues('pickupOptions.pickupMode');

    const checkoutPayload = {
      aeropay_account_verified: janeGoldUser || false,
      anonymous_user_id: getAnonymousUserId(),
      cart_id: String(cartId) || '',
      cart_uuid: uuid,
      fulfillment_contact: getContact(),
      is_curbside_pickup:
        reservation_mode === 'pickup' && pickupMode === 'curbside',
      reservation_mode,
      user_consent: checkoutConsents,
      tags,
      user_location: userLocation,
      user_preferences: userPreferences as Preferences,
      ...getContact(),
      ...getFulfillmentOptions(),
    };

    return checkoutPayload;
  };

  return {
    buildPayload: handleBuildPayload,
    isLoading: isLoadingAeropayUser,
  };
};
