import { useFormContext, useWatch } from 'react-hook-form';

import { useCheckout } from '@jane/shared-ecomm/providers';

export const useReservationModeLabels = (lowercase = false) => {
  const {
    cart: { reservation_mode },
    store: { reservation_mode_labels },
  } = useCheckout();

  const { control } = useFormContext();
  const pickupMode = useWatch({ control, name: 'pickupOptions.pickupMode' });

  const curbsideLabel = reservation_mode_labels?.curbside
    ? reservation_mode_labels.curbside
    : lowercase
    ? 'curbside pickup'
    : 'Curbside pickup';
  const deliveryLabel = reservation_mode_labels?.delivery
    ? reservation_mode_labels.delivery
    : lowercase
    ? 'delivery'
    : 'Delivery';
  const pickupLabel = reservation_mode_labels?.pickup
    ? reservation_mode_labels.pickup
    : lowercase
    ? 'pickup'
    : 'Pickup';

  const reservationModeLabel =
    reservation_mode === 'delivery'
      ? deliveryLabel
      : pickupMode === 'pickup'
      ? pickupLabel
      : curbsideLabel;

  return { reservationModeLabel, curbsideLabel, deliveryLabel, pickupLabel };
};
